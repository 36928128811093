import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from 'configs/azureConfig';
import axiosInstance from 'configs/axiosConfig';
import { clearLocalStorage, setAuthToken } from 'utils/localStorageUtil';
import { useTranslation } from 'react-i18next';

interface RequestInterceptorProps {
	children: JSX.Element;
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
	const { t } = useTranslation();
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0]);

	/* eslint-disable no-param-reassign */
	axiosInstance.interceptors.response.use(
		async (config) => {
			return config;
		},
		async (error) => {
			try {
				let originalRequest = error.config;

				if (error.response.status !== 401 && originalRequest) {
					return Promise.reject(error);
				}
				originalRequest._retry = true;
				/*
				 * When response code is 401, try to refresh the token.
				 * Eject the interceptor so it doesn't loop in case
				 * token refresh causes the 401 response
				 */

				if (!account) {
					throw Error(t('no_active_account'));
				}

				const response = await instance.acquireTokenSilent({
					...loginRequest,
					account
				});

				const token = response.accessToken;

				if (token) {
					setAuthToken(token);
					return axiosInstance(originalRequest);
				}
			} catch (error) {
				clearLocalStorage();
				instance.logoutRedirect({
					account: account
				});
				return Promise.reject(error);
			}
		}
	);

	/* eslint-enable no-param-reassign */

	return <>{children}</>;
};

export default RequestInterceptor;
