import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from 'components/atoms/Loader';
import { TableSortLabel, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatColumnValue } from 'utils/commonUtil';
import { ReportTableCell } from 'components/atoms/CPReportTableCell';
import { IColumn } from '../DataTable/DataTable.type';
import { useState } from 'react';
import { SortOrder } from 'common/enums/sortOrder.enum';
import _ from 'lodash';

type Order = SortOrder.ASC | SortOrder.DESC;
interface IFunnelReportTable {
	columns: IColumn[];
	rows: any;
	loading?: boolean;
	defaultSortColumn: string;
	defaultSortOrder: Order;
}

export default function FunnelReportTable(props: IFunnelReportTable) {
	const { rows, columns, loading, defaultSortColumn, defaultSortOrder } = props;
	const { t } = useTranslation();
	const [order, setOrder] = useState<Order>(defaultSortOrder);
	const [orderBy, setOrderBy] = useState<string>(defaultSortColumn);

	const createSortHandler = (property: string) => () => {
		const isAsc = orderBy === property && order === SortOrder.ASC;
		setOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);
		setOrderBy(property);
	};

	function sortRows<T>(array: readonly T[]) {
		return _.orderBy(array, [orderBy], [order]);
	}
	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: '100%'
				}
			}}
		>
			<Table
				stickyHeader
				sx={{ width: '100%', tableLayout: 'auto', borderCollapse: 'Collapse', borderStyle: 'hidden' }}
				aria-label="label-table"
			>
				<TableHead>
					<TableRow sx={{}}>
						{columns.map((column) => (
							<TableCell
								sortDirection={orderBy === column.id ? order : false}
								key={column.id}
								align={'center'}
								sx={{
									minWidth: column.minWidth,
									...column.headerStyle
								}}
							>
								{column.id === 'date' ? (
									<TableSortLabel
										active={orderBy === column.id}
										direction={orderBy === column.id ? order : SortOrder.ASC}
										onClick={createSortHandler(column.id)}
									>
										<Tooltip title={column.label} placement="bottom-start">
											<span>{column.label}</span>
										</Tooltip>
									</TableSortLabel>
								) : (
									<Tooltip title={column.label} placement="bottom-start">
										<span>{column.label}</span>
									</Tooltip>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{rows && rows.length > 0 && !loading
						? sortRows(rows).map((row: any, index: number) => (
								<TableRow key={`row-${index.toString()}`}>
									{columns.map((column) => {
										const value = row[column.id as keyof typeof row];
										return (
											<ReportTableCell
												key={column.id}
												sx={{
													textAlign: column.align,
													minWidth: column.minWidth,
													padding: '6px 16px !important',
													...column.style
												}}
											>
												<span>{formatColumnValue(column.type, value, column.trimDecimal)}</span>
											</ReportTableCell>
										);
									})}
								</TableRow>
						  ))
						: !loading && (
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
										<Typography align="center">{t('no_records_found')}</Typography>
									</TableCell>
								</TableRow>
						  )}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
