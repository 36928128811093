import { useTheme } from '@mui/material';
import ComparisonLabelTable from 'components/molecules/ComparisonLabelTable';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import LabelTable from 'components/molecules/LabelTable';
import { useTranslation } from 'react-i18next';

interface ISummaryReportContainer {
	summaryReport: any;
	searchFunnelSummary: boolean;
	isSpAttributedHidden?: boolean;
	isGaLastClickHidden?: boolean;
	isPlatformStatisticHidden?: boolean;
}
const SummaryReportContainer = (props: ISummaryReportContainer) => {
	const { t } = useTranslation();
	const { summaryReport, searchFunnelSummary, isSpAttributedHidden, isGaLastClickHidden, isPlatformStatisticHidden } =
		props;
	const theme = useTheme();

	const summaryColumns: IColumn[] = [
		{ id: 'funnelName', label: '', minWidth: '382px', align: 'left', style: { borderLeft: 0 } },
		{
			id: 'cost',
			label: t('cost'),
			minWidth: '144px',
			align: 'right',
			type: 'currency',
			trimDecimal: true
		},
		{
			id: 'costPercentage',
			label: t('costPercentage'),
			minWidth: '144px',
			align: 'right',
			type: 'percentage',
			style: { borderRight: 10, borderColor: '#E5E5E5' }
		},
		...(!isSpAttributedHidden
			? [
					{
						id: 'spAttributeRevenue',
						label: t('cp_after_sales'),
						minWidth: '144px',
						align: 'right' as const,
						type: 'currency' as const,
						trimDecimal: true,
						style: { borderLeft: 10, borderColor: '#E5E5E5' }
					},
					{
						id: 'spAttributeRoas',
						label: t('cp_after_roas'),
						minWidth: '144px',
						align: 'right' as const,
						style: { borderRight: 10, borderColor: '#E5E5E5' }
					}
			  ]
			: []),
		...(!isGaLastClickHidden
			? [
					{
						id: 'gaRevenue',
						label: t('ga_revenue'),
						minWidth: '144px',
						align: 'right' as const,
						type: 'currency' as const,
						trimDecimal: true,
						style: { borderLeft: 10, borderColor: '#E5E5E5' }
					},
					{
						id: 'gaRoas',
						label: t('ga_roas'),
						minWidth: '144px',
						align: 'right' as const,
						style: { borderRight: 10, borderColor: '#E5E5E5' }
					}
			  ]
			: []),
		...(!isPlatformStatisticHidden
			? [
					{
						id: 'platformRevenue',
						label: t('platform_revenue'),
						minWidth: '144px',
						align: 'right' as const,
						type: 'currency' as const,
						trimDecimal: true,
						style: { borderLeft: 10, borderColor: '#E5E5E5' }
					},
					{
						id: 'platformRoas',
						label: t('platform_roas'),
						minWidth: '144px',
						align: 'right' as const,
						style: { borderRight: 0 }
					}
			  ]
			: [])
	];

	const comparisonTableColumns: IColumn[] = [
		{
			id: 'spendChange',
			label: t('spend_change'),
			minWidth: '144px',
			align: 'left',
			colSpan: 2
		},
		{
			id: 'revenueChange',
			label: t('revenue_change'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},

		{
			id: 'spendChangePercentage',
			label: t('spend_change_percentage'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},
		{
			id: 'revChangePercentage',
			label: t('rev_change_percentage'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},
		{
			id: 'changePercentage',
			label: t('change_percentage'),
			minWidth: '144px',
			align: 'right'
		}
	];

	return (
		<div style={{ marginBottom: '20px', display: 'flex', overflow: 'auto' }}>
			<div style={{ margin: '10px 50px 10px 0px' }}>
				<LabelTable
					columns={summaryColumns}
					loading={searchFunnelSummary}
					labelName={''}
					headerColor={theme.palette.primary.labelReport.summaryColor}
					rows={summaryReport.summaryTableOne}
					totalRow={summaryReport.tableOneTotal}
					isSpAttributedHidden={isSpAttributedHidden}
					isGaLastClickHidden={isGaLastClickHidden}
					isPlatformStatisticHidden={isPlatformStatisticHidden}
				/>
			</div>
			<div style={{ margin: '10px 50px 10px 0px' }}>
				<LabelTable
					hideLabel={true}
					columns={summaryColumns}
					loading={searchFunnelSummary}
					labelName={''}
					headerColor={theme.palette.primary.labelReport.summaryColor}
					rows={summaryReport.summaryTableTwo}
					totalRow={summaryReport.tableTwoTotal}
					isSpAttributedHidden={isSpAttributedHidden}
					isGaLastClickHidden={isGaLastClickHidden}
					isPlatformStatisticHidden={isPlatformStatisticHidden}
				/>
			</div>
			<div style={{ margin: '10px 50px 10px 0px' }}>
				<ComparisonLabelTable
					columns={comparisonTableColumns}
					totalRow={summaryReport.comparisonTotal}
					rows={summaryReport.comparisonTable}
					loading={searchFunnelSummary}
					headerColor={theme.palette.primary.labelReport.summaryColor}
				/>
			</div>
		</div>
	);
};

export default SummaryReportContainer;
