import { Grid, IconButton } from '@mui/material';
import CPNumberPercentageFormat from 'components/atoms/CPNumberPercentageFormat';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import CPTextField from 'components/atoms/CPTextField';
import { theme } from 'configs/theme';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
interface IRemovableSplitRow {
	handleRemoveClick: (split: any) => void;
	channelsFieldName: string;
	channels: IOptionItem[];
	isLoadingChannels: boolean;
	channelsSelectedValue: IOptionItem;
	setSelectedChannelFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	isReadOnly: boolean | undefined;
	splitPercentageFieldName: string;
	splitPercentageFieldValue: string | number;
	splitPercentageHandleChange: (e: ChangeEvent) => void;
	splitPercentageFieldError: boolean | undefined;
	splitPercentageFieldOnBlurCapture?: () => void;
	split: any;
}
const RemovableSplitRow = (props: IRemovableSplitRow) => {
	const { t } = useTranslation();
	const {
		channelsFieldName,
		channels,
		isLoadingChannels,
		channelsSelectedValue,
		setSelectedChannelFieldValue,
		isReadOnly,
		splitPercentageFieldName,
		splitPercentageFieldValue,
		splitPercentageHandleChange,
		splitPercentageFieldError,
		splitPercentageFieldOnBlurCapture,
		split,
		handleRemoveClick
	} = props;
	return (
		<Grid item xs={12}>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<CPSingleSelectAutoCompleteDropDown
						name={channelsFieldName}
						size="small"
						options={channels}
						label={t('channel_name')}
						loading={isLoadingChannels}
						disableClearable
						value={channelsSelectedValue}
						setFieldValue={setSelectedChannelFieldValue}
						readOnly={isReadOnly}
					/>
				</Grid>
				<Grid item xs={3}>
					<CPTextField
						label={t('split_percentage')}
						name={splitPercentageFieldName}
						fullWidth
						size="small"
						value={splitPercentageFieldValue}
						InputProps={{
							inputComponent: CPNumberPercentageFormat as any
						}}
						handleChange={splitPercentageHandleChange}
						defaultValue={0}
						error={splitPercentageFieldError}
						inputProps={{ readOnly: isReadOnly }}
						onBlurCapture={splitPercentageFieldOnBlurCapture}
					/>
				</Grid>
				<Grid item xs={3}>
					{!isReadOnly ? (
						<div style={{ display: 'flex' }}>
							<IconButton
								sx={{
									minWidth: '40px',
									width: '40px',
									height: '40px',
									marginLeft: '-20px',
									color: theme.palette.primary.delete,
									flex: 'none'
								}}
								onClick={() => handleRemoveClick(split)}
							>
								<RemoveCircleIcon />
							</IconButton>
						</div>
					) : null}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RemovableSplitRow;
