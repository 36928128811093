import ACCESS_TAGS from 'common/constants/accessTags';
import Loader from 'components/atoms/Loader';
import _ from 'lodash';
import CustomizedQueries from 'pages/CustomizedQueries';
import CreateCustomizedQuery from 'pages/CustomizedQueries/CreateCustomizedQuery';
import ChannelReport from 'pages/DailyStatistics/ChannelsReport';
import FunnelReport from 'pages/DailyStatistics/FunnelReport';
import Notifications from 'pages/Notifications';
import Operations from 'pages/Operations';
import MyProfile from 'pages/ProfileManagement/MyProfile';
import DataAnalysis from 'pages/Statistics/IncrementalReport';
import DeltaAnalysis from 'pages/Statistics/DeltaAnalysis';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { RootState } from 'store';
import ChangePassword from 'pages/ProfileManagement/ChangePassword';
import IncrementalSchedules from 'pages/Statistics/IncrementalReport/IncrementalSchedules';

const Dashboard = lazy(() => import('pages/Dashboard'));
const NotFound = lazy(() => import('pages/NotFound'));
const Accounts = lazy(() => import('pages/UserManagement/Accounts'));
const Clients = lazy(() => import('pages/UserManagement/Clients'));
const GlobalQueryList = lazy(() => import('pages/GlobalQueryList'));
const Channels = lazy(() => import('pages/Channels'));
const CPAttributionEntry = lazy(() => import('pages/Statistics/CPAttributionEntry'));
const SingleAnalysis = lazy(() => import('pages/Statistics/SingleAnalysis'));

export const APP_ROUTES = [
	{
		path: '/configurations/channels',
		component: <Channels />,
		auth: 'GET_ALL_CHANNELS'
	},
	{
		path: '/user-management/accounts',
		component: <Accounts />,
		auth: ACCESS_TAGS.GET_ALL_USERS
	},
	{
		path: '/user-management/clients',
		component: <Clients />,
		auth: ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT
	},
	{
		path: '/',
		component: <Dashboard />
	},
	{
		path: '/configurations/global-queries',
		component: <GlobalQueryList />,
		auth: ACCESS_TAGS.GET_GLOBAL_QUERY
	},
	{
		path: '/configurations/custom-queries',
		component: <CustomizedQueries />,
		auth: ACCESS_TAGS.GET_CUSTOM_QUERY
	},
	{
		path: '/my-profile',
		component: <MyProfile />
	},
	{
		path: '/configurations/custom-queries/create-customized-query',
		component: <CreateCustomizedQuery />,
		auth: ACCESS_TAGS.CREATE_CUSTOM_QUERY
	},
	{
		path: '/configurations/sp-attribution-entry',
		component: <CPAttributionEntry />,
		auth: ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
	},
	{
		path: '/reports/attributed-sales',
		component: <SingleAnalysis />,
		auth: ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT
	},
	{
		path: '/reports/attributed-sales/:prmClientId/:prmStartDate/:prmEndDate',
		component: <SingleAnalysis />,
		auth: ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT
	},
	{
		path: '/reports/delta-analysis',
		component: <DeltaAnalysis />,
		auth: ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT
	},
	{
		path: '/reports/delta-analysis/:prmClientId/:prmStartDate/:prmEndDate/:prmStartDate2/:prmEndDate2',
		component: <DeltaAnalysis />,
		auth: ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT
	},
	{
		path: '/reports/daily-funnel-stats',
		component: <FunnelReport />,
		auth: ACCESS_TAGS.DAILY_FUNNEL_REPORT
	},
	{
		path: '/reports/daily-funnel-stats/:prmClientId/:prmStartDate/:prmEndDate',
		component: <FunnelReport />,
		auth: ACCESS_TAGS.DAILY_FUNNEL_REPORT
	},
	{
		path: '/reports/daily-channel-stats',
		component: <ChannelReport />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	},
	{
		path: '/reports/daily-channel-stats/:prmClientId/:prmStartDate/:prmEndDate',
		component: <ChannelReport />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	},
	{
		path: '/notifications',
		component: <Notifications />,
		auth: ACCESS_TAGS.GET_ALL_NOTIFICATIONS
	},
	{
		path: '/configurations/incremental-schedule',
		component: <IncrementalSchedules />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	},
	{
		path: '/operations',
		component: <Operations />,
		auth: ACCESS_TAGS.TRIGGER_ETL
	},
	{
		path: '/change-password',
		component: <ChangePassword />
	},
	{
		path: '/configurations/incremental-schedule/create-incremental-schedules',
		component: <DataAnalysis />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	},
	{
		path: '/configurations/incremental-schedule/update-incremental-schedules/:id',
		component: <DataAnalysis />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	}
];

const AppRoutes = () => {
	const userAccess = useSelector((state: RootState) => state.userProfile);

	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				{APP_ROUTES.map(
					(route, index) =>
						(_.get(userAccess, 'userProfile.featureList', []).includes(route.auth) || !route.auth) && (
							<Route key={index} element={route.component} path={route.path} />
						)
				)}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	);
};

export default AppRoutes;
