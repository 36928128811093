import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Divider, Grid, Typography } from '@mui/material';
import CPDatePicker from 'components/atoms/CPDatePicker';
import { useTranslation } from 'react-i18next';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useEffect, useState } from 'react';
import { getEndpointPromise, insertEndpointPromise } from 'services/apiServices';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { ILabels } from 'types/label.type';
import { useSnackbar } from 'notistack';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import _, { get } from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ChannelReportContainer from "./ChannelReportListContainer'";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ScrollContainer from 'react-indiana-drag-scroll';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getDownloadFileName, isAuthOperation } from 'utils/commonUtil';
import ACCESS_TAGS from 'common/constants/accessTags';
import { MOMENT_DATE_FORMAT } from 'common/constants/applicationConstants';
import moment from 'moment';
import { getReportParameters, setReportParameters } from 'utils/localStorageUtil';
import { useNavigate, useParams } from 'react-router-dom';

const ChannelReport = () => {
	const { t } = useTranslation();
	const [clients, setClients] = useState<IOptionItem[] | []>([]);
	const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [rows, setRows] = useState<any>([]);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [isExportingExelReport, setIsExportingExelReport] = useState<boolean>(false);
	const [uniqueChannels, setUniqueChannels] = useState<any>({});
	const [channelData, setChannelData] = useState<any>({});
	const settings = useSelector((state: RootState) => state.settings);
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const { prmClientId, prmStartDate, prmEndDate } = useParams();
	const navigate = useNavigate();

	const validationSchema = yup
		.object({
			clientId: yup.string().required(t('value_required')).trim().nullable()
		})
		.shape({
			startDate: yup
				.date()
				.typeError('Date needs to be mm/dd/yyyy format')
				.required(t('value_required')),
			endDate: yup
				.date()
				.when('startDate', (startDate, schema) => startDate && schema.min(startDate, t('end_date_cannot_be')))
				.typeError('Date needs to be mm/dd/yyyy format')
				.required(t('value_required'))
		});

	const searchForm = useFormik({
		initialValues: {
			startDate: moment().subtract(7, 'd').toDate(),
			endDate: moment().subtract(1, 'd').toDate(),
			clientId: null
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			handleChannelReportSearch();
		}
	});
	const handleChannelReportSearch = async () => {
		try {
			setSearchLoading(true);
			let clientId = searchForm.values.clientId;
			let startDate = moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT);
			let endDate = moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT);
			navigate('/reports/daily-channel-stats/' + clientId + '/' + startDate + '/' + endDate);

			let funnelData = await insertEndpointPromise(`/entitymanager/analysis-report/daily-channel-report`, {
				clientId: searchForm.values.clientId,
				startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
				endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT)
			});
			setRows(funnelData.data.channelReport);
			let channelReport = funnelData.data.channelReport;
			let awarenessData: any = [];
			let acquisitionData: any = [];
			let conversionData: any = [];
			let navigationData: any = [];

			for (const element of channelReport) {
				let awarenessChannels: any = {};
				let acquisitionChannels: any = {};
				let conversionChannels: any = {};
				let navigationChannels: any = {};

				element.awarenessChannels.map((item: any) => {
					awarenessChannels[item.name] = item.spends;
				});
				element.acquisitionChannels.map((item: any) => {
					acquisitionChannels[item.name] = item.spends;
				});
				element.conversionChannels.map((item: any) => {
					conversionChannels[item.name] = item.spends;
				});
				element.navigationChannels.map((item: any) => {
					navigationChannels[item.name] = item.spends;
				});

				awarenessData.push(awarenessChannels);
				acquisitionData.push(acquisitionChannels);
				conversionData.push(conversionChannels);
				navigationData.push(navigationChannels);
			}

			let channelData = {
				awarenessData: awarenessData,
				acquisitionData: acquisitionData,
				conversionData: conversionData,
				navigationData: navigationData
			};
			setChannelData(channelData);

			let uniqueAwarenessChannels: any = [];
			let uniqueAcquisitionChannels: any = [];
			let uniqueConversionChannels: any = [];
			let uniqueNavigationChannels: any = [];
			for (const element of channelReport) {
				let awareness = element.awarenessChannels.map((item: any) => item.name);
				let acquisition = element.acquisitionChannels.map((item: any) => item.name);
				let conversion = element.conversionChannels.map((item: any) => item.name);
				let navigation = element.navigationChannels.map((item: any) => item.name);

				uniqueAwarenessChannels = [...uniqueAwarenessChannels, ...awareness];
				uniqueAcquisitionChannels = [...uniqueAcquisitionChannels, ...acquisition];
				uniqueConversionChannels = [...uniqueConversionChannels, ...conversion];
				uniqueNavigationChannels = [...uniqueNavigationChannels, ...navigation];
			}
			let channels = {
				uniqueAwarenessChannels: Array.from(new Set(uniqueAwarenessChannels)),
				uniqueAcquisitionChannels: Array.from(new Set(uniqueAcquisitionChannels)),
				uniqueConversionChannels: Array.from(new Set(uniqueConversionChannels)),
				uniqueNavigationChannels: Array.from(new Set(uniqueNavigationChannels))
			};
			setUniqueChannels(channels);

			setSearchLoading(false);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchLoading(false);
		}
	};

	const handleDataExport = async () => {
		setIsExportingExelReport(true);
		try {
			let response = await insertEndpointPromise(
				`/entitymanager/analysis-report/daily-channel-report/download`,
				{
					clientId: searchForm.values.clientId,
					startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
					endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT)
				},
				{ responseType: 'arraybuffer' }
			);
			let disposition = response.headers['content-disposition'];
			let filename = getDownloadFileName(disposition);
			let blob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsExportingExelReport(false);
		}
	};

	useEffect(() => {
		getClients();
	}, []);

	useEffect(() => {
		if (searchForm.values.clientId && searchForm.values.startDate && searchForm.values.endDate) {
			const reportParams = {
				clientId: searchForm.values.clientId,
				startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
				endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT)
			};
			setReportParameters(JSON.stringify(reportParams));
		}
	}, [searchForm.values.clientId, searchForm.values.startDate, searchForm.values.endDate]);

	useEffect(() => {
		if (prmClientId && prmStartDate && prmEndDate) {
			searchForm.setFieldValue('clientId', parseInt(prmClientId));
			searchForm.setFieldValue('startDate', moment(prmStartDate).toDate());
			searchForm.setFieldValue('endDate', moment(prmEndDate).toDate());
		} else {
			let reportParams = getReportParameters();
			if (reportParams) {
				let reportParamsObj = JSON.parse(reportParams);
				searchForm.setFieldValue('clientId', parseInt(reportParamsObj.clientId));
				searchForm.setFieldValue('startDate', moment(reportParamsObj.startDate).toDate());
				searchForm.setFieldValue('endDate', moment(reportParamsObj.endDate).toDate());
				searchForm.validateForm();
			}
		}

		if (!isLoadingClientList && !searchLoading && searchForm.isValid && searchForm.dirty) {
			searchForm.submitForm();
		}
	}, [isLoadingClientList, searchForm.isValid, searchForm.dirty]);

	const getClients = async () => {
		if (!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA)) {
			let clients = get(userAccess, 'userProfile.clients');

			let clientList: IOptionItem[] = [];
			clients?.forEach((value: any) => {
				clientList.push({ value: value.id, label: value.name });
			});

			let sortedClients = clientList.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);

			return;
		}
		try {
			setIsLoadingClientList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/client-account/list`);
			var clients: IOptionItem[] = [];
			response.data.userAccountList?.forEach((value: any) => {
				clients.push({ value: value.id, label: value.clientName });
			});
			let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingClientList(false);
		}
	};

	const getPageCSS = () => {
		return `@page { margin: 15px  15px  15px  15px !important; background-color: #FFF;size: landscape; }`;
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('channel_report')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }} id="channel-report">
					<style>{getPageCSS()}</style>
					<Typography variant="h6" sx={{ marginTop: '10px' }}>
						{t('Search')}
					</Typography>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ padding: '10px 20px' }}>
							<Grid container spacing={1} sx={{ padding: '10px 0px 24px 0px' }}>
								<Grid item md={12} xs={12} sx={{ paddingTop: 0, marginBottom: '15px' }}>
									<Typography variant="info1" sx={{ fontSize: '11px', fontWeight: 400, lineHeight: '16.5px' }}>
										{t('need_to_select_client_to_start_the_search')}
									</Typography>
								</Grid>
								<Grid item md={3} xs={12}>
									<CPSingleSelectAutoCompleteDropDown
										name="clientId"
										size="small"
										options={clients}
										label={t('client')}
										loading={isLoadingClientList}
										disableClearable
										onBlur={searchForm.handleBlur}
										setFieldValue={searchForm.setFieldValue}
										error={searchForm.touched.clientId && searchForm.errors.clientId ? true : false}
										helperText={searchForm.touched.clientId ? searchForm.errors.clientId : ''}
										// disabled={
										// 	!isAuthOperation(
										// 		get(userAccess, 'userProfile.featureList', []),
										// 		ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
										// 	)
										// }
										value={get(
											[...clients].filter((e) => e.value === searchForm.values.clientId),
											'0',
											null
										)}
									/>
								</Grid>
								<Grid item md={1} xs={1} justifyContent="center" alignItems="center" display="flex">
									<Divider orientation="vertical" style={{ height: '100%', width: '1px' }} />
								</Grid>
								<Grid item md={3} xs={12}>
									<CPDatePicker
										name="startDate"
										label={t('start_date')}
										handleChange={(date) => {
											searchForm.setFieldValue('startDate', date ? date : undefined);
										}}
										size="small"
										fullWidth
										value={searchForm.values.startDate}
										onBlur={searchForm.handleBlur}
										error={searchForm.touched.startDate && searchForm.errors.startDate ? true : false}
										helperText={searchForm.touched.startDate ? searchForm.errors.startDate : ''}
										maxDate={searchForm.values.endDate}
										allowKeyInput={true}
									/>
								</Grid>
								<Grid item md={3} xs={12}>
									<CPDatePicker
										name="endDate"
										label={t('end_date')}
										handleChange={(date) => {
											searchForm.setFieldValue('endDate', date ? date : undefined);
										}}
										size="small"
										fullWidth
										minDate={searchForm.values.startDate}
										value={searchForm.values.endDate}
										onBlur={searchForm.handleBlur}
										error={searchForm.touched.endDate && searchForm.errors.endDate ? true : false}
										helperText={searchForm.touched.endDate ? searchForm.errors.endDate : ''}
										maxDate={ moment().subtract(1, 'd').toDate()}
										allowKeyInput={true}
									/>
								</Grid>

								<Grid item md={2} xs={12}>
									<CPLoadingButton
										label={
											<>
												<ManageSearchIcon sx={{ marginRight: '12px' }} />
												{t('search')}
											</>
										}
										variant={'contained'}
										style={{ width: '100%', borderRadius: '32px' }}
										loading={isLoadingClientList || searchLoading}
										onClick={searchForm.submitForm}
										disabled={!(searchForm.isValid && searchForm.dirty)}
									/>
								</Grid>
							</Grid>
						</div>
					</div>
					<Grid container spacing={2} sx={{ marginBottom: '15px' }}>
						<Grid item xs={6}>
							<Typography variant="h6" sx={{ marginTop: '15px' }}>
								{t('channel_report')}
							</Typography>
						</Grid>
						<Grid item xs={6} justifyContent={'flex-end'} display="flex">
							<span style={{ padding: '20px' }}></span>
							<CPLoadingButton
								label={t('export')}
								startIcon={<FileUploadIcon />}
								onClick={handleDataExport}
								variant="contained"
								loading={isExportingExelReport}
								disabled={!(searchForm.isValid && searchForm.dirty && !searchLoading)}
							/>
						</Grid>
					</Grid>
					<ScrollContainer
						hideScrollbars={false}
						className="scroll-container"
						style={{
							overflowX: 'scroll',
							display: 'flex',
							width: settings.isNavBarOpen ? '75vw' : '88vw',
							paddingBottom: '30px'
						}}
					>
						<div id="ChannelReportContainer" style={{ backgroundColor: '#FFF' }}>
							<div id="client-name-div"></div>
							<div id="date-period-div"></div>

							<ChannelReportContainer
								channelData={channelData}
								channels={uniqueChannels}
								funnelReportDataList={rows}
								searchFunnnelReportLoading={searchLoading}
							/>
						</div>
					</ScrollContainer>
				</div>
			</main>
		</div>
	);
};

export default ChannelReport;
