import NumberFormat from 'react-number-format';
import { forwardRef } from 'react';
interface ICPNumberPercentageFormat {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const CPNumberPercentageFormat = forwardRef<NumberFormat<number>, ICPNumberPercentageFormat>(
	function NumberFormatCustom(props, ref) {
		const { onChange, ...other } = props;
		return (
			<NumberFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value
						}
					});
				}}
				isNumericString
				decimalScale={0}
				suffix="%"
				isAllowed={(value) => {
					return (value.floatValue ?? 0) <= 100 && (value.floatValue ?? 0) >= 0;
				}}
			/>
		);
	}
);
export default CPNumberPercentageFormat;
