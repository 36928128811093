import { Configuration } from "@azure/msal-browser";
const passwordResetPolicy = process.env.REACT_APP_PASSWORD_RESET_POLICY;
const tenantName = process.env.REACT_APP_TENANT_NAME ;
const signInPolicy = process.env.REACT_APP_SIGNIN_POLICY;
const AuthorityUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signInPolicy}`;
const PasswordResetAuthorityUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${passwordResetPolicy}`;
const clientID = process.env.REACT_APP_CLIENT_ID ?? "";
const scope = `${clientID} openid offline_access`;

const msalConfig: Configuration = {
	auth: {
		clientId: clientID,
		authority: AuthorityUrl,  // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		redirectUri: typeof window !== 'undefined' ? window.location.origin : '',
		knownAuthorities: [`${tenantName}.b2clogin.com`],
		postLogoutRedirectUri: typeof window !== 'undefined' ? window.location.origin : '',
		navigateToLoginRequestUrl: true
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	}
};

const forgotPasswordRequest = {
	scopes: [scope],
	authority: PasswordResetAuthorityUrl,
	navigateToLoginRequestUrl: true
};

const logoutRequest = {
    postLogoutRedirectUri: typeof window !== 'undefined' ? window.location.origin : '',
  };

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
	scopes: [scope]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/oidc/userinfo'
};
//graph.windows.net

export { msalConfig, loginRequest, graphConfig, forgotPasswordRequest,logoutRequest, signInPolicy,  passwordResetPolicy};
