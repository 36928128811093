import { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';

interface ICPSnackbar {
	content: React.ReactNode;
	key?: string | number;
}

const CPSnackbar = forwardRef<HTMLDivElement, ICPSnackbar>((props, ref) => {
	const { content } = props;
	return <SnackbarContent ref={ref}>{content}</SnackbarContent>;
});
export default CPSnackbar;
