import {
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip
} from '@mui/material';
import { ChangeEvent, Fragment } from 'react';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { get } from 'lodash';

interface ICPSingleSelectDropDown {
	options: IOptionItem[];
	label?: string;
	fullWidth?: boolean;
	size?: 'small' | 'medium';
	error?: boolean | undefined;
	helperText?: string;
	setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	onChange?: (event: SelectChangeEvent<string>) => void;
	onBlur?: (e: ChangeEvent) => void;
	name: string;
	id?: string | undefined;
	disabled?: boolean;
	required?: boolean;
	value?: any;
	loading?: boolean;
	MenuProps?: any;
	inputProps?: any;
	showTooltip?: boolean | undefined;
	showTooltipMinimumLength?: number;
}

export default function CPSingleSelectDropDown(props: ICPSingleSelectDropDown) {
	const {
		label,
		options,
		fullWidth,
		size,
		name,
		helperText,
		error,
		setFieldValue,
		id,
		onBlur,
		onChange,
		disabled,
		required,
		value,
		loading,
		MenuProps,
		inputProps,
		showTooltipMinimumLength = 15,
		showTooltip = true
	} = props;
	const showToolTipForOption = (label: string) => {
		var toolTipValue = '';

		if (showTooltip && label !== undefined) {
			const tooltip = label.toString();
			if (tooltip.length > showTooltipMinimumLength) {
				toolTipValue = tooltip;
			}
		}
		return toolTipValue;
	};
	return (
		<Tooltip
			title={showToolTipForOption(
				get(
					Object.values(options).filter((e) => e.value === value),
					'0.label',
					''
				)
			)}
			placement="bottom-start"
		>
			<FormControl
				fullWidth={fullWidth}
				size={size}
				error={error}
				disabled={disabled}
				required={required}
				sx={{ display: 'flex', justifyContent: 'center' }}
			>
				<InputLabel id={id} shrink={value !== null && value !== '' ? true : false}>
					{label}
				</InputLabel>
				<Select
					label={label}
					onChange={(e) => {
						setFieldValue?.(name, e.target.value);
						onChange?.(e);
					}}
					onBlur={onBlur}
					name={name}
					defaultValue=""
					value={value}
					MenuProps={MenuProps}
					inputProps={inputProps}
				>
					{Object.values(options).map((option) => (
						<MenuItem key={option.value} value={option.value}>
							<Tooltip
								key={option.value}
								title={option.value !== value ? showToolTipForOption(option.label) : ''}
								placement="bottom-start"
							>
								<span style={{ width: '100%' }}>{option.label}</span>
							</Tooltip>
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{helperText}</FormHelperText>
				<Fragment>
					{loading ? <CircularProgress color="inherit" size={20} sx={{ position: 'absolute', right: '32px' }} /> : null}
				</Fragment>
			</FormControl>
		</Tooltip>
	);
}
