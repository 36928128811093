import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { toFirstLetterCapital } from 'utils/helpers';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isValidRoute } from 'utils/commonUtil';

interface IBreadcrumb {
	title: string;
	actions?: React.ReactNode;
}

export default function Breadcrumb(props: IBreadcrumb) {
	const { title, actions } = props;
	const location = useLocation();
	const linkPath = location.pathname.split('/');
	const [breadcrumbs, setBreadcrumbs] = useState<ReactNode[]>();
	const navigate = useNavigate();
	const { prmClientId, id, prmStartDate, prmEndDate, prmStartDate2, prmEndDate2 } = useParams();

	if (prmClientId) {
		var indexclientId = linkPath.indexOf(prmClientId);
		if (indexclientId !== -1) {
			linkPath.splice(indexclientId, 1);
		}
	}

	if (id) {
		var indexid = linkPath.indexOf(id);
		if (indexid !== -1) {
			linkPath.splice(indexid, 1);
		}
	}

	if (prmStartDate) {
		var indexStartDate = linkPath.indexOf(prmStartDate);
		if (indexStartDate !== -1) {
			linkPath.splice(indexStartDate, 1);
		}
	}

	if (prmEndDate) {
		var indexEndDate = linkPath.indexOf(prmEndDate);
		if (indexEndDate !== -1) {
			linkPath.splice(indexEndDate, 1);
		}
	}
	if (prmStartDate2) {
		var indexStartDate2 = linkPath.indexOf(prmStartDate2);
		if (indexStartDate2 !== -1) {
			linkPath.splice(indexStartDate2, 1);
		}
	}

	if (prmEndDate2) {
		var indexEndDate2 = linkPath.indexOf(prmEndDate2);
		if (indexEndDate2 !== -1) {
			linkPath.splice(indexEndDate2, 1);
		}
	}

	useEffect(() => {
		const pathArray = linkPath.map((path: any, index: number) => {
			let place = toFirstLetterCapital(path?.replaceAll('-', '_'));
			if (path !== undefined && path !== '') {
				const pathURL = linkPath.slice(0, index + 1).join('/');
				const validRoute = isValidRoute(pathURL);
				return (
					<Link
						underline={validRoute ? 'hover' : 'none'}
						key={path}
						color="inherit"
						sx={{ cursor: validRoute ? 'pointer' : 'default' }}
						onClick={validRoute ? () => handleClick(pathURL) : () => {}}
						fontSize={'14px'}
					>
						{place}
					</Link>
				);
			}
		});
		if (pathArray.length === 2) {
			pathArray.push(<div key={'last'}></div>);
		}
		setBreadcrumbs(pathArray);
	}, []);

	function handleClick(path: string) {
		navigate(path);
	}

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				backgroundColor: '#FFF',
				marginBottom: 2,
				borderRadius: '10px',
				padding: '16px 32px',
				boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.05)'
			}}
		>
			<div style={{}}>
				<Typography fontSize={'24px'} fontWeight={500}>
					{title}
				</Typography>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
			</div>
			<div style={{ float: 'right', alignSelf: 'center' }}>{actions}</div>
		</Stack>
	);
}
