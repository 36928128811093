import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';

interface ICPCheckBox {
	label?: string | React.ReactNode;
	size?: 'small' | 'medium';
	style?: React.CSSProperties;
	handleChange?: (e: ChangeEvent) => void;
	disabled?: boolean;
	name?: string;
	checked?: boolean;
}

export default function CPCheckBox(props: ICPCheckBox) {
	const { label, size, handleChange, style, disabled, name, checked } = props;
	return (
		<FormControlLabel
			control={
				<Checkbox size={size} onChange={handleChange} disabled={disabled} style={style} name={name} checked={checked} />
			}
			label={label}
		/>
	);
}
