import { Fragment } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, Tooltip } from '@mui/material';
import { ChangeEvent } from 'react';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { get } from 'lodash';

interface ICPSingleSelectAutoCompleteDropDown {
	label: string;
	options: IOptionItem[];
	size?: 'small' | 'medium';
	id?: string;
	error?: boolean | undefined;
	helperText?: string;
	setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	onBlur?: (e: ChangeEvent) => void;
	name: string;
	loading?: boolean;
	disableClearable?: boolean;
	required?: boolean;
	disabled?: boolean;
	value?: IOptionItem | null | string | number | any;
	readOnly?: boolean | undefined;
	showTooltip?: boolean | undefined;
	showTooltipMinimumLength?: number;
	onChange?: (value: IOptionItem) => void;
}

export default function CPSingleSelectAutoCompleteDropDown(props: ICPSingleSelectAutoCompleteDropDown) {
	const {
		label,
		setFieldValue,
		error,
		helperText,
		onBlur,
		size,
		options,
		id,
		name,
		loading,
		disableClearable,
		required,
		disabled,
		value,
		readOnly,
		showTooltipMinimumLength = 15,
		showTooltip = true,
		onChange
	} = props;
	const showToolTipForOption = (label: string) => {
		var toolTipValue = '';

		if (showTooltip && label !== undefined) {
			const tooltip = label.toString();
			if (tooltip.length > showTooltipMinimumLength) {
				toolTipValue = tooltip;
			}
		}
		return toolTipValue;
	};

	return (
		<Tooltip title={showToolTipForOption(get(value, 'label', ''))} placement="bottom-start">
			<Autocomplete
				disabled={disabled}
				size={size}
				id={id}
				fullWidth={true}
				options={options}
				getOptionLabel={(option) => option.label}
				onChange={(_: object, value: any | null) => {
					setFieldValue?.(name, value ? value.value : '');
					if (onChange) {
						onChange(value);
					}
				}}
				disableClearable={disableClearable}
				loading={loading}
				value={value}
				isOptionEqualToValue={(option, value) => option.value === value.value}
				readOnly={readOnly}
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} sx={{ position: 'absolute', right: '32px' }} />
									) : null}
									{params.InputProps.endAdornment}
								</Fragment>
							)
						}}
						name={name}
						label={label}
						error={error}
						onBlur={onBlur}
						helperText={helperText}
					/>
				)}
				renderOption={(props, option) => (
					<Tooltip key={option.value} title={showToolTipForOption(option.label.toString())} placement="bottom-start">
						<Box
							component="li"
							{...props}
							sx={{ textOverflow: 'ellipsis', display: 'block !important', whiteSpace: 'nowrap' }}
						>
							<div />
							{option.label}
						</Box>
					</Tooltip>
				)}
			/>
		</Tooltip>
	);
}
