import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { deleteEndpointPromise, getEndpointPromise, updateEndpointPromise } from 'services/apiServices';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getNotificationStatuses, isAuthOperation } from 'utils/commonUtil';
import NotificationTable from 'components/molecules/NotificationTable';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import CPSingleSelectDropDown from 'components/atoms/CPSingleSelectDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { get } from 'lodash';
import { IQueryParams } from 'types/queryParams.type';
import ACCESS_TAGS from 'common/constants/accessTags';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
import CPButton from 'components/atoms/CPButton';
import { NotificationStatuses } from 'common/enums/notificationStatus.enum';

const Notifications = () => {
	const { t } = useTranslation();
	const [rowCount, setRowCount] = useState<number>(0);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [rows, setRows] = useState<any>([]);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [queryParams, setQueryParams] = useState<IQueryParams>({ keyword: '', pageNo: 0 });
	const [deleteNotificationConfirmation, setDeleteNotificationConfirmation] = useState(null);
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const [isDeletingNotification, setIsDeletingNotification] = useState<boolean>(false);
	const [isUpdatingNotification, setIsUpdatingNotification] = useState<boolean>(false);
	const [updateNotificationConfirmation, setUpdateNotificationConfirmation] = useState({ data: null, action: '' });
	const validationSchema = yup.object({
		filter: yup.string().required(t('value_required'))
	});

	useEffect(() => {
		handleNotificationSearch();
	}, [queryParams, rowsPerPage]);

	const searchForm = useFormik({
		initialValues: {
			filter: 'ALL'
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			handleNotificationSearch();
		}
	});

	const handleNotificationSearch = async () => {
		try {
			setSearchLoading(true);
			const response = await getEndpointPromise(
				`/entitymanager/notification/all?count=${rowsPerPage}&page=${queryParams.pageNo}&keyword=${encodeURIComponent(
					searchForm.values.filter
				)}`
			);
			setRows(get(response, 'data.notificationsList'));
			setRowCount(get(response, 'data.pagination.total'));
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchLoading(false);
		}
	};

	const notificationStatuses = getNotificationStatuses();

	const handleDelete = async (data: any) => {
		try {
			setIsDeletingNotification(true);
			await deleteEndpointPromise(`/entitymanager/notification/delete?notificationId=${data.id}`);
			handleNotificationSearch();
			setDeleteNotificationConfirmation(null);
			const key = enqueueSnackbar(
				<CPAlert
					title={t('delete_successfully_notification')}
					message={t('delete_successfully_notification_message')}
					severity={'info'}
					onClose={() => closeSnackbar(key)}
				/>
			);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsDeletingNotification(false);
		}
	};

	const handleUpdate = async (notificationData: any) => {
		setIsUpdatingNotification(true);
		let notification: any = {
			id: get(notificationData.data, 'id'),
			newStatus: notificationData.action
		};

		try {
			await updateEndpointPromise(`/entitymanager/notification/update`, notification);
			setUpdateNotificationConfirmation({ data: null, action: '' });
			if (notificationData.action === NotificationStatuses.RESOLVED) {
				const key = enqueueSnackbar(
					<CPAlert
						title={t('information_updated')}
						message={t('notification_info_updated')}
						severity={'info'}
						onClose={() => closeSnackbar(key)}
					/>
				);
			}
			handleNotificationSearch();
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsUpdatingNotification(false);
		}
	};

	const handlePageNo = (pageNo: number) => {
		setQueryParams({ ...queryParams, pageNo: pageNo });
	};

	const handleFilterChanged = (e: any) => {
		setQueryParams({ ...queryParams, pageNo: 0 });
		searchForm.handleChange(e);
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('notification')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }} id="funnel-report">
					<div style={{ padding: '10px 20px' }}>
						<Grid container spacing={1} sx={{ padding: '10px 0px 24px 0px' }}>
							<Grid item md={2} xs={12}>
								<CPSingleSelectDropDown
									name="filter"
									options={notificationStatuses}
									fullWidth
									size="small"
									label={t('filter')}
									onBlur={searchForm.handleBlur}
									error={searchForm.touched.filter && searchForm.errors.filter ? true : false}
									helperText={searchForm.touched.filter ? searchForm.errors.filter : ''}
									onChange={handleFilterChanged}
									required
									value={searchForm.values.filter}
									MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								/>
							</Grid>
							<Grid item md={3} xs={12} sx={{ padding: '8px 0px 0px 30px !important' }}>
								<CPLoadingButton
									label={
										<>
											<RefreshIcon />
										</>
									}
									variant={'contained'}
									style={{ width: '10px', borderRadius: '32px', backgroundColor: '#CCCCCC' }}
									loading={searchLoading || isUpdatingNotification}
									onClick={searchForm.submitForm}
								/>
							</Grid>
						</Grid>
					</div>

					<div id="NotificationTableContainer" style={{ backgroundColor: '#FFF' }}>
						<div id="client-name-div"></div>
						<div id="date-period-div"></div>
						<NotificationTable
							onEditAction={
								isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_ALL_NOTIFICATIONS)
									? (data, action) =>
											action === NotificationStatuses.READ
												? handleUpdate({ data, action })
												: setUpdateNotificationConfirmation({ data, action })
									: undefined
							}
							onDeleteAction={
								isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_ALL_NOTIFICATIONS)
									? (data) => setDeleteNotificationConfirmation(data)
									: undefined
							}
							rowCount={rowCount}
							rowsPerPage={rowsPerPage}
							page={queryParams.pageNo}
							setRowsPerPage={setRowsPerPage}
							setPage={handlePageNo}
							loading={searchLoading}
							rows={rows}
						/>
					</div>
					{/* delete confirmation dialog */}
					<CPConfirmationDialog
						open={deleteNotificationConfirmation !== null}
						title={t('delete_notification_title')}
						content={<>{t('notification_delete_confirmation')}</>}
						buttonOptions={
							<>
								<CPButton
									style={{ minWidth: '150px' }}
									label={t('cancel')}
									onClick={() => {
										setDeleteNotificationConfirmation(null);
									}}
									disabled={isDeletingNotification}
								/>
								<CPLoadingButton
									loading={isDeletingNotification}
									style={{ minWidth: '150px' }}
									label={t('delete')}
									variant="contained"
									color="error"
									onClick={() => {
										handleDelete(deleteNotificationConfirmation);
									}}
								/>
							</>
						}
					/>
					{/* Reolve confirmation dialog */}
					<CPConfirmationDialog
						open={updateNotificationConfirmation.data !== null}
						title={t('update_notification_title')}
						content={<>{t('notification_update_confirmation')}</>}
						buttonOptions={
							<>
								<CPButton
									style={{ minWidth: '150px' }}
									label={t('cancel')}
									onClick={() => {
										setUpdateNotificationConfirmation({ data: null, action: '' });
									}}
									disabled={isUpdatingNotification}
								/>
								<CPLoadingButton
									loading={isUpdatingNotification}
									style={{ minWidth: '150px' }}
									label={t('update')}
									variant="contained"
									color="error"
									onClick={() => {
										handleUpdate(updateNotificationConfirmation);
									}}
								/>
							</>
						}
					/>
				</div>
			</main>
		</div>
	);
};

export default Notifications;
