import CPButton from 'components/atoms/CPButton';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from './context';

export const PromtContext = (props: any) => {
	const { children } = props;
	const cbReject = useRef<() => void>();
	const cbResolve = useRef<() => void>();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const showPrompt = useCallback(
		() =>
			new Promise<void>((resolve, reject) => {
				cbReject.current = reject;
				cbResolve.current = resolve;
				setOpen(true);
			}),
		[]
	);

	const close = useCallback(() => {
		if (cbReject.current) {
			cbReject.current();
		}
		setOpen(false);
	}, []);

	const confirm = useCallback(() => {
		if (cbResolve.current) {
			cbResolve.current();
		}
		setOpen(false);
	}, []);

	return (
		<Context.Provider value={showPrompt}>
			{children}
			<CPConfirmationDialog
				open={open}
				title={t('are_you_sure_you_want_to_leave')}
				content={t(`you_have_n_unsaved_changes`)}
				buttonOptions={
					<>
						<CPButton style={{ minWidth: '150px' }} label={t('go_back')} onClick={close} />
						<CPButton style={{ minWidth: '150px' }} label={t('ok')} variant="contained" onClick={confirm} />
					</>
				}
			/>
		</Context.Provider>
	);
};
