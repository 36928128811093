import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import CPButton from 'components/atoms/CPButton';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import CPTextField from 'components/atoms/CPTextField';
import RemovableTextField from 'components/molecules/RemovableTextField';
import ResponsiveDialog from 'components/molecules/ResponsiveDialog';
import { getIn, useFormik } from 'formik';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { get, isEmpty } from 'lodash';
import isNull from 'lodash/isNull';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomQuery, ICustomQueryCreateBased } from 'types/customQuery.type';
import * as yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import CPSwitch from 'components/atoms/CPSwitch';
import CPNumberPercentageFormat from 'components/atoms/CPNumberPercentageFormat';
import { IChannel, IChannelsLov } from 'types/channel.type';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemovableSplitRow from 'components/molecules/RemovableSplitRow';
import CPAlert from 'components/atoms/CPAlert';
import { getEndpointPromise, insertEndpointPromise, updateEndpointPromise } from 'services/apiServices';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
import CustomQueryTableType from 'common/enums/customQueryTableType';
import { IQuerySplit } from 'types/globalQuery.type';
import CPDatePicker from 'components/atoms/CPDatePicker';
import { MOMENT_DATE_FORMAT } from 'common/constants/applicationConstants';
import moment from 'moment';
interface ICustomizedQuerySplitForm {
	channelId: number;
	percentage: number;
}
interface ICustomizedQueryGoogleAnalyticsForm {
	match?: {
		source?: string | undefined;
		medium?: string | undefined;
		campaign?: string[];
		campaignField?: string;
	};
	dontMatch?: {
		source?: string | undefined;
		medium?: string | undefined;
		campaign?: string[];
		campaignField?: string;
	};
	querySplit?: ICustomizedQuerySplitForm[];
	isMapAllUnmapped: boolean;
}

interface ICustomizedQueryAdPlatformForm {
	match?: {
		campaign?: string[];
		campaignField?: string;
	};
	dontMatch?: {
		campaign?: string[];
		campaignField?: string;
	};
	querySplit?: ICustomizedQuerySplitForm[];
	isMapAllUnmapped: boolean;
}

interface ICustomizedQueryForm {
	clientId: number | null;
	queryName: string;
	googleAnalytics?: ICustomizedQueryGoogleAnalyticsForm;
	adPlatform?: ICustomizedQueryAdPlatformForm;
	querySplitChannelFieldValue?: number;
	querySplitChannelPercentageFieldValue?: number;
	querySplit?: ICustomizedQuerySplitForm[];
	asOfDate: Date;
}

interface IAddEditCustomizedQuery {
	open: boolean;
	editableCustomQuery?: ICustomQuery | null;
	handleClose: () => void;
	getAllCustomQueries?: () => void;
	readOnlyCustomQuery?: boolean;
	createCustomQuery?: ICustomQueryCreateBased | null;
}
const AddEditCustomizedQuery = (props: IAddEditCustomizedQuery) => {
	const { editableCustomQuery, readOnlyCustomQuery, open, handleClose, createCustomQuery, getAllCustomQueries } = props;
	const { t } = useTranslation();
	const [client, setClient] = useState<IOptionItem>({ label: '', value: 0 });
	const [googleAnalyticsCommonError, setGoogleAnalyticsCommonError] = useState<string | null>(null);
	const [adPlatformCommonError, setAdPlatformCommonError] = useState<string | null>(null);
	const [splitError, setSplitError] = useState<string | null>(null);
	const [channels, setChannels] = useState<IChannelsLov | null>(null);
	const [selectableChannelList, setSelectableChannelList] = useState<IOptionItem[] | []>([]);
	const [channelList, setChannelList] = useState<IOptionItem[] | []>([]);
	const [isLoadingChannels, setIsLoadingChannels] = useState<boolean>(false);
	const [isCreateQueryButtonPressed, setIsCreateQueryButtonPressed] = useState<boolean>(false);
	const [isCreatingCustomQuery, setIsCreatingCustomQuery] = useState<boolean>(false);
	const [openCreateCustomQueryConfirmation, setOpenCreateCustomQueryConfirmation] = useState(false);
	const [tempGoogleAnalyticsFormData, setTempGoogleAnalyticsFormData] =
		useState<ICustomizedQueryGoogleAnalyticsForm | null>(null);
	const [tempAdPlatformFormData, setTempAdPlatformFormData] = useState<ICustomizedQueryAdPlatformForm | null>(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isHasSplitData, setIsHasSplitData] = useState<boolean>(false);
	const splitErrorRef = useRef(null);
	const googleAnalyticsCommonErrorRef = useRef(null);
	const theme = useTheme();
	const validationSchema = yup.object({
		queryName: yup.string().required(t('value_required')).max(255, t('cannot_exceed_255')).trim(),
		adPlatform: yup.object().shape({
			match: yup.object().shape({
				campaignField: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.trim()
			}),
			dontMatch: yup.object().shape({
				campaignField: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.trim()
			})
		}),
		googleAnalytics: yup.object().shape({
			match: yup.object().shape({
				source: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.nullable()
					.trim(),
				medium: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.nullable()
					.trim(),
				campaignField: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.trim()
			}),
			dontMatch: yup.object().shape({
				source: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.nullable()
					.trim(),
				medium: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.nullable()
					.trim(),
				campaignField: yup
					.string()
					.max(255, t('cannot_exceed_255'))
					.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
					.trim()
			})
		}),
		asOfDate: yup
			.date()
			.default(() => new Date())
			.required(t('value_required'))
	});
	const customQueryForm = useFormik({
		initialValues: {
			clientId: null,
			queryName: '',
			googleAnalytics: {
				match: {
					source: undefined,
					medium: undefined,
					campaignField: '',
					campaign: []
				},
				dontMatch: {
					source: undefined,
					medium: undefined,
					campaignField: '',
					campaign: []
				},
				querySplit: [],
				isMapAllUnmapped: false
			},
			adPlatform: {
				match: {
					campaignField: '',
					campaign: []
				},
				dontMatch: {
					campaignField: '',
					campaign: []
				},
				querySplit: [],
				isMapAllUnmapped: false
			},
			querySplitChannelFieldValue: undefined,
			querySplitChannelPercentageFieldValue: 0,
			querySplit: [],
			asOfDate: new Date()
		},
		validationSchema: validationSchema,
		onSubmit: (_: ICustomizedQueryForm) => {
			if (isFormValidateToSubmit(true)) {
				handleAddNewSplit();
				handleAddAdPlatformMatchCampaign();
				handleAddGoogleAnalyticsMatchCampaign();
				handleAddAdPlatformDontMatchCampaign();
				handleAddGoogleAnalyticsDontMatchCampaign();
				setOpenCreateCustomQueryConfirmation(true);
			}
			setIsCreateQueryButtonPressed(true);
		}
	});

	useEffect(() => {
		let querySplit = customQueryForm.values.querySplit;
		let querySplitChannelFieldValue = customQueryForm.values.querySplitChannelFieldValue;
		if (
			(querySplitChannelFieldValue !== undefined && (querySplitChannelFieldValue as any).toString() !== '') ||
			(querySplit?.length ?? 0) > 0
		) {
			setIsHasSplitData(true);
		} else {
			setIsHasSplitData(false);
		}
	}, [customQueryForm.values.querySplitChannelFieldValue, customQueryForm.values.querySplit]);

	useEffect(() => {
		if (!isNull(createCustomQuery)) {
			setClient(get(createCustomQuery, 'client') ?? { label: '', value: 0 });
			let fromTable = get(createCustomQuery, 'fromTable', null);
			customQueryForm.setFieldValue('clientId', get(createCustomQuery, 'client.value', null));
			let campaign = get(createCustomQuery, 'campaign', null);
			let source = get(createCustomQuery, 'source', null);
			let medium = get(createCustomQuery, 'medium', null);
			if (!isNull(campaign)) {
				let googleAnalyticsCampaign: string[] = [`[${campaign}]`];
				let adPlatformCampaign: string[] = [`[${campaign}]`];
				switch (fromTable) {
					case CustomQueryTableType.ADPLATFORM:
						customQueryForm.setFieldValue('adPlatform.match.campaign', adPlatformCampaign ?? []);
						break;
					case CustomQueryTableType.GOOGLEANALYTICS:
						customQueryForm.setFieldValue('googleAnalytics.match.campaign', googleAnalyticsCampaign ?? []);
						break;
					case CustomQueryTableType.GOOGLEANALYTICS_AND_ADPLATFORM:
						customQueryForm.setFieldValue('googleAnalytics.match.campaign', googleAnalyticsCampaign ?? []);
						customQueryForm.setFieldValue('adPlatform.match.campaign', adPlatformCampaign ?? []);
						break;
				}
			}
			if (!isNull(source) && !isEmpty(source)) {
				let googleAnalyticsSource: string = `[${source}]`;
				switch (fromTable) {
					case CustomQueryTableType.GOOGLEANALYTICS || CustomQueryTableType.GOOGLEANALYTICS_AND_ADPLATFORM:
						customQueryForm.setFieldValue('googleAnalytics.match.source', googleAnalyticsSource ?? '');
						break;
				}
			}
			if (!isNull(medium)) {
				let googleAnalyticsMedium: string = `[${medium}]`;
				switch (fromTable) {
					case CustomQueryTableType.GOOGLEANALYTICS || CustomQueryTableType.GOOGLEANALYTICS_AND_ADPLATFORM:
						customQueryForm.setFieldValue('googleAnalytics.match.medium', googleAnalyticsMedium ?? '');
						break;
				}
			}
		}
	}, [createCustomQuery]);

	useEffect(() => {
		if (open) {
			getAllChannels();
		}
	}, [open]);

	useEffect(() => {
		if (channels) {
			mapChannelList();
			mapSelectableChannelList();
		}
	}, [channels]);

	useEffect(() => {
		mapSelectableChannelList();
	}, [customQueryForm.values.querySplitChannelFieldValue, customQueryForm.values.querySplit]);

	useEffect(() => {
		isFormValidateToSubmit();
	}, [
		customQueryForm.values.adPlatform,
		customQueryForm.values.googleAnalytics,
		isCreateQueryButtonPressed,
		customQueryForm.values.querySplitChannelFieldValue,
		customQueryForm.values.querySplit,
		customQueryForm.values.querySplitChannelPercentageFieldValue
	]);

	useEffect(() => {
		if (editableCustomQuery) {
			let queryName = get(editableCustomQuery, 'queryName', '');
			let source = get(editableCustomQuery, 'googleAnalytics.match.source', '');
			let medium = get(editableCustomQuery, 'googleAnalytics.match.medium', '');
			let googleAnalyticsCampaign = get(editableCustomQuery, 'googleAnalytics.match.campaign', []);
			let adPlatformCampaign = get(editableCustomQuery, 'adPlatform.match.campaign', []);

			//dont match
			let dontMatchSource = get(editableCustomQuery, 'googleAnalytics.dontMatch.source', '');
			let dontMatchMedium = get(editableCustomQuery, 'googleAnalytics.dontMatch.medium', '');
			let dontMatchGoogleAnalyticsCampaign = get(editableCustomQuery, 'googleAnalytics.dontMatch.campaign', []);
			let dontMatchAdPlatformCampaign = get(editableCustomQuery, 'adPlatform.dontMatch.campaign', []);

			let googleAnalyticsQuerySplits = get(editableCustomQuery, 'googleAnalytics.querySplit', []) as IQuerySplit[];
			let googleAnalyticsIsMapAllUnmapped = get(editableCustomQuery, 'googleAnalytics.isMapAllUnmapped', false);
			let adPlatformIsMapAllUnmapped = get(editableCustomQuery, 'adPlatform.isMapAllUnmapped', false);
			let querySplit: ICustomizedQuerySplitForm[] = [];
			let chanelList: IOptionItem[] = channelList;
			let ids = chanelList.map((item) => item.value);
			let client = get(editableCustomQuery, 'client', { clientId: 0, clientName: '' });
			let asOfDate = get(editableCustomQuery, 'asOfDate', '');

			if (!isNull(client)) {
				setClient({ label: client.clientName ?? '', value: client.clientId ?? 0 });
				customQueryForm.setFieldValue('clientId', get(client, 'clientId', null));
			}
			googleAnalyticsQuerySplits.forEach((item) => {
				if (!ids.includes(item.channelId)) {
					chanelList.push({ value: get(item, 'channelId', 0), label: get(item, 'channelName', '') });
				}
				querySplit.push({ channelId: item.channelId, percentage: item.percentage });
			});
			setChannelList(chanelList);
			customQueryForm.setFieldValue('asOfDate', asOfDate);
			customQueryForm.setFieldValue('querySplit', querySplit);
			customQueryForm.setFieldValue('queryName', queryName);
			customQueryForm.setFieldValue('googleAnalytics.match.source', source ?? '');
			customQueryForm.setFieldValue('googleAnalytics.match.medium', medium ?? '');
			customQueryForm.setFieldValue('googleAnalytics.match.campaign', googleAnalyticsCampaign ?? []);
			customQueryForm.setFieldValue('adPlatform.match.campaign', adPlatformCampaign ?? []);

			//dont match
			customQueryForm.setFieldValue('googleAnalytics.dontMatch.source', dontMatchSource ?? '');
			customQueryForm.setFieldValue('googleAnalytics.dontMatch.medium', dontMatchMedium ?? '');
			customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaign', dontMatchGoogleAnalyticsCampaign ?? []);
			customQueryForm.setFieldValue('adPlatform.dontMatch.campaign', dontMatchAdPlatformCampaign ?? []);

			customQueryForm.setFieldValue('googleAnalytics.isMapAllUnmapped', googleAnalyticsIsMapAllUnmapped ?? false);
			customQueryForm.setFieldValue('adPlatform.isMapAllUnmapped', adPlatformIsMapAllUnmapped ?? false);
		}
	}, [editableCustomQuery]);

	const getAllChannels = async () => {
		try {
			setIsLoadingChannels(true);
			const response = await getEndpointPromise<IChannelsLov>(`/entitymanager/channel/list`);

			setChannels(get(response, 'data'));
		} catch (error) {
			let message = getReadableError(error);

			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingChannels(false);
		}
	};

	const createNewCustomQuery = async (customQuery: any) => {
		try {
			setIsCreatingCustomQuery(true);
			await insertEndpointPromise<any>('/entitymanager/custom-query/create', customQuery);
			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={t('new_customized_query_created')}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			handleCloseModal();
			handleClose();
			getAllCustomQueries?.();
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsCreatingCustomQuery(false);
		}
	};

	const updateCustomQuery = async (customQuery: any) => {
		try {
			setIsCreatingCustomQuery(true);
			await updateEndpointPromise<any>('/entitymanager/custom-query/update', customQuery);
			const key = enqueueSnackbar(
				<CPAlert
					title={t('information_updated')}
					message={t('update_successfully_global_query_message')}
					severity={'info'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			handleCloseModal();
			handleClose();
			getAllCustomQueries?.();
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsCreatingCustomQuery(false);
		}
	};

	const handleCloseModal = () => {
		customQueryForm.resetForm({
			values: {
				queryName: '',
				googleAnalytics: {
					match: {
						source: undefined,
						medium: undefined,
						campaignField: '',
						campaign: []
					},
					dontMatch: {
						source: undefined,
						medium: undefined,
						campaignField: '',
						campaign: []
					},
					querySplit: [],
					isMapAllUnmapped: false
				},
				adPlatform: {
					match: {
						campaignField: '',
						campaign: []
					},
					dontMatch: {
						campaignField: '',
						campaign: []
					},
					querySplit: [],
					isMapAllUnmapped: false
				},
				querySplitChannelFieldValue: undefined,
				querySplitChannelPercentageFieldValue: 0,
				querySplit: [],
				clientId: null,
				asOfDate: new Date()
			}
		});
		setGoogleAnalyticsCommonError(null);
		setAdPlatformCommonError(null);
		setSplitError(null);
		setIsCreateQueryButtonPressed(false);
		handleClose();
	};

	const mapChannelList = () => {
		let channelsList: IChannel[] = channels?.channels ?? [];
		var mapChannels: IOptionItem[] = editableCustomQuery ? channelList : [];
		channelsList.forEach((channel) => {
			let values = mapChannels.map((value) => value.value);
			if (!values.includes(channel.id)) {
				mapChannels.push({ value: channel.id, label: channel.channelName });
			}
		});

		setChannelList(mapChannels);
	};

	const mapSelectableChannelList = () => {
		let channelsList: IChannel[] = channels?.channels ?? [];
		var mapChannels: IOptionItem[] = [];
		let selectedChanelIds = (customQueryForm.values.querySplit ?? []).map(
			(value: ICustomizedQuerySplitForm) => value.channelId
		);
		if (
			customQueryForm.values.querySplitChannelFieldValue &&
			!selectedChanelIds.includes(customQueryForm.values.querySplitChannelFieldValue)
		) {
			selectedChanelIds.push(customQueryForm.values.querySplitChannelFieldValue ?? -1);
		}
		channelsList.forEach((channel) => {
			if (!selectedChanelIds.includes(channel.id)) {
				mapChannels.push({ value: channel.id, label: channel.channelName });
			}
		});
		let sortedChannels = mapChannels.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
		setSelectableChannelList(sortedChannels);
	};

	const handleAddGoogleAnalyticsMatchCampaign = () => {
		let campaignList: string[] = getIn(customQueryForm.values, 'googleAnalytics.match.campaign');
		let newCampaign = (getIn(customQueryForm.values, 'googleAnalytics.match.campaignField') ?? '').trim();
		if (newCampaign !== undefined && newCampaign !== '' && !campaignList.includes(newCampaign)) {
			campaignList.push(newCampaign);
			customQueryForm.setFieldValue('googleAnalytics.match.campaign', campaignList);
		}

		customQueryForm.setFieldValue('googleAnalytics.match.campaignField', '');
	};

	// dont match
	const handleAddGoogleAnalyticsDontMatchCampaign = () => {
		let campaignList: string[] = getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign');
		let newCampaign = (getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaignField') ?? '').trim();
		if (newCampaign !== undefined && newCampaign !== '' && !campaignList.includes(newCampaign)) {
			campaignList.push(newCampaign);
			customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaign', campaignList);
		}

		customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaignField', '');
	};

	const handleRemoveGoogleAnalyticsMatchCampaign = (campaign: string) => {
		let campaignList: string[] = getIn(customQueryForm.values, 'googleAnalytics.match.campaign');
		const index = campaignList.indexOf(campaign);
		if (index > -1) {
			campaignList.splice(index, 1);
			customQueryForm.setFieldValue('googleAnalytics.match.campaign', campaignList);
		}
	};
	// dont match
	const handleRemoveGoogleAnalyticsDontMatchCampaign = (campaign: string) => {
		let campaignList: string[] = getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign');
		const index = campaignList.indexOf(campaign);
		if (index > -1) {
			campaignList.splice(index, 1);
			customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaign', campaignList);
		}
	};

	// Add Ad Platform Campaign
	const handleAddAdPlatformMatchCampaign = () => {
		let campaignList: string[] = getIn(customQueryForm.values, 'adPlatform.match.campaign');
		let newCampaign = (getIn(customQueryForm.values, 'adPlatform.match.campaignField') ?? '').trim();
		if (newCampaign !== undefined && newCampaign !== '' && !campaignList.includes(newCampaign)) {
			campaignList.push(newCampaign);
			customQueryForm.setFieldValue('adPlatform.match.campaign', campaignList);
		}

		customQueryForm.setFieldValue('adPlatform.match.campaignField', '');
	};
	// dont match
	const handleAddAdPlatformDontMatchCampaign = () => {
		let campaignList: string[] = getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign');
		let newCampaign = (getIn(customQueryForm.values, 'adPlatform.dontMatch.campaignField') ?? '').trim();
		if (newCampaign !== undefined && newCampaign !== '' && !campaignList.includes(newCampaign)) {
			campaignList.push(newCampaign);
			customQueryForm.setFieldValue('adPlatform.dontMatch.campaign', campaignList);
		}

		customQueryForm.setFieldValue('adPlatform.dontMatch.campaignField', '');
	};

	// Remove Ad Platform Campaign
	const handleRemoveAdPlatformMatchCampaign = (campaign: string) => {
		let campaignList: string[] = getIn(customQueryForm.values, 'adPlatform.match.campaign');
		const index = campaignList.indexOf(campaign);
		if (index > -1) {
			campaignList.splice(index, 1);
			customQueryForm.setFieldValue('adPlatform.match.campaign', campaignList);
		}
	};
	// dont match
	const handleRemoveAdPlatformDontMatchCampaign = (campaign: string) => {
		let campaignList: string[] = getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign');
		const index = campaignList.indexOf(campaign);
		if (index > -1) {
			campaignList.splice(index, 1);
			customQueryForm.setFieldValue('adPlatform.dontMatch.campaign', campaignList);
		}
	};
	const handleOnBlurSplitFieldValues = () => {
		let querySplit = customQueryForm.values.querySplit;
		if (get(customQueryForm.values, 'querySplitChannelPercentageFieldValue', '') === '') {
			customQueryForm.setFieldValue('querySplitChannelPercentageFieldValue', 0);
		}
		if (querySplit !== undefined) {
			querySplit.forEach((value, index) => {
				if (get(value, 'percentage', '') === '') {
					customQueryForm.setFieldValue(`querySplit.${index}.percentage`, 0);
				}
			});
		}
	};

	const handleRemoveSplit = (split: ICustomizedQuerySplitForm) => {
		let querySplitList = (customQueryForm.values.querySplit ?? []) as ICustomizedQuerySplitForm[];
		const index = querySplitList.indexOf(split);
		if (index > -1) {
			querySplitList.splice(index, 1);
			customQueryForm.setFieldValue('querySplit', querySplitList);
		}
		mapSelectableChannelList();
		isFormValidateToSubmit();
	};

	const handleMapAllUnmapedGoogleAnalytics = async (value: boolean) => {
		let googleAnalyticsFormData = JSON.parse(JSON.stringify(getIn(customQueryForm.values, 'googleAnalytics')));
		if (value) {
			setTempGoogleAnalyticsFormData(googleAnalyticsFormData);
			await customQueryForm.setFieldValue('googleAnalytics.match.source', '');
			await customQueryForm.setFieldValue('googleAnalytics.match.medium', '');
			await customQueryForm.setFieldValue('googleAnalytics.match.campaign', []);
			await customQueryForm.setFieldValue('googleAnalytics.match.campaignField', '');
			//dont match
			await customQueryForm.setFieldValue('googleAnalytics.dontMatch.source', '');
			await customQueryForm.setFieldValue('googleAnalytics.dontMatch.medium', '');
			await customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaign', []);
			await customQueryForm.setFieldValue('googleAnalytics.dontMatch.campaignField', '');
		} else {
			if (!isNull(tempGoogleAnalyticsFormData)) {
				await customQueryForm.setFieldValue(
					'googleAnalytics.match.source',
					get(tempGoogleAnalyticsFormData, 'match.source', '')
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.match.medium',
					get(tempGoogleAnalyticsFormData, 'match.medium', '')
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.match.campaign',
					get(tempGoogleAnalyticsFormData, 'match.campaign', [])
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.match.campaignField',
					get(tempGoogleAnalyticsFormData, 'match.campaignField', '')
				);
				//dont match
				await customQueryForm.setFieldValue(
					'googleAnalytics.dontMatch.source',
					get(tempGoogleAnalyticsFormData, 'dontMatch.source', '')
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.dontMatch.medium',
					get(tempGoogleAnalyticsFormData, 'dontMatch.medium', '')
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.dontMatch.campaign',
					get(tempGoogleAnalyticsFormData, 'dontMatch.campaign', [])
				);
				await customQueryForm.setFieldValue(
					'googleAnalytics.dontMatch.campaignField',
					get(tempGoogleAnalyticsFormData, 'dontMatch.campaignField', '')
				);
			}
		}

		customQueryForm.setFieldValue('googleAnalytics.isMapAllUnmapped', value);
		customQueryForm.validateForm();
	};

	const handleMapAllUnmapedAdPlatform = async (value: boolean) => {
		let adPlatformFormData = JSON.parse(JSON.stringify(getIn(customQueryForm.values, 'adPlatform')));
		if (value) {
			setTempAdPlatformFormData(adPlatformFormData);
			await customQueryForm.setFieldValue('adPlatform.match.campaign', []);
			await customQueryForm.setFieldValue('adPlatform.match.campaignField', '');
			//dont match
			await customQueryForm.setFieldValue('adPlatform.dontMatch.campaign', []);
			await customQueryForm.setFieldValue('adPlatform.dontMatch.campaignField', '');
		} else {
			if (!isNull(tempAdPlatformFormData)) {
				await customQueryForm.setFieldValue(
					'adPlatform.match.campaign',
					get(tempAdPlatformFormData, 'match.campaign', [])
				);

				await customQueryForm.setFieldValue(
					'adPlatform.match.campaignField',
					get(tempAdPlatformFormData, 'match.campaignField', '')
				);
				await customQueryForm.setFieldTouched('adPlatform.match.campaignField', true);
				//dont match
				await customQueryForm.setFieldValue(
					'adPlatform.dontMatch.campaign',
					get(tempAdPlatformFormData, 'dontMatch.campaign', [])
				);
				await customQueryForm.setFieldValue(
					'adPlatform.dontMatch.campaignField',
					get(tempAdPlatformFormData, 'dontMatch.campaignField', '')
				);
				await customQueryForm.setFieldTouched('adPlatform.dontMatch.campaignField', true);
			}
		}
		customQueryForm.setFieldValue('adPlatform.isMapAllUnmapped', value);
		customQueryForm.validateForm();
	};

	const isFormValidateToSubmit = (isSubmitting?: boolean | null) => {
		var isGoogleAndAdPlatformValid = false;
		var isSplitValid = false;
		// Google and Ad Platform
		let googleAnalyticsSource = getIn(customQueryForm.values, 'googleAnalytics.match.source');
		let googleAnalyticsMedium = getIn(customQueryForm.values, 'googleAnalytics.match.medium');
		let googleAnalyticsCampaign = getIn(customQueryForm.values, 'googleAnalytics.match.campaign') as [];
		let googleAnalyticsCampaignField = getIn(customQueryForm.values, 'googleAnalytics.match.campaignField');
		let googleAnalyticsIsMapAllUnmapped = getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped');
		let adPlatformCampaign = getIn(customQueryForm.values, 'adPlatform.match.campaign') as [];
		let adPlatformCampaignField = getIn(customQueryForm.values, 'adPlatform.match.campaignField');
		let adPlatformIsMapAllUnmapped = getIn(customQueryForm.values, 'adPlatform.isMapAllUnmapped');

		//dont match
		let dontMatchGoogleAnalyticsSource = getIn(customQueryForm.values, 'googleAnalytics.dontMatch.source');
		let dontMatchGoogleAnalyticsMedium = getIn(customQueryForm.values, 'googleAnalytics.dontMatch.medium');
		let dontMatchGoogleAnalyticsCampaign = getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign') as [];
		let dontMatchGoogleAnalyticsCampaignField = getIn(
			customQueryForm.values,
			'googleAnalytics.dontMatch.campaignField'
		);
		let dontMatchAdPlatformCampaign = getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign') as [];
		let dontMatchAdPlatformCampaignField = getIn(customQueryForm.values, 'adPlatform.dontMatch.campaignField');

		// Split
		let querySplit = customQueryForm.values.querySplit;
		let querySplitChannelFieldValue = customQueryForm.values.querySplitChannelFieldValue ?? '';
		let querySplitChannelPercentageFieldValue = customQueryForm.values.querySplitChannelPercentageFieldValue;

		if (
			(googleAnalyticsSource === null || googleAnalyticsSource === '' || googleAnalyticsSource === undefined) &&
			(googleAnalyticsMedium === null || googleAnalyticsMedium === '' || googleAnalyticsMedium === undefined) &&
			(dontMatchGoogleAnalyticsSource === null ||
				dontMatchGoogleAnalyticsSource === '' ||
				dontMatchGoogleAnalyticsSource === undefined) &&
			(dontMatchGoogleAnalyticsMedium === null ||
				dontMatchGoogleAnalyticsMedium === '' ||
				dontMatchGoogleAnalyticsMedium === undefined) &&
			!googleAnalyticsIsMapAllUnmapped &&
			!adPlatformIsMapAllUnmapped
		) {
			if (
				googleAnalyticsCampaign.length === 0 &&
				(googleAnalyticsCampaignField === null || googleAnalyticsCampaignField === '') &&
				dontMatchGoogleAnalyticsCampaign.length === 0 &&
				(dontMatchGoogleAnalyticsCampaignField === null || dontMatchGoogleAnalyticsCampaignField === '') &&
				adPlatformCampaign.length === 0 &&
				(adPlatformCampaignField === null || adPlatformCampaignField === '') &&
				dontMatchAdPlatformCampaign.length === 0 &&
				(dontMatchAdPlatformCampaignField === null || dontMatchAdPlatformCampaignField === '')
			) {
				if (isCreateQueryButtonPressed || isSubmitting) {
					setGoogleAnalyticsCommonError(t('google_analytics_common_error'));
					setAdPlatformCommonError(t('ad_platform_common_error'));
				}
			} else {
				isGoogleAndAdPlatformValid = true;
			}
		} else {
			isGoogleAndAdPlatformValid = true;
		}

		if (isGoogleAndAdPlatformValid) {
			setGoogleAnalyticsCommonError(null);
			setAdPlatformCommonError(null);
		}

		// split validation
		if (isCreateQueryButtonPressed || isSubmitting === true) {
			if (
				querySplit?.length === 0 &&
				querySplitChannelFieldValue !== undefined &&
				querySplitChannelFieldValue.toString() !== ''
			) {
				if (querySplitChannelPercentageFieldValue?.toString() === '100') {
					isSplitValid = true;
					setSplitError(null);
				} else {
					setSplitError(t('split_needs_balanced_100'));
				}
			} else if ((querySplit?.length ?? 0) > 0) {
				var percentages: number[] = (querySplit ?? []).map((value: ICustomizedQuerySplitForm) =>
					Number(value.percentage)
				);
				if (querySplitChannelFieldValue !== undefined && querySplitChannelFieldValue.toString() !== '') {
					percentages.push(Number(querySplitChannelPercentageFieldValue ?? 0));
				}
				let sum = percentages.reduce((partialSum, i) => partialSum + i, 0);

				if (sum === 100) {
					if (
						percentages.includes(0) &&
						querySplitChannelFieldValue !== undefined &&
						querySplitChannelFieldValue.toString() !== ''
					) {
						setSplitError(t('split_cannot_0_percentage'));
					} else {
						isSplitValid = true;
						setSplitError(null);
					}
				} else {
					setSplitError(t('split_needs_balanced_100'));
				}
			} else {
				setSplitError(t('channel_selection_cannot_empty'));
			}
		}
		if (!isGoogleAndAdPlatformValid && isSubmitting) {
			(googleAnalyticsCommonErrorRef.current as any).scrollIntoView();
		} else if (!isSplitValid && isSubmitting) {
			(splitErrorRef.current as any).scrollIntoView();
		}
		return isGoogleAndAdPlatformValid && isSplitValid;
	};

	const handleOnchangeSplitFieldValue = (value: number) => {
		customQueryForm.setFieldValue('querySplitChannelFieldValue', value);
		if (
			customQueryForm.values.querySplit?.length === 0 &&
			!customQueryForm.touched.querySplitChannelPercentageFieldValue
		) {
			customQueryForm.setFieldValue('querySplitChannelPercentageFieldValue', 100);
		} else {
			// Split
			let querySplit = customQueryForm.values.querySplit;
			let querySplitChannelFieldValue = value;
			let querySplitChannelPercentageFieldValue = customQueryForm.values.querySplitChannelPercentageFieldValue;
			if (
				(querySplit?.length ?? 0) >= 0 &&
				querySplitChannelFieldValue !== undefined &&
				querySplitChannelFieldValue.toString() !== '' &&
				querySplitChannelPercentageFieldValue?.toString() === '0'
			) {
				var percentages: number[] = (querySplit ?? []).map((value: ICustomizedQuerySplitForm) =>
					Number(value.percentage)
				);
				let sum = percentages.reduce((partialSum, i) => partialSum + i, 0);
				if (sum < 100) {
					customQueryForm.setFieldValue('querySplitChannelPercentageFieldValue', 100 - sum);
				}
			}
		}
	};

	const handleAddNewSplit = () => {
		let splitList: ICustomizedQuerySplitForm[] = customQueryForm.values.querySplit ?? [];
		let querySplitChannelFieldValue: number = Number(customQueryForm.values.querySplitChannelFieldValue);
		let querySplitChannelPercentageFieldValue: number = Number(
			customQueryForm.values.querySplitChannelPercentageFieldValue
		);
		let isContainElement = splitList?.some(
			(e: ICustomizedQuerySplitForm) => e.channelId === querySplitChannelFieldValue
		);
		if (
			!isNaN(querySplitChannelPercentageFieldValue) &&
			!isNaN(querySplitChannelFieldValue) &&
			querySplitChannelFieldValue !== 0 &&
			!isContainElement
		) {
			splitList.push({ channelId: querySplitChannelFieldValue, percentage: querySplitChannelPercentageFieldValue });
			customQueryForm.setFieldValue('querySplit', splitList);
		}
		customQueryForm.setFieldValue('querySplitChannelPercentageFieldValue', 0);
		customQueryForm.setFieldValue('querySplitChannelFieldValue', '');
		mapSelectableChannelList();
	};
	const handleCreateDataAndCreateCustomQuery = () => {
		var castValues = validationSchema.cast(customQueryForm.values) as any;
		castValues.adPlatform.querySplit = [];
		castValues.adPlatform.querySplit.push(...(castValues.querySplit ?? []));
		castValues.googleAnalytics.querySplit = [];
		castValues.googleAnalytics.querySplit.push(...(castValues.querySplit ?? []));
		castValues.querySplitChannelFieldValue = undefined;

		let googleAnalyticsMatchSource = get(castValues, 'googleAnalytics.match.source', '');
		let googleAnalyticsMatchMedium = getIn(castValues, 'googleAnalytics.match.medium', '');
		let googleAnalyticsDontMatchSource = get(castValues, 'googleAnalytics.dontMatch.source', '');
		let googleAnalyticsontMatchMedium = getIn(castValues, 'googleAnalytics.dontMatch.medium', '');

		castValues.googleAnalytics.match.source = googleAnalyticsMatchSource === '' ? null : googleAnalyticsMatchSource;
		castValues.googleAnalytics.match.medium = googleAnalyticsMatchMedium === '' ? null : googleAnalyticsMatchMedium;
		castValues.googleAnalytics.dontMatch.source =
			googleAnalyticsDontMatchSource === '' ? null : googleAnalyticsDontMatchSource;
		castValues.googleAnalytics.dontMatch.medium =
			googleAnalyticsontMatchMedium === '' ? null : googleAnalyticsontMatchMedium;
		castValues.asOfDate = moment(customQueryForm.values.asOfDate).format(MOMENT_DATE_FORMAT);
		if (editableCustomQuery) {
			castValues.id = editableCustomQuery.id;
			updateCustomQuery(castValues);
		} else {
			createNewCustomQuery(castValues);
		}

		setOpenCreateCustomQueryConfirmation(false);
	};

	const handleClearLastRowSplitValues = () => {
		customQueryForm.setFieldValue('querySplitChannelPercentageFieldValue', 0);
		customQueryForm.setFieldValue('querySplitChannelFieldValue', '');
	};

	const removableDontMatchCampaignTextField = (label: string, handleRemoveClick: (label: string) => void) => {
		return (
			<Grid item xs={12} sm={6} key={label} md={6} sx={{ paddingRight: { sm: '16px !important;' } }}>
				<RemovableTextField
					showRemoveIcon={readOnlyCustomQuery}
					label={t('campaign')}
					value={label}
					name={'googleAnalytics.dontMatch.campaignField'}
					size={'small'}
					disabled={!readOnlyCustomQuery ? true : false}
					inputProps={{ readOnly: readOnlyCustomQuery }}
					handleRemoveClick={(value) => handleRemoveClick(value ?? '')}
				/>
			</Grid>
		);
	};
	const removableMatchCampaignTextField = (label: string, handleRemoveClick: (label: string) => void) => {
		return (
			<Grid item xs={12} sm={6} key={label} md={6} sx={{ paddingRight: { sm: '16px !important;' } }}>
				<RemovableTextField
					showRemoveIcon={readOnlyCustomQuery}
					label={t('campaign')}
					value={label}
					name={'googleAnalytics.match.campaignField'}
					size={'small'}
					disabled={!readOnlyCustomQuery ? true : false}
					inputProps={{ readOnly: readOnlyCustomQuery }}
					handleRemoveClick={(value) => handleRemoveClick(value ?? '')}
				/>
			</Grid>
		);
	};
	const removableSplitRow = (
		split: ICustomizedQuerySplitForm,
		index: number,
		handleRemoveClick: (split: ICustomizedQuerySplitForm) => void
	) => {
		return (
			<RemovableSplitRow
				key={`querySplit.${index}`}
				channels={[
					get(
						[...channelList].filter((e) => e.value === split.channelId),
						'0',
						...[]
					),
					...selectableChannelList
				]}
				channelsFieldName={`querySplit.${index}.channelId`}
				setSelectedChannelFieldValue={(field: string, value: number) => {
					customQueryForm.setFieldValue(field, value);
				}}
				channelsSelectedValue={get(
					[...channelList].filter((e) => e.value === split.channelId),
					'0',
					null
				)}
				isReadOnly={readOnlyCustomQuery}
				splitPercentageFieldName={`querySplit.${index}.percentage`}
				splitPercentageFieldValue={split.percentage}
				splitPercentageHandleChange={customQueryForm.handleChange}
				splitPercentageFieldError={
					split.percentage.toString() === '0' || split.percentage.toString() === '' || splitError !== null
				}
				splitPercentageFieldOnBlurCapture={handleOnBlurSplitFieldValues}
				handleRemoveClick={handleRemoveClick}
				isLoadingChannels={isLoadingChannels}
				split={split}
			/>
		);
	};
	const dialogContent = () => {
		return (
			<>
				<form
					onSubmit={customQueryForm.handleSubmit}
					onReset={customQueryForm.handleReset}
					onBlur={() => {
						// format inputs
						customQueryForm.values.queryName = customQueryForm.values.queryName.trim();
					}}
				>
					<fieldset disabled={readOnlyCustomQuery}>
						<Grid container sx={{ paddingTop: '10px' }} spacing={4}>
							<Grid item xs={12} sm={6}>
								<CPSingleSelectAutoCompleteDropDown
									name="accountId"
									size="small"
									options={[client]}
									label={t('clientName')}
									disableClearable
									required
									disabled={!readOnlyCustomQuery}
									readOnly={readOnlyCustomQuery}
									value={client}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CPTextField
									required
									label={t('query_name')}
									name="queryName"
									onBlur={customQueryForm.handleBlur}
									handleChange={customQueryForm.handleChange}
									error={customQueryForm.touched.queryName && customQueryForm.errors.queryName ? true : false}
									helperText={customQueryForm.touched.queryName ? customQueryForm.errors.queryName : ''}
									size={'small'}
									fullWidth
									value={customQueryForm.values.queryName}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CPDatePicker
									name="asOfDate"
									label={t('asOfDate')}
									handleChange={(date) => {
										customQueryForm.setFieldValue('asOfDate', date);
									}}
									size="small"
									fullWidth
									value={moment(customQueryForm.values.asOfDate, MOMENT_DATE_FORMAT).toDate()}
									onBlur={customQueryForm.handleBlur}
									error={customQueryForm.touched.asOfDate && customQueryForm.errors.asOfDate ? true : false}
									helperText={customQueryForm.touched.asOfDate ? customQueryForm.errors.asOfDate : ''}
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex' }}>
								<CPSwitch
									handleChange={(_, value) => {
										handleMapAllUnmapedGoogleAnalytics(value);
										handleMapAllUnmapedAdPlatform(value);
									}}
									checked={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
								/>
								<Typography variant="body2" sx={{ marginLeft: '8px', alignSelf: 'center' }}>
									{t('map_all_unmapped')}
								</Typography>
							</Grid>
						</Grid>
						<div style={{ marginTop: '32px' }}>
							<Typography variant={'subHeader1'}>{t('google_analytics')}</Typography>
							{!readOnlyCustomQuery ? (
								<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
									{t('create_new_query_google_analytics_info_line')}
								</Typography>
							) : null}
							<Grid container sx={{ paddingTop: '20px' }} spacing={3}>
								<Grid item xs={12}>
									<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
										{t('Match_Query_With')}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6} sx={{ paddingTop: '15px !important' }}>
									<CPTextField
										label={t('source')}
										name={'googleAnalytics.match.source'}
										onBlur={customQueryForm.handleBlur}
										handleChange={customQueryForm.handleChange}
										error={Boolean(
											getIn(customQueryForm.touched, 'googleAnalytics.match.source') &&
												getIn(customQueryForm.errors, 'googleAnalytics.match.source')
										)}
										helperText={getIn(customQueryForm.errors, 'googleAnalytics.match.source')}
										size={'small'}
										fullWidth
										value={getIn(customQueryForm.values, 'googleAnalytics.match.source') ?? ''}
										disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} sx={{ paddingTop: '15px !important' }}>
									<CPTextField
										label={t('medium')}
										name={'googleAnalytics.match.medium'}
										onBlur={customQueryForm.handleBlur}
										handleChange={customQueryForm.handleChange}
										error={Boolean(
											getIn(customQueryForm.touched, 'googleAnalytics.match.medium') &&
												getIn(customQueryForm.errors, 'googleAnalytics.match.medium')
										)}
										helperText={getIn(customQueryForm.errors, 'googleAnalytics.match.medium')}
										size={'small'}
										fullWidth
										value={getIn(customQueryForm.values, 'googleAnalytics.match.medium') ?? ''}
										disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
									/>
								</Grid>
								{/* Google Analytics */}
								<Grid item xs={12}>
									<Grid container spacing={3}>
										{/* loop google campaign list */}
										{(getIn(customQueryForm.values, 'googleAnalytics.match.campaign') as []).length > 0 ? (
											<Grid item xs={12}>
												<Stack spacing={3}>
													{(getIn(customQueryForm.values, 'googleAnalytics.match.campaign') as string[]).map((object) =>
														removableMatchCampaignTextField(object, (object) => {
															handleRemoveGoogleAnalyticsMatchCampaign(object);
														})
													)}
												</Stack>
											</Grid>
										) : null}
										{/* loop google campaign list */}
										{readOnlyCustomQuery &&
										(getIn(customQueryForm.values, 'googleAnalytics.match.campaign') as []).length > 0 ? null : (
											<>
												<Grid item xs={8} sm={6}>
													<CPTextField
														label={t('campaign')}
														name={'googleAnalytics.match.campaignField'}
														onBlur={customQueryForm.handleBlur}
														handleChange={customQueryForm.handleChange}
														error={Boolean(
															getIn(customQueryForm.touched, 'googleAnalytics.match.campaignField') &&
																getIn(customQueryForm.errors, 'googleAnalytics.match.campaignField')
														)}
														helperText={getIn(customQueryForm.errors, 'googleAnalytics.match.campaignField')}
														size={'small'}
														fullWidth
														value={getIn(customQueryForm.values, 'googleAnalytics.match.campaignField')}
														disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
													/>
												</Grid>
												<Grid item xs={4} sm={6}>
													{readOnlyCustomQuery ? null : (
														<div style={{ display: 'flex' }}>
															<CPButton
																label={<AddIcon />}
																onClick={handleAddGoogleAnalyticsMatchCampaign}
																variant="contained"
																style={{
																	minWidth: '40px',
																	width: '40px',
																	height: '40px',
																	marginLeft: -10,
																	flex: 'none'
																}}
																disabled={Boolean(
																	getIn(customQueryForm.errors, 'googleAnalytics.match.campaignField') ||
																		(getIn(customQueryForm.values, 'googleAnalytics.match.campaignField').length ??
																			0) === 0
																)}
															/>
															<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
																{t('add_more')}
															</Typography>
														</div>
													)}
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
								{/* google analytics dont match start */}
								<Grid item xs={12}>
									<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
										{t('Dont_Match_Query_With')}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6} sx={{ paddingTop: '15px !important' }}>
									<CPTextField
										label={t('source')}
										name={'googleAnalytics.dontMatch.source'}
										onBlur={customQueryForm.handleBlur}
										handleChange={customQueryForm.handleChange}
										error={Boolean(
											getIn(customQueryForm.touched, 'googleAnalytics.dontMatch.source') &&
												getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.source')
										)}
										helperText={getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.source')}
										size={'small'}
										fullWidth
										value={getIn(customQueryForm.values, 'googleAnalytics.dontMatch.source') ?? ''}
										disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} sx={{ paddingTop: '15px !important' }}>
									<CPTextField
										label={t('medium')}
										name={'googleAnalytics.dontMatch.medium'}
										onBlur={customQueryForm.handleBlur}
										handleChange={customQueryForm.handleChange}
										error={Boolean(
											getIn(customQueryForm.touched, 'googleAnalytics.dontMatch.medium') &&
												getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.medium')
										)}
										helperText={getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.medium')}
										size={'small'}
										fullWidth
										value={getIn(customQueryForm.values, 'googleAnalytics.dontMatch.medium') ?? ''}
										disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
									/>
								</Grid>
								{/* Google Analytics */}
								<Grid item xs={12}>
									<Grid container spacing={3}>
										{/* loop google campaign list */}
										{(getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign') as []).length > 0 ? (
											<Grid item xs={12}>
												<Stack spacing={3}>
													{(getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign') as string[]).map(
														(object) =>
															removableDontMatchCampaignTextField(object, (object) => {
																handleRemoveGoogleAnalyticsDontMatchCampaign(object);
															})
													)}
												</Stack>
											</Grid>
										) : null}
										{/* loop google campaign list */}
										{readOnlyCustomQuery &&
										(getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaign') as []).length > 0 ? null : (
											<>
												<Grid item xs={8} sm={6}>
													<CPTextField
														label={t('campaign')}
														name={'googleAnalytics.dontMatch.campaignField'}
														onBlur={customQueryForm.handleBlur}
														handleChange={customQueryForm.handleChange}
														error={Boolean(
															getIn(customQueryForm.touched, 'googleAnalytics.dontMatch.campaignField') &&
																getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.campaignField')
														)}
														helperText={getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.campaignField')}
														size={'small'}
														fullWidth
														value={getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaignField')}
														disabled={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
													/>
												</Grid>

												<Grid item xs={4} sm={6}>
													{readOnlyCustomQuery ? null : (
														<div style={{ display: 'flex' }}>
															<CPButton
																label={<AddIcon />}
																onClick={handleAddGoogleAnalyticsDontMatchCampaign}
																variant="contained"
																style={{
																	minWidth: '40px',
																	width: '40px',
																	height: '40px',
																	marginLeft: -10,
																	flex: 'none'
																}}
																disabled={Boolean(
																	getIn(customQueryForm.errors, 'googleAnalytics.dontMatch.campaignField') ||
																		(getIn(customQueryForm.values, 'googleAnalytics.dontMatch.campaignField').length ??
																			0) === 0
																)}
															/>
															<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
																{t('add_more')}
															</Typography>
														</div>
													)}
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
								{/* google analytics dont match end */}
								<Grid item xs={12} sx={{ display: 'none' }}>
									<CPSwitch
										handleChange={(_, value) => handleMapAllUnmapedGoogleAnalytics(value)}
										checked={getIn(customQueryForm.values, 'googleAnalytics.isMapAllUnmapped')}
									/>
									<Typography variant="body2" sx={{ marginLeft: '8px', alignSelf: 'center' }}>
										{t('map_all_unmapped')}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								sx={{ paddingTop: '0px !important', paddingBottom: '0px !important' }}
								ref={googleAnalyticsCommonErrorRef}
							>
								{googleAnalyticsCommonError ? (
									<Grid item xs={12} sx={{ paddingTop: '24px !important', paddingBottom: '0px !important' }}>
										<Typography variant="caption" color={theme.palette.error.main}>
											{googleAnalyticsCommonError}
										</Typography>
									</Grid>
								) : null}
							</Grid>
						</div>
						<div style={{ marginTop: '20px' }}>
							<Typography variant="subHeader1">{t('ad_platform')}</Typography>
							{readOnlyCustomQuery ? null : (
								<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
									{t('create_new_query_ad_platform_info')}
								</Typography>
							)}
							<Grid container sx={{ paddingTop: '20px' }} spacing={3}>
								<Grid item xs={12}>
									<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
										{t('Match_Query_With')}
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ paddingTop: '15px !important' }}>
									<Grid container spacing={3}>
										{/* loop google campaign list */}
										{(getIn(customQueryForm.values, 'adPlatform.match.campaign') as []).length > 0 ? (
											<Grid item xs={12}>
												<Stack spacing={3}>
													{(getIn(customQueryForm.values, 'adPlatform.match.campaign') as string[]).map((object) =>
														removableMatchCampaignTextField(object, (object) => {
															handleRemoveAdPlatformMatchCampaign(object);
														})
													)}
												</Stack>
											</Grid>
										) : null}
										{/* loop google campaign list */}
										{readOnlyCustomQuery &&
										(getIn(customQueryForm.values, 'adPlatform.match.campaign') as []).length > 0 ? null : (
											<>
												<Grid item xs={8} sm={6}>
													<CPTextField
														label={t('campaign')}
														name={'adPlatform.match.campaignField'}
														onBlur={customQueryForm.handleBlur}
														handleChange={customQueryForm.handleChange}
														error={Boolean(
															getIn(customQueryForm.touched, 'adPlatform.match.campaignField') &&
																getIn(customQueryForm.errors, 'adPlatform.match.campaignField')
														)}
														helperText={getIn(customQueryForm.errors, 'adPlatform.match.campaignField')}
														size={'small'}
														fullWidth
														value={getIn(customQueryForm.values, 'adPlatform.match.campaignField')}
														disabled={getIn(customQueryForm.values, 'adPlatform.isMapAllUnmapped')}
													/>
												</Grid>
												<Grid item xs={4} sm={6}>
													{readOnlyCustomQuery ? null : (
														<div style={{ display: 'flex' }}>
															<CPButton
																label={<AddIcon />}
																onClick={handleAddAdPlatformMatchCampaign}
																variant="contained"
																style={{
																	minWidth: '40px',
																	width: '40px',
																	height: '40px',
																	marginLeft: -10,
																	flex: 'none'
																}}
																disabled={Boolean(
																	getIn(customQueryForm.errors, 'adPlatform.match.campaignField') ||
																		(getIn(customQueryForm.values, 'adPlatform.match.campaignField').length ?? 0) === 0
																)}
															/>
															<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
																{t('add_more')}
															</Typography>
														</div>
													)}
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
								{/* add platform dont match start */}
								<Grid item xs={12}>
									<Typography variant="info1" component={'p'} sx={{ maxWidth: '550px' }}>
										{t('Dont_Match_Query_With')}
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ paddingTop: '15px !important' }}>
									<Grid container spacing={3}>
										{/* loop google campaign list */}
										{(getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign') as []).length > 0 ? (
											<Grid item xs={12}>
												<Stack spacing={3}>
													{(getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign') as string[]).map((object) =>
														removableDontMatchCampaignTextField(object, (object) => {
															handleRemoveAdPlatformDontMatchCampaign(object);
														})
													)}
												</Stack>
											</Grid>
										) : null}
										{/* loop google campaign list */}
										{readOnlyCustomQuery &&
										(getIn(customQueryForm.values, 'adPlatform.dontMatch.campaign') as []).length > 0 ? null : (
											<>
												<Grid item xs={8} sm={6}>
													<CPTextField
														label={t('campaign')}
														name={'adPlatform.dontMatch.campaignField'}
														onBlur={customQueryForm.handleBlur}
														handleChange={customQueryForm.handleChange}
														error={Boolean(
															getIn(customQueryForm.touched, 'adPlatform.dontMatch.campaignField') &&
																getIn(customQueryForm.errors, 'adPlatform.dontMatch.campaignField')
														)}
														helperText={getIn(customQueryForm.errors, 'adPlatform.dontMatch.campaignField')}
														size={'small'}
														fullWidth
														value={getIn(customQueryForm.values, 'adPlatform.dontMatch.campaignField')}
														disabled={getIn(customQueryForm.values, 'adPlatform.isMapAllUnmapped')}
													/>
												</Grid>
												<Grid item xs={4} sm={6}>
													{readOnlyCustomQuery ? null : (
														<div style={{ display: 'flex' }}>
															<CPButton
																label={<AddIcon />}
																onClick={handleAddAdPlatformDontMatchCampaign}
																variant="contained"
																style={{
																	minWidth: '40px',
																	width: '40px',
																	height: '40px',
																	marginLeft: -10,
																	flex: 'none'
																}}
																disabled={Boolean(
																	getIn(customQueryForm.errors, 'adPlatform.dontMatch.campaignField') ||
																		(getIn(customQueryForm.values, 'adPlatform.dontMatch.campaignField').length ??
																			0) === 0
																)}
															/>
															<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
																{t('add_more')}
															</Typography>
														</div>
													)}
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
								{/* add platform dont match end */}
								<Grid item xs={12} sx={{ display: 'none' }}>
									<CPSwitch
										handleChange={(_, value) => handleMapAllUnmapedAdPlatform(value)}
										checked={getIn(customQueryForm.values, 'adPlatform.isMapAllUnmapped')}
									/>
									<Typography variant="body2" sx={{ marginLeft: '8px', alignSelf: 'center' }}>
										{t('map_all_unmapped')}
									</Typography>
								</Grid>
								{adPlatformCommonError ? (
									<Grid item xs={12} sx={{ paddingTop: '24px !important', paddingBottom: '0px !important' }}>
										<Typography variant="caption" color={theme.palette.error.main}>
											{adPlatformCommonError}
										</Typography>
									</Grid>
								) : null}
							</Grid>
						</div>
						{/* Split */}
						<div style={{ marginTop: '16px' }}>
							<Typography variant="subHeader1">{t('split')}</Typography>
							<Grid container spacing={3} sx={{ paddingTop: '10px' }}>
								{/* loop split list */}
								{(customQueryForm.values.querySplit?.length ?? 0) > 0 ? (
									<Grid item xs={12}>
										<Stack spacing={3}>
											{(customQueryForm.values.querySplit as []).map((value, index) =>
												removableSplitRow(value, index, (split) => {
													handleRemoveSplit(split);
												})
											)}
										</Stack>
									</Grid>
								) : null}

								{/* loop split list */}
								<Grid item xs={12}>
									{readOnlyCustomQuery ? null : (
										<Grid container spacing={4}>
											<Grid item xs={6}>
												<CPSingleSelectAutoCompleteDropDown
													name="querySplitChannelFieldValue"
													size="small"
													options={
														customQueryForm.values.querySplitChannelFieldValue
															? [
																	get(
																		[...channelList].filter(
																			(e) => e.value === customQueryForm.values.querySplitChannelFieldValue
																		),
																		'0',
																		...[]
																	),
																	...selectableChannelList
															  ]
															: selectableChannelList
													}
													label={t('channel_name')}
													loading={isLoadingChannels}
													disableClearable
													setFieldValue={(_field, value, _) => {
														handleOnchangeSplitFieldValue(value);
													}}
													value={get(
														channelList.filter((e) => e.value === customQueryForm.values.querySplitChannelFieldValue),
														'0',
														null
													)}
													required
													error={
														(customQueryForm.values.querySplit?.length ?? 0) === 0 &&
														get(customQueryForm.values, 'querySplitChannelFieldValue', '') === '' &&
														splitError !== null
													}
												/>
											</Grid>
											<Grid item xs={3}>
												<CPTextField
													label={t('split_percentage')}
													name="querySplitChannelPercentageFieldValue"
													fullWidth
													size="small"
													disabled={
														get(customQueryForm.values, 'querySplitChannelFieldValue', '') !== '' ? false : true
													}
													value={customQueryForm.values.querySplitChannelPercentageFieldValue ?? ''}
													InputProps={{
														inputComponent: CPNumberPercentageFormat as any
													}}
													error={
														(((customQueryForm.values.querySplitChannelPercentageFieldValue ?? 0).toString() === '0' ||
															(customQueryForm.values.querySplitChannelPercentageFieldValue ?? '').toString() === '') &&
															get(customQueryForm.values, 'querySplitChannelFieldValue', '') !== '') ||
														(splitError !== null &&
															!(
																(customQueryForm.values.querySplitChannelPercentageFieldValue ?? 0).toString() ===
																	'0' ||
																(customQueryForm.values.querySplitChannelPercentageFieldValue ?? '').toString() === ''
															))
													}
													handleChange={customQueryForm.handleChange}
													onBlurCapture={handleOnBlurSplitFieldValues}
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<div style={{ display: 'flex' }}>
													{get(customQueryForm.values, 'querySplitChannelFieldValue', '') !== '' ? (
														<IconButton
															sx={{
																minWidth: '40px',
																width: '40px',
																height: '40px',
																marginLeft: '-20px',
																color: theme.palette.primary.delete,
																flex: 'none',
																marginRight: '12px'
															}}
															onClick={() => handleClearLastRowSplitValues()}
														>
															<RemoveCircleIcon />
														</IconButton>
													) : null}
													<CPButton
														label={<AddIcon />}
														onClick={handleAddNewSplit}
														variant="contained"
														style={{
															minWidth: '40px',
															width: '40px',
															height: '40px',
															marginLeft: '-10px',
															flex: 'none'
														}}
														disabled={
															get(customQueryForm.values, 'querySplitChannelFieldValue', '') !== '' &&
															(customQueryForm.values.querySplitChannelPercentageFieldValue ?? 0) > 0
																? false
																: true
														}
													/>
													<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
														{t('add_more')}
													</Typography>
												</div>
											</Grid>
										</Grid>
									)}
								</Grid>
								<Grid item sx={{ paddingTop: '0px !important', paddingBottom: '0px !important' }} ref={splitErrorRef}>
									{splitError ? (
										<Grid item xs={12} sx={{ paddingTop: '24px !important', paddingBottom: '0px !important' }}>
											<Typography variant="caption" color={theme.palette.error.main}>
												{splitError}
											</Typography>
										</Grid>
									) : null}
								</Grid>
							</Grid>
						</div>
					</fieldset>
				</form>
				{/* create confirmation dialog */}
				<CPConfirmationDialog
					open={openCreateCustomQueryConfirmation}
					title={editableCustomQuery ? t('edit_customized_query') : t('create_customized_query')}
					content={editableCustomQuery ? t('update_customized_query_confirm') : t('create_customized_query_confirm')}
					buttonOptions={
						<>
							<CPButton
								style={{ minWidth: '150px' }}
								label={t('go_back')}
								onClick={() => {
									setOpenCreateCustomQueryConfirmation(false);
								}}
							/>
							<CPButton
								style={{ minWidth: '150px' }}
								label={'Save'}
								variant="contained"
								onClick={() => {
									handleCreateDataAndCreateCustomQuery();
								}}
							/>
						</>
					}
				/>
			</>
		);
	};
	return (
		<ResponsiveDialog
			title={
				editableCustomQuery
					? readOnlyCustomQuery
						? t('view_customized_query')
						: t('edit_customized_query')
					: t('create_new_custom_query')
			}
			content={dialogContent()}
			open={open}
			handleClose={handleCloseModal}
			contentHeight={readOnlyCustomQuery ? 450 : 620}
			actions={
				<>
					{readOnlyCustomQuery ? (
						<CPButton
							label={t('close')}
							variant="contained"
							style={{ height: '40px', minWidth: '150px' }}
							onClick={handleCloseModal}
						/>
					) : (
						<>
							<CPLoadingButton
								label={editableCustomQuery ? t('update') : t('create_query')}
								variant="contained"
								style={{ height: '40px', minWidth: '150px' }}
								onClick={customQueryForm.submitForm}
								disabled={!(customQueryForm.isValid && customQueryForm.dirty && isHasSplitData)}
								loading={isCreatingCustomQuery}
							/>
						</>
					)}
				</>
			}
		/>
	);
};

export default AddEditCustomizedQuery;
