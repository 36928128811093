import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import CPButton from 'components/atoms/CPButton';
import Grid from '@mui/material/Grid';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CPAlert from 'components/atoms/CPAlert';
import { deleteEndpointPromise, getEndpointPromise } from 'services/apiServices';
import { getDownloadFileName, isAuthOperation } from 'utils/commonUtil';
import fileDownload from 'js-file-download';
import get from 'lodash-es/get';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import { debouncedTime, MOMENT_DATE_FORMAT } from 'common/constants/applicationConstants';
import { IQueryParams } from 'types/queryParams.type';
import DataTable from 'components/molecules/DataTable';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
// Icons
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ACCESS_TAGS from 'common/constants/accessTags';
import moment from 'moment';

const IncrementalSchedules = () => {
	// userState + variables
	const [keyword, setKeyword] = useState<string>('');
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const [isExportingCustomizedQueries, setIsExportingCustomizedQueries] = useState<boolean>(false);
	const [queryParams, setQueryParams] = useState<IQueryParams>({ keyword: '', pageNo: 0 });
	const [dataList, setDataList] = useState([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [deleteIncrementalScheduleConfirmation, setDeleteIncrementalScheduleConfirmation] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [isDeletingIncrementalSchedule, setIsDeletingCIncrementalSchedule] = useState<boolean>(false);
	// const [editableCustomQuery, setEditableCustomQuery] = useState<ICustomQuery | null>(null);
	// const [readOnlyCustomQuery, setReadOnlyCustomQuery] = useState<boolean>(false);
	// const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const timerRef = useRef<any>({ current: '' });
	const { t } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const columns: IColumn[] = [
		{
			id: 'reportName',
			label: t('Name'),
			minWidth: '20%',
			align: 'left'
		},
		{
			id: 'clientName',
			label: t('Client'),
			minWidth: '20%',
			align: 'left'
		},
		{
			id: 'channelName',
			label: t('Channel'),
			minWidth: '20%',
			align: 'left'
		},
		{
			id: 'modifiedDate',
			label: t('Modified Date'),
			minWidth: '20%',
			align: 'left'
		}
	];

	// useEffects
	useEffect(() => {
		getAllIncrementalSchedules();
	}, [queryParams, rowsPerPage]);

	// handles
	const getAllIncrementalSchedules = async () => {
		try {
			setTableLoading(true);
			const response = await getEndpointPromise(
				`/entitymanager/incremental-report/all?count=${rowsPerPage}&page=${
					queryParams.pageNo
				}&keyword=${encodeURIComponent(queryParams.keyword)}`
			);
			setRowCount(get(response, 'data.pagination.total'));
			let filteredDataList = get(response, 'data.dataList', []).map((query: any) => {
				let id = get(query, 'id');
				return {
					id: id,
					modifiedDate: query.lastModifiedDate,
					reportName: query.reportName,
					clientName: query.clientName,
					channelName: query.channelName,
					asOfDate: moment(query.asOfDate).format(MOMENT_DATE_FORMAT)
				};
			});

			setDataList(filteredDataList);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setTableLoading(false);
		}
	};
	const handleSearch = (value: string) => {
		setKeyword(value);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(
			(searchTextValue) => {
				setQueryParams({ ...queryParams, keyword: searchTextValue, pageNo: 0 });
			},
			debouncedTime,
			value
		);
	};
	const handlePageNo = (pageNo: number) => {
		setQueryParams({ ...queryParams, pageNo: pageNo });
	};
	const handleEdit = (data: any) => {
		let id = get(data, 'id', null);
		navigate('/configurations/incremental-schedule/update-incremental-schedules/' + id);
	};

	const handleDataExport = async () => {
		setIsExportingCustomizedQueries(true);
		try {
			const response = await getEndpointPromise(`/entitymanager/custom-query/download?keyword=${keyword}`);
			let disposition = response.headers['content-disposition'];
			let filename = getDownloadFileName(disposition);
			fileDownload(get(response, 'data'), filename || ' Users.csv');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsExportingCustomizedQueries(false);
		}
	};
	const handleDelete = async (data: any) => {
		setIsDeletingCIncrementalSchedule(true);
		try {
			await deleteEndpointPromise(`/entitymanager/incremental-report/delete?id=${get(data, 'id')}`);
			getAllIncrementalSchedules();
			const key = enqueueSnackbar(
				<CPAlert
					title={t('delete_incremental_schedule_title')}
					message={t('delete_successfully_incremental_schedule_message')}
					severity={'info'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			setDeleteIncrementalScheduleConfirmation(null);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsDeletingCIncrementalSchedule(false);
		}
	};

	return (
		<div className={styles.container}>
			<Breadcrumb
				title={t('incremental_schedule')}
				actions={
					!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.CREATE_CUSTOM_QUERY) ? null : (
						<CPButton
							label={<>{t('create_incremental_schedule')}</>}
							startIcon={<AddIcon />}
							onClick={() => {
								navigate('create-incremental-schedules');
							}}
							variant="contained"
						/>
					)
				}
			/>
			<main className={styles.main}>
				<div style={{ width: '100%' }}>
					<Grid container sx={{ padding: '24px 0px' }}>
						<Grid item xs={4}>
							<CPLoadingButton
								label={'Export'}
								startIcon={<FileUploadIcon />}
								onClick={() => handleDataExport()}
								variant="contained"
								loading={isExportingCustomizedQueries}
							/>
						</Grid>
						<Grid item xs={3}></Grid>
						<Grid item xs={5} alignItems="right">
							<OutlinedInput
								id="standard-basic"
								sx={{ width: '100%', height: '48px' }}
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								}
								placeholder="Search for queries..."
								value={keyword}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
							/>
						</Grid>
					</Grid>
					<div style={{ marginTop: '8px', marginBottom: '8px' }}>
						<DataTable
							rows={dataList}
							rowCount={rowCount}
							rowsPerPage={rowsPerPage}
							page={queryParams.pageNo}
							columns={columns}
							setRowsPerPage={setRowsPerPage}
							setPage={handlePageNo}
							loading={tableLoading}
							onEditAction={
								isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.UPDATE_CUSTOM_QUERY)
									? (data) => handleEdit(data)
									: undefined
							}
							onDeleteAction={
								isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.DELETE_CUSTOM_QUERY)
									? (data) => setDeleteIncrementalScheduleConfirmation(data)
									: undefined
							}
							// onViewAction={
							// 	!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.UPDATE_CUSTOM_QUERY)
							// 		? (data) => handleReadOnly(data)
							// 		: undefined
							// }
						/>
					</div>
				</div>
				{/* delete confirmation dialog */}
				<CPConfirmationDialog
					open={deleteIncrementalScheduleConfirmation !== null}
					title={t('delete_incremental_schedule_title')}
					content={<>{t('delete_incremental_schedule_confirm')}</>}
					buttonOptions={
						<>
							<CPButton
								style={{ minWidth: '150px' }}
								label={t('cancel')}
								onClick={() => {
									setDeleteIncrementalScheduleConfirmation(null);
								}}
								disabled={isDeletingIncrementalSchedule}
							/>
							<CPLoadingButton
								loading={isDeletingIncrementalSchedule}
								style={{ minWidth: '150px' }}
								label={t('delete')}
								variant="contained"
								color="error"
								onClick={() => {
									handleDelete(deleteIncrementalScheduleConfirmation);
								}}
							/>
						</>
					}
				/>
			</main>
			{/* Edit custom query */}
		</div>
	);
};
export default IncrementalSchedules;
