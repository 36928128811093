import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISettings {
	isNavBarOpen: boolean;
}

const initialState: ISettings = { isNavBarOpen: true };

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialState,
	reducers: {
		setNavBarOpen: (state, action: PayloadAction<boolean>) => {
			state.isNavBarOpen = action.payload;
		}
	}
});
export const { setNavBarOpen } = settingsSlice.actions;

export default settingsSlice.reducer;
