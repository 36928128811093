import { Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ChangeEvent, KeyboardEventHandler, useState } from 'react';

interface ICPTextField {
	label?: string;
	name: string;
	error?: boolean | undefined;
	helperText?: string | any;
	handleChange?: (e: ChangeEvent) => void;
	onBlur?: (e: ChangeEvent) => void;
	size?: 'small' | 'medium';
	fullWidth?: boolean;
	value?: any;
	InputProps?: any;
	required?: boolean;
	disabled?: boolean;
	type?: 'text' | 'number'|'password';
	defaultValue?: number | string;
	readOnly?: boolean;
	inputProps?: any;
	showTooltip?: boolean | undefined;
	showTooltipMinimumLength?: number;
	onBlurCapture?: () => void;
	onKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined;
	onkeyup?: any;
	tabIndex?: number;
}

export default function CPTextField(props: ICPTextField) {
	const {
		label,
		handleChange,
		error,
		name,
		helperText,
		onBlur,
		size,
		fullWidth,
		value,
		InputProps,
		required,
		disabled,
		type,
		defaultValue,
		inputProps,
		onBlurCapture,
		showTooltipMinimumLength = 15,
		showTooltip = true,
		onKeyDown,
		tabIndex
	} = props;
	const [isTooltipHideOnEdit, setIsTooltipHideOnEdit] = useState<boolean>(false);
	const showToolTipValue = () => {
		var toolTipValue = '';
		if (showTooltip && value !== undefined && value !== null) {
			if (!isTooltipHideOnEdit) {
				const tooltip = value.toString();
				if (tooltip.length > showTooltipMinimumLength) {
					toolTipValue = tooltip;
				}
			}
		}
		return toolTipValue;
	};
	return (
		<Tooltip title={showToolTipValue()} placement="bottom-start">
			<TextField
				type={type}
				required={required}
				InputProps={InputProps}
				id="outlined-basic"
				label={label}
				name={name}
				variant="outlined"
				error={error}
				onBlur={onBlur}
				onChange={handleChange}
				helperText={helperText}
				size={size}
				fullWidth={fullWidth}
				value={value}
				disabled={disabled}
				defaultValue={defaultValue}
				inputProps={inputProps}
				tabIndex={tabIndex}
				onFocusCapture={() => setIsTooltipHideOnEdit(true)}
				onBlurCapture={() => {
					onBlurCapture?.();
					setIsTooltipHideOnEdit(false);
				}}
				onKeyDown={onKeyDown}
				InputLabelProps={{ shrink: value !== null && value !== '' && value !== undefined ? true : false }}
			/>
		</Tooltip>
	);
}
