import { createTheme, PaletteOptions, SimplePaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Theme {
		primary: {
			main: string;
		};
		secondary: {
			main: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		primary?: {
			main?: string;
		};
		secondary?: {
			main?: string;
		};
	}
	interface SimplePaletteColorOptions {
		delete: string;
		cpSwitch?: {
			primaryBackground: string;
			disableBackground: string;
		};
		attributeReport?: {
			default: string;
			updated: string;
			previousUpdated: string;
		};
		labelReport: {
			headerColor: string;
			summaryColor: string;
			fontColor: string;
		};
		linkOff: string;
	}

	interface PaletteColor {
		delete: string;
		cpSwitch?: {
			primaryBackground: string;
			disableBackground: string;
		};
		attributeReport?: {
			default: string;
			updated: string;
			previousUpdated: string;
		};
		labelReport: {
			headerColor: string;
			summaryColor: string;
			fontColor: string;
		};
		linkOff: string;
	}

	interface TypographyVariants {
		subHeader1: React.CSSProperties;
		info1: React.CSSProperties;
		bodyDisable: React.CSSProperties;
		header1: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		subHeader1?: React.CSSProperties;
		info1?: React.CSSProperties;
		bodyDisable?: React.CSSProperties;
		header1?: React.CSSProperties;
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		subHeader1: true;
		info1: true;
		bodyDisable: true;
		header1: true;
	}
}

interface DefaultPaletteOptions extends PaletteOptions {
	primary?: SimplePaletteColorOptions;
}
const DefaultPalette = (): DefaultPaletteOptions => {
	return {
		mode: 'light',
		primary: {
			main: '#352B61',
			delete: '#FF4242',
			cpSwitch: {
				primaryBackground: '#FFFFFF',
				disableBackground: '#E9E9EB'
			},
			linkOff: '#FF7777',
			attributeReport: {
				default: '#FFF3DB',
				updated: '#E0E1FF',
				previousUpdated: '#E0FFE5'
			},
			labelReport: {
				headerColor: 'linear-gradient(0deg, #194D91, #194D91), #000000',
				summaryColor: '#c26c15',
				fontColor: '#FFF'
			}
		}
	};
};

const defaultColors = DefaultPalette();

const palette: PaletteOptions = {
	...defaultColors,
	background: {
		default: '#ededed'
	}
};

export const theme = createTheme({
	primary: {
		main: '#00000'
	},
	secondary: {
		main: '#352B61'
	},
	palette,
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'contained', color: 'primary' },
					style: {
						backgroundColor: '#352B61',
						fontWeight: 700,
						transform: 'none',
						fontSize: '0.875em',
						'&:hover': {
							backgroundColor: '#352B61'
						},
						boxShadow: 'none'
					}
				},
				{
					props: { variant: 'contained', color: 'error' },
					style: {
						backgroundColor: '#DB0707',
						fontWeight: 700,
						transform: 'none',
						fontSize: '0.875em',
						'&:hover': {
							backgroundColor: '#DB0707'
						},
						boxShadow: 'none'
					}
				}
			]
		},
		MuiAutocomplete: {
			variants: [
				{
					props: { size: 'small' },
					style: {
						input: {
							fontWeight: 400,
							fontSize: '14px'
						},
						label: {
							fontWeight: 400,
							fontSize: '14px'
						}
					}
				}
			]
		},
		MuiFormControl: {
			variants: [
				{
					props: { size: 'small' },
					style: {
						input: {
							fontWeight: 400,
							fontSize: '14px',
							height: '23px'
						},
						label: {
							fontWeight: 400,
							fontSize: '14px',
							paddingTop: '1px'
						}
					}
				}
			]
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#FFFFFF',
					color: '#49454F;',
					borderRight: '0px solid rgba(0, 0, 0, 0.12)',
					boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.03)'
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				container: {
					backgroundColor: 'rgba(53, 43, 97, 0.22)'
				}
			}
		},
		MuiAlert: {
			variants: [
				{
					props: { severity: 'success' },
					style: {
						backgroundColor: '#E7FAF5',
						border: '2px solid #B5D0C9',
						borderRadius: '4px'
					}
				},
				{
					props: { severity: 'info' },
					style: {
						backgroundColor: '#EBEEFF',
						border: '2px solid #BCB0F5',
						borderRadius: '4px'
					}
				},
				{
					props: { severity: 'error' },
					style: {
						border: '2px solid #FCB6B5',
						borderRadius: '4px'
					}
				},
				{
					props: { severity: 'warning' },
					style: {
						border: `2px solid #FDBC99`,
						borderRadius: '4px'
					}
				}
			]
		}
	},
	typography: {
		fontFamily: 'Poppins',
		button: {
			textTransform: 'none'
		},
		subHeader1: {
			color: '#000000',
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '34px'
		},
		info1: {
			fontWeight: 400,
			fontSize: '11px',
			lineHeight: '16px',
			color: '#7D7D7D'
		},
		bodyDisable: {
			fontWeight: 400,
			fontSize: '14px',
			color: '#7D7D7D'
		},
		header1: {
			fontWeight: 600,
			fontSize: '20px',
			color: '#000000'
		}
	}
});
