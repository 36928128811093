import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ModalProps } from '@mui/material';

interface ICPButton {
	open: boolean;
	onClose?: ModalProps['onClose'];
	title: React.ReactNode;
	content: React.ReactNode;
	buttonOptions: React.ReactNode;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xs';
}

export default function CPConfirmationDialog(props: ICPButton) {
	const { open, onClose, title, buttonOptions, content, maxWidth = 'md' } = props;
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			PaperProps={{
				sx: { borderRadius: '15px', minWidth: { md: '600px' } }
			}}
		>
			<DialogTitle id="alert-dialog-title" sx={{ fontSize: 24, fontWeight: 500, textTransform: 'capitalize' }}>
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					sx={{ minHeight: '60px', color: '#000000', fontSize: '14px', lineHeight: '21px' }}
				>
					{content}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ padding: '32px' }}>{buttonOptions}</DialogActions>
		</Dialog>
	);
}
