import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid } from '@mui/material';
import CPDatePicker from 'components/atoms/CPDatePicker';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getEndpointPromise, insertEndpointPromise, updateEndpointPromise } from 'services/apiServices';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { useSnackbar } from 'notistack';
import { ILabels } from 'types/label.type';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import { isAuthOperation } from 'utils/commonUtil';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import get from 'lodash/get';
import ACCESS_TAGS from 'common/constants/accessTags';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { IChannel, IChannelsLov } from 'types/channel.type';
import IncrementalTestResultsTableOne from './IncrementalTestResultsTableOne';
import IncrementalTestResultsTableTwo from './IncrementalTestResultsTableTwo';
import Loader from 'components/atoms/Loader';
import IncrementalTestResultsTableThree from './IncrementalTestResultsTableThree';
import CPButton from 'components/atoms/CPButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MOMENT_DATE_FORMAT } from 'common/constants/applicationConstants';
import { getReportParameters, setReportParameters } from 'utils/localStorageUtil';
import CPTextField from 'components/atoms/CPTextField';
import { useNavigate, useParams } from 'react-router-dom';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
import { usePrompt } from 'hooks/Prompt';

interface IStatisticsResults {
	geoStatistics: any;
	levelStatistics: any;
}

const DataAnalysis = () => {
	const { t } = useTranslation();
	const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [loadingResults, setLoadingResults] = useState<boolean>(false);
	const [clients, setClients] = useState<IOptionItem[] | []>([]);
	const contentLeftRef = useRef<HTMLDivElement>(null);
	const contentRightRef = useRef<HTMLDivElement>(null);
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const [incrementalReport, setIncrementalReport] = useState<any>();
	const componentRef = useRef<any>({ current: '' });
	const [isLoadingChannels, setIsLoadingChannels] = useState<boolean>(false);
	const [channelList, setChannelList] = useState<IOptionItem[] | []>([]);
	const [userInputParameters, setUserInputParameters] = useState({
		aov: 0,
		baseSpendControlGeo: 0,
		totalSalesControlGeo: 0,
		totalSalesTestGeo: 0,
		totalSpendTestGeo: 0,
		controlGeoCPC: 0,
		conversionRate: 0,
		incrementalTestGeoCPC: 0,
		sitewideCr: 0
	});
	const [tableUpdated, setTableUpdated] = useState<boolean>(false);
	const [tableThreeValueList, setTableThreeValueList] = useState<any>([]);
	const [tableThreeUpdated, setTableThreeUpdated] = useState<boolean>(false);
	const [loadingTableThree, setLoadingTableThree] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [openIncrementalScheduleConfirmation, setOpenIncrementalScheduleConfirmation] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	usePrompt(tableUpdated || tableThreeUpdated);

	useEffect(() => {
		if (id) {
			getIncrementalScheduleById(id);
		}
	}, [id]);

	const getIncrementalScheduleById = async (id: any) => {
		try {
			setLoadingResults(true);
			setIsSaving(true);
			setLoadingTableThree(true);
			const response = await getEndpointPromise<IChannelsLov>(`/entitymanager/incremental-report/get?id=${id}`);
			const reportResponse: any = get(response, 'data');
			reportResponse.geoData = reportResponse.geoStatistics;
			setIncrementalReport(reportResponse);
			setUserInputParameters({
				aov: reportResponse.aov,
				baseSpendControlGeo: reportResponse?.baseSpendControlGeo ?? 0,
				totalSalesControlGeo: reportResponse?.totalSalesControlGeo ?? 0,
				totalSalesTestGeo: reportResponse?.totalSalesTestGeo ?? 0,
				totalSpendTestGeo: reportResponse?.totalSpendTestGeo ?? 0,
				controlGeoCPC: reportResponse?.geoStatistics?.controlGeo?.cpc ?? 0,
				conversionRate: reportResponse?.geoStatistics?.controlGeo?.conversionRate ?? 0,
				incrementalTestGeoCPC: reportResponse?.geoStatistics?.incrementalTestGeo?.cpc ?? 0,
				sitewideCr: reportResponse?.sitewideCr ?? 0
			});
			const levelStatistics: any = get(reportResponse, 'levelStatistics');
			setTableThreeValueList(levelStatistics);

			searchForm.setFieldTouched('reportName', true);
			searchForm.setFieldTouched('clientId', true);
			searchForm.setFieldTouched('startDate', true);
			searchForm.setFieldTouched('endDate', true);
			searchForm.setFieldTouched('channelId', true);

			searchForm.setFieldValue('reportName', get(reportResponse, 'reportName'));
			searchForm.setFieldValue('clientId', parseInt(get(reportResponse, 'clientId')));
			searchForm.setFieldValue('startDate', new Date(get(reportResponse, 'startDate')));
			searchForm.setFieldValue('endDate', new Date(get(reportResponse, 'endDate')));
			searchForm.setFieldValue('channelId', parseInt(get(reportResponse, 'channelId')));
		} catch (error) {
			let message = getReadableError(error);

			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsSaving(false);
			setLoadingResults(false);
			setLoadingTableThree(false);
			searchForm.validateForm();
		}
	};

	useEffect(() => {
		componentRef.current = document.getElementById('incrementalReportContainer');
	});

	const validationSchema = yup
		.object({
			clientId: yup.string().required(t('value_required')).trim().nullable(),
			channelId: yup.string().required(t('value_required')).trim().nullable()
		})
		.shape({
			startDate: yup.date().typeError('Date needs to be mm/dd/yyyy format').required(t('value_required')),
			endDate: yup
				.date()
				.when('startDate', (startDate, schema) => startDate && schema.min(startDate, t('end_date_cannot_be')))
				.typeError('Date needs to be mm/dd/yyyy format')
				.required(t('value_required')),
			reportName: yup.string().required(t('value_required')).max(255, t('cannot_exceed_255'))
		});
	const searchForm = useFormik({
		initialValues: {
			startDate: moment().subtract(7, 'd').toDate(),
			endDate: moment().subtract(1, 'd').toDate(),
			clientId: null,
			channelId: null,
			reportName: ''
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			setOpenIncrementalScheduleConfirmation(true);
		}
	});

	useEffect(() => {
		getClients();
		getAllChannels();
	}, []);

	useEffect(() => {
		if (searchForm.values.clientId && searchForm.values.startDate && searchForm.values.endDate) {
			const reportParams = {
				clientId: searchForm.values.clientId,
				startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
				endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT)
			};
			setReportParameters(JSON.stringify(reportParams));
		}
	}, [searchForm.values.clientId, searchForm.values.startDate, searchForm.values.endDate]);

	useEffect(() => {
		let reportParams = getReportParameters();
		if (reportParams) {
			let reportParamsObj = JSON.parse(reportParams);
			searchForm.setFieldValue('clientId', parseInt(reportParamsObj.clientId));
			searchForm.setFieldValue('startDate', new Date(reportParamsObj.startDate));
			searchForm.setFieldValue('endDate', new Date(reportParamsObj.endDate));
			searchForm.validateForm();
		}
	}, [isLoadingClientList, isLoadingChannels]);

	const getAllChannels = async () => {
		try {
			setIsLoadingChannels(true);
			const response = await getEndpointPromise<IChannelsLov>(`/entitymanager/channel/list`);
			let channelsList: IChannel[] = get(response, 'data')?.channels ?? [];
			var mapChannels: IOptionItem[] = [];
			channelsList.forEach((channel) => {
				let values = mapChannels.map((value) => value.value);
				if (!values.includes(channel.id)) {
					mapChannels.push({ value: channel.id, label: channel.channelName });
				}
			});
			setChannelList(mapChannels);
		} catch (error) {
			let message = getReadableError(error);

			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingChannels(false);
		}
	};

	const handleSearchWrapper = async () => {
		setTableThreeValueList([]);
		handleSearch(
			{
				aov: 0,
				baseSpendControlGeo: 0,
				totalSalesControlGeo: 0,
				totalSalesTestGeo: 0,
				totalSpendTestGeo: 0,
				controlGeoCPC: 0,
				conversionRate: 0,
				incrementalTestGeoCPC: 0,
				sitewideCr: 0
			},
			false
		);
	};

	const handleSearch = async (
		userInputParams: any = userInputParameters,
		isGenerateTableThree: boolean = true
	): Promise<IStatisticsResults> => {
		try {
			setLoadingResults(true);

			const response = await insertEndpointPromise<any>(`/entitymanager/incremental-report/geo-statistics`, {
				aov: userInputParams.aov ? userInputParams.aov : 0,
				baseSpendControlGeo: userInputParams.baseSpendControlGeo ? userInputParams.baseSpendControlGeo : 0,
				incrementalTestGeo: {
					conversionRate: 0,
					cpc: userInputParams.incrementalTestGeoCPC ? userInputParams.incrementalTestGeoCPC : 0
				},
				sitewide: {
					channelId: searchForm.values.channelId,
					clientId: searchForm.values.clientId,
					startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
					endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT)
				},
				testControlGeo: {
					conversionRate: userInputParams.conversionRate ? userInputParams.conversionRate : 0,
					cpc: userInputParams.controlGeoCPC ? userInputParams.controlGeoCPC : 0
				},
				totalSalesControlGeo: userInputParams.totalSalesControlGeo ? userInputParams.totalSalesControlGeo : 0,
				totalSalesTestGeo: userInputParams.totalSalesTestGeo ? userInputParams.totalSalesTestGeo : 0,
				totalSpendTestGeo: userInputParams.totalSpendTestGeo ? userInputParams.totalSpendTestGeo : 0
			});
			const reportResponse: any = get(response, 'data');
			setIncrementalReport(reportResponse);
			setUserInputParameters({
				aov: reportResponse.aov,
				baseSpendControlGeo: reportResponse?.baseSpendControlGeo ?? 0,
				totalSalesControlGeo: reportResponse?.totalSalesControlGeo ?? 0,
				totalSalesTestGeo: reportResponse?.totalSalesTestGeo ?? 0,
				totalSpendTestGeo: reportResponse?.totalSpendTestGeo ?? 0,
				controlGeoCPC: reportResponse?.geoData.controlGeo.cpc ?? 0,
				conversionRate: reportResponse?.geoData.controlGeo.conversionRate ?? 0,
				incrementalTestGeoCPC: reportResponse?.geoData.incrementalTestGeo.cpc ?? 0,
				sitewideCr: userInputParameters?.sitewideCr ?? 0
			});

			setLoadingResults(false);

			if (tableThreeValueList.length > 0 && isGenerateTableThree) {
				let levelStatisticsResult = await generateTableThree();
				return { geoStatistics: reportResponse, levelStatistics: levelStatisticsResult };
			}
			return { geoStatistics: reportResponse, levelStatistics: null };
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setLoadingResults(false);
			setTableUpdated(false);
		}
		return { geoStatistics: null, levelStatistics: null };
	};

	const handleSaveWrapper = async () => {
		if (tableUpdated) {
			let result = await handleSearch();
			if (result.geoStatistics) {
				handleSave(result);
			}
		} else if (tableThreeUpdated) {
			let result = await generateTableThree();
			if (result) {
				handleSave({ geoStatistics: null, levelStatistics: result });
			}
		} else {
			if (incrementalReport || tableThreeValueList) {
				handleSave({ geoStatistics: incrementalReport, levelStatistics: tableThreeValueList });
			}
		}
	};

	const handleSave = async (generatedResult: IStatisticsResults) => {
		try {
			setIsSaving(true);
			const incremetalShcedule = {
				aov: userInputParameters?.aov ? userInputParameters?.aov : 0,
				baseSpendControlGeo: userInputParameters.baseSpendControlGeo ? userInputParameters.baseSpendControlGeo : 0,
				channelId: searchForm.values.channelId,
				clientId: searchForm.values.clientId,
				endDate: moment(searchForm.values.endDate).format(MOMENT_DATE_FORMAT),
				geoStatistics: {
					incrementalTestGeo: {
						channelROAS: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.channelRoas ?? 0,
						clicks: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.clicks ?? 0,
						conversionRate: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.conversionRate ?? 0,
						convs: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.conv ?? 0,
						cpc: userInputParameters?.incrementalTestGeoCPC ?? 0,
						spend: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.spend ?? 0,
						totalSales: generatedResult?.geoStatistics?.geoData?.incrementalTestGeo?.totalSales ?? 0
					},
					testControlGeo: {
						channelROAS: generatedResult?.geoStatistics?.geoData?.controlGeo?.channelRoas ?? 0,
						clicks: generatedResult?.geoStatistics?.geoData?.controlGeo?.clicks ?? 0,
						conversionRate: userInputParameters?.conversionRate ?? 0,
						convs: generatedResult?.geoStatistics?.geoData?.controlGeo?.conv ?? 0,
						cpc: userInputParameters?.controlGeoCPC ?? 0,
						spend: generatedResult?.geoStatistics?.geoData?.controlGeo?.spend ?? 0,
						totalSales: generatedResult?.geoStatistics?.geoData?.controlGeo?.totalSales ?? 0
					},
					totalTestGeo: {
						channelROAS: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.channelRoas ?? 0,
						clicks: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.clicks ?? 0,
						conversionRate: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.conversionRate ?? 0,
						convs: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.conv ?? 0,
						cpc: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.cpc ?? 0,
						spend: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.spend ?? 0,
						totalSales: generatedResult?.geoStatistics?.geoData?.totalTestGeo?.totalSales ?? 0
					}
				},
				incrementalRoasTestGeo: generatedResult?.geoStatistics?.incrementalRoasTestGeo ?? 0,
				incrementalSalesObservedTestGeo: generatedResult?.geoStatistics?.incrementalSalesObservedTestGeo ?? 0,
				incrementalSpendTestGeo: generatedResult?.geoStatistics?.incrementalSpendTestGeo ?? 0,

				levelStatistics: generatedResult?.levelStatistics?.map((value: any) => {
					return {
						channelROAS: value.channelRoas ? value.channelRoas : 0,
						channelSales: value.channelSales ? value.channelSales : 0,
						clicks: value.clicks ? value.clicks : 0,
						convs: value.conv ? value.conv : 0,
						conversionRate: value.conversionRate ? value.conversionRate : 0,
						cpc: value.cpc ? value.cpc : 0,
						rowIndex: value.rowId ? value.rowId : 0,
						spendLevelMax: value.spendLevelMax ? value.spendLevelMax : 0,
						spendLevelMin: value.spendLevelMin ? value.spendLevelMin : 0
					};
				}),
				reportName: searchForm.values.reportName,
				sitewideCr: userInputParameters.sitewideCr ? userInputParameters.sitewideCr : 0,
				startDate: moment(searchForm.values.startDate).format(MOMENT_DATE_FORMAT),
				totalSalesControlGeo: userInputParameters.totalSalesControlGeo ? userInputParameters.totalSalesControlGeo : 0,
				totalSalesTestGeo: userInputParameters.totalSalesTestGeo ? userInputParameters.totalSalesTestGeo : 0,
				totalSpendTestGeo: userInputParameters.totalSpendTestGeo ? userInputParameters.totalSpendTestGeo : 0
			};
			id ? updateIncrementalSchedule(incremetalShcedule) : createIncrementalSchedule(incremetalShcedule);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setTableUpdated(false);
		}
	};

	const createIncrementalSchedule = async (incremetalShcedule: any) => {
		try {
			if (!incremetalShcedule) return;
			await insertEndpointPromise<any>(`/entitymanager/incremental-report/create`, incremetalShcedule);

			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={t('incremental_schedule_successfully_created')}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			navigate('/configurations/incremental-schedule');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsSaving(false);
			setOpenIncrementalScheduleConfirmation(false);
		}
	};

	const updateIncrementalSchedule = async (incremetalShcedule: any) => {
		try {
			if (!incremetalShcedule || !id) return;
			incremetalShcedule.id = id;
			await updateEndpointPromise<any>(`/entitymanager/incremental-report/update`, incremetalShcedule);

			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={t('successfullySaved')}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			navigate('/configurations/incremental-schedule');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsSaving(false);
			setOpenIncrementalScheduleConfirmation(false);
		}
	};

	const setUserInputParametersWrapper = (userInputParametersObj: any) => {
		setUserInputParameters(userInputParametersObj);
		setTableUpdated(true);
	};

	const getClients = async () => {
		if (!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA)) {
			let clientId = get(userAccess, 'userProfile.clientId');
			let clientName = get(userAccess, 'userProfile.clientName');
			var client: IOptionItem = { value: clientId, label: clientName };
			setClients([client]);
			searchForm.setFieldValue('clientId', clientId);
			return;
		}
		try {
			setIsLoadingClientList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/client-account/list`);
			var clients: IOptionItem[] = [];
			response.data.userAccountList?.forEach((value: any) => {
				clients.push({ value: value.id, label: value.clientName });
			});
			let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingClientList(false);
		}
	};

	const handleTableThreeChange = (row: any, index: number, value: any, key: string) => {
		let rowsTemp = JSON.parse(JSON.stringify(tableThreeValueList));
		let rowTemp = row;
		rowTemp[key] = value ? Number(value) : null;
		rowsTemp[index] = rowTemp;
		setTableThreeValueList([...rowsTemp]);
		setTableThreeUpdated(true);
	};

	const generateTableThree = async (): Promise<any> => {
		try {
			setLoadingTableThree(true);
			const response = await insertEndpointPromise<any>(
				`/entitymanager/incremental-report/level-statistics`,
				tableThreeValueList.map((value: any) => {
					return {
						aov: userInputParameters.aov ? userInputParameters.aov : 0,
						conversionRate: value.conversionRate ? value.conversionRate : 0,
						cpc: value.cpc ? value.cpc : 0,
						rowId: value.rowId ? value.rowId : 0,
						spendLevelMax: value.spendLevelMax ? value.spendLevelMax : 0,
						spendLevelMin: value.spendLevelMin ? value.spendLevelMin : 0
					};
				})
			);
			const reportResponse = get(response, 'data');
			setTableThreeValueList(reportResponse);
			return reportResponse;
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setLoadingTableThree(false);
			setTableThreeUpdated(false);
		}
	};

	const reactToPrintContent = useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const clearTable = () => {
		// setTableThreeValueList([]);
		// setTableThreeUpdated(false);
		// setIncrementalReport(null);
		// setUserInputParameters({
		// 	aov: null,
		// 	baseSpendControlGeo: null,
		// 	totalSalesControlGeo: null,
		// 	totalSalesTestGeo: null,
		// 	totalSpendTestGeo: null,
		// 	controlGeoCPC: null,
		// 	conversionRate: null,
		// 	incrementalTestGeoCPC: null
		// });
		handleSearchWrapper();
	};

	const IncrementalTableOne = useCallback(() => {
		return (
			<IncrementalTestResultsTableOne
				incrementalReport={incrementalReport}
				setUserInputParameters={setUserInputParametersWrapper}
				userInputParameters={userInputParameters}
			/>
		);
	}, [incrementalReport, userInputParameters]);

	const IncrementalTableTwo = useCallback(() => {
		return (
			<IncrementalTestResultsTableTwo
				incrementalReport={incrementalReport}
				setUserInputParameters={setUserInputParametersWrapper}
				userInputParameters={userInputParameters}
			/>
		);
	}, [incrementalReport, userInputParameters]);

	const IncrementalTableThree = useCallback(() => {
		return (
			<IncrementalTestResultsTableThree
				aov={incrementalReport?.aov ?? 0}
				tableThreeValueList={tableThreeValueList}
				handleTableThreeChange={handleTableThreeChange}
				setTableThreeValueList={setTableThreeValueList}
				tableThreeUpdated={tableThreeUpdated}
				setTableThreeUpdated={setTableThreeUpdated}
				generateTableThree={generateTableThree}
				loadingTableThree={loadingTableThree}
			/>
		);
	}, [incrementalReport, tableThreeValueList, tableThreeUpdated, loadingTableThree]);

	const reactToPrintTrigger = useCallback(() => {
		return (
			<CPLoadingButton
				label={'Export'}
				startIcon={<FileUploadIcon />}
				variant="contained"
				disabled={!searchForm.dirty || !searchForm.isValid}
			/>
		);
	}, [searchForm]);

	const addHTMLNode = () => {
		const nameElement = document.createElement('h5');
		nameElement.id = 'client-name';
		const dateElement1 = document.createElement('h5');
		dateElement1.id = 'date-period-1';
		let clientName: any = clients.find((client) => searchForm.values.clientId === client.value);
		nameElement.textContent = `Client Name: ${clientName.label}`;
		dateElement1.textContent = `Date Period: ${moment(searchForm.values.startDate).format('MM/DD/YYYY')} to ${moment(
			searchForm.values.endDate
		).format('MM/DD/YYYY')}`;

		document.getElementById('client-name-div')?.append(nameElement);
		document.getElementById('date-period-div')?.append(dateElement1);
	};

	const removeHTMLNode = () => {
		let clientName = document.getElementById('client-name');
		let dateNode1 = document.getElementById('date-period-1');
		let dateNode2 = document.getElementById('date-period-2');
		clientName?.remove();
		dateNode1?.remove();
		dateNode2?.remove();
	};
	const getPageCSS = () => {
		return `@page { margin: 15px  15px  15px  15px !important; background-color: #FFF;size: 12in 9in; }`;
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('incremental_schedule')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }}>
					<style>{getPageCSS()}</style>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ width: '100%', height: 0 }}>
							<div style={{ width: '100px', height: '100px', position: 'absolute' }} ref={contentLeftRef} />
							<div
								style={{ width: '100px', height: '100px', position: 'absolute', right: '0px' }}
								ref={contentRightRef}
							/>
						</div>

						<div style={{ padding: '24px 28px' }}>
							<Grid container spacing={2} sx={{ padding: '0px 0px' }}>
								<Grid item xs={3}>
									<CPTextField
										label={t('report_name')}
										name="reportName"
										onBlur={searchForm.handleBlur}
										handleChange={searchForm.handleChange}
										error={searchForm.touched.reportName && searchForm.errors.reportName ? true : false}
										helperText={searchForm.touched.reportName ? searchForm.errors.reportName : ''}
										fullWidth
										size="small"
										required
										value={searchForm.values.reportName}
									/>
								</Grid>
								<Grid item xs={3}></Grid>
								<Grid item xs={6}></Grid>

								<Grid item xs={3}>
									<CPSingleSelectAutoCompleteDropDown
										name="clientId"
										size="small"
										options={clients}
										label={t('client_name')}
										loading={isLoadingClientList}
										disableClearable
										onBlur={searchForm.handleBlur}
										setFieldValue={searchForm.setFieldValue}
										error={searchForm.touched.clientId && searchForm.errors.clientId ? true : false}
										helperText={searchForm.touched.clientId ? searchForm.errors.clientId : ''}
										disabled={
											!isAuthOperation(
												get(userAccess, 'userProfile.featureList', []),
												ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
											)
										}
										value={get(
											[...clients].filter((e) => e.value === searchForm.values.clientId),
											'0',
											null
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<CPSingleSelectAutoCompleteDropDown
										name="channelId"
										size="small"
										options={channelList}
										label={t('channel_name')}
										loading={isLoadingChannels}
										setFieldValue={searchForm.setFieldValue}
										disableClearable
										required
										value={channelList.find((channel) => channel.value === searchForm.values.channelId) || null}
									/>
								</Grid>
								<Grid item xs={6}></Grid>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={3}>
											<CPDatePicker
												name="startDate"
												label={t('start_date')}
												size="small"
												fullWidth
												handleChange={(date) => {
													searchForm.setFieldValue('startDate', date ? date : undefined);
												}}
												value={searchForm.values.startDate}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.startDate && searchForm.errors.startDate ? true : false}
												helperText={searchForm.touched.startDate ? searchForm.errors.startDate : ''}
												maxDate={searchForm.values.endDate}
												allowKeyInput={true}
											/>
										</Grid>
										<Grid item xs={3}>
											<CPDatePicker
												name="endDate"
												label={t('end_date')}
												size="small"
												fullWidth
												minDate={searchForm.values.startDate}
												handleChange={(date) => {
													searchForm.setFieldValue('endDate', date ? date : undefined);
												}}
												value={searchForm.values.endDate}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.endDate && searchForm.errors.endDate ? true : false}
												helperText={searchForm.touched.endDate ? searchForm.errors.endDate : ''}
												maxDate={moment().subtract(1, 'd').toDate()}
												allowKeyInput={true}
											/>
										</Grid>
										<Grid item xs={2} sx={{ marginLeft: '16px' }}>
											<CPLoadingButton
												label={<>{id ? t('update') : t('save')}</>}
												variant={'contained'}
												style={{ width: '100%', borderRadius: '32px' }}
												onClick={searchForm.submitForm}
												loading={isLoadingClientList || isSaving || loadingTableThree || loadingResults}
												disabled={!searchForm.dirty || !searchForm.isValid || isSaving}
											/>
										</Grid>
										<Grid item xs={2}></Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>

					<Grid container spacing={2} sx={{ marginBottom: '15px' }}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6} justifyContent={'flex-end'} display="flex">
							<CPButton
								label={'Clear All'}
								startIcon={<DeleteOutlineIcon />}
								onClick={clearTable}
								variant="contained"
								disabled={loadingResults || !searchForm.dirty || !searchForm.isValid}
								style={{ marginRight: '10px' }}
							/>
							<CPLoadingButton
								label={'Generate'}
								startIcon={<RefreshIcon />}
								onClick={() => handleSearch()}
								variant="contained"
								loading={isLoadingClientList || loadingResults}
								disabled={!tableUpdated || loadingResults || !searchForm.dirty || !searchForm.isValid}
								style={{ marginRight: '10px' }}
							/>
							<ReactToPrint
								content={reactToPrintContent}
								documentTitle="Incremental Schedules Report.pdf"
								onAfterPrint={() => removeHTMLNode()}
								onBeforeGetContent={() => addHTMLNode()}
								removeAfterPrint
								trigger={reactToPrintTrigger}
							/>
						</Grid>
					</Grid>

					<div id="incrementalReportContainer" style={{ backgroundColor: '#FFF' }}>
						<div id="client-name-div"></div>
						<div id="date-period-div"></div>
						{loadingResults && <Loader />}
						{IncrementalTableOne()}
						{IncrementalTableTwo()}
						{IncrementalTableThree()}
					</div>
				</div>
				<CPConfirmationDialog
					open={openIncrementalScheduleConfirmation}
					title={id ? t('edit_incremental_schedule') : t('create_incremental_schedule')}
					content={id ? t('incremental_schedule_update_confirmation') : t('incremental_schedule_create_confirmation')}
					buttonOptions={
						<>
							<CPButton
								style={{ minWidth: '150px' }}
								label={t('go_back')}
								disabled={isSaving || loadingResults || loadingTableThree}
								onClick={() => {
									setOpenIncrementalScheduleConfirmation(false);
								}}
							/>
							<CPLoadingButton
								style={{ minWidth: '150px' }}
								label={t('save')}
								variant="contained"
								loading={isSaving || loadingResults || loadingTableThree}
								disabled={isSaving || loadingResults || loadingTableThree}
								onClick={() => {
									handleSaveWrapper();
								}}
							/>
						</>
					}
				/>
			</main>
		</div>
	);
};

export default DataAnalysis;
