export const ACCESS_TOKEN_KEY = 'auth.access_token';
export const X_USER_ID = 'X-User-ID';
export const REPORT_PARAMS = 'REPORT_PARAMS';
export const COLUMN_SETTINGS = 'COLUMN_SETTINGS';

const storage = {
	get: (key: string) => {
		return window.localStorage.getItem(key);
	},
	set: (key: string, val: string) => {
		return window.localStorage.setItem(key, val);
	},
	remove: (key: string) => {
		return window.localStorage.removeItem(key);
	}
};

const setAuthToken = (token: string) => {
	storage.set(ACCESS_TOKEN_KEY, token);
};

const getAuthToken = () => {
	return storage.get(ACCESS_TOKEN_KEY);
};

const removeAuthToken = () => {
	storage.remove(ACCESS_TOKEN_KEY);
};

const setXUserId = (xUserId: string) => {
	storage.set(X_USER_ID, xUserId);
};

const getXUserId = () => {
	return storage.get(X_USER_ID);
};

const clearLocalStorage = () => {
	localStorage.clear();
};

const setReportParameters = (reportParams: string) => {
	storage.set(REPORT_PARAMS, reportParams);
};
const getReportParameters = () => {
	return storage.get(REPORT_PARAMS);
};
const setColumnSettings = (columnSettings: string) => {
	storage.set(COLUMN_SETTINGS, columnSettings);
};
const getColumnSettings = () => {
	return storage.get(COLUMN_SETTINGS);
};
export {
	getAuthToken,
	setAuthToken,
	removeAuthToken,
	setXUserId,
	getXUserId,
	clearLocalStorage,
	setReportParameters,
	getReportParameters,
	setColumnSettings,
	getColumnSettings
};
