import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface ICPDatePicker {
	label?: string | React.ReactNode;
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	value?: Date | null | undefined;
	style?: React.CSSProperties;
	handleChange: (date: Date | null | undefined) => void;
	disabled?: boolean;
	size?: 'small' | 'medium';
	fullWidth?: boolean;
	onBlur?: (e: ChangeEvent) => void;
	error?: boolean | undefined;
	helperText?: string | any;
	name?: string;
	minDate?: Date;
	maxDate?: Date;
	required?: boolean;
	allowKeyInput?: boolean;
}

export default function CPDatePicker(props: ICPDatePicker) {
	const {
		label,
		value,
		disabled,
		handleChange,
		fullWidth,
		size,
		error,
		helperText,
		name,
		onBlur,
		minDate,
		maxDate,
		required,
		allowKeyInput = false
	} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePicker
				label={label}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				minDate={minDate}
				maxDate={maxDate}
				renderInput={(params) => (
					<TextField
						size={size}
						{...params}
						fullWidth={fullWidth}
						error={error}
						helperText={helperText}
						name={name}
						onBlur={onBlur}
						onKeyDown={(evt: any) => !allowKeyInput && evt.keyCode !== 9 && evt.keyCode !== 13 && evt.preventDefault()}
						required={required}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
