import { IconButton, Paper, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Loader from 'components/atoms/Loader';
import { ChangeEvent } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import { NotificationStatuses } from 'common/enums/notificationStatus.enum';

interface INotificationTable {
	rows: any;
	loading?: boolean;
	rowsPerPage: number;
	page: number;
	rowCount: number;
	setPage: (value: number) => void;
	setRowsPerPage: (value: number) => void;
	onEditAction?: (data: any, action: string) => void;
	onDeleteAction?: (data: any) => void;
}

export default function NotificationTable(props: INotificationTable) {
	const { rows, loading, rowsPerPage, page, setPage, setRowsPerPage, rowCount, onEditAction, onDeleteAction } = props;
	const { t } = useTranslation();

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0}>
			<TableContainer
				sx={{
					'&.MuiTableContainer-root': {
						width: '100%'
					}
				}}
			>
				<Table stickyHeader sx={{ width: '100%', tableLayout: 'auto', borderStyle: 'hidden' }} aria-label="label-table">
					<TableBody>
						{loading && (
							<TableRow>
								<TableCell colSpan={3} sx={{ padding: '6px 0px !important' }}>
									<Loader />
								</TableCell>
							</TableRow>
						)}

						{rows && rows.length > 0 && !loading && onEditAction
							? rows.map((row: any, index: number) => (
									<TableRow
										onClick={() =>
											row.notificationStatus === NotificationStatuses.UN_READ
												? onEditAction(row, NotificationStatuses.READ)
												: null
										}
										style={{
											height: '50px',
											...(row.notificationStatus === NotificationStatuses.UN_READ && {
												backgroundColor: '#EAEFF6',
												cursor: 'pointer'
											})
										}}
										key={`row-${index.toString()}`}
									>
										<TableCell
											sx={{
												textAlign: 'left',
												padding: '0px   !important',
												paddingLeft: '10px  !important',
												width: '1em'
											}}
										>
											{row.notificationStatus === NotificationStatuses.UN_READ && (
												<CircleIcon htmlColor="#1A73E8" sx={{ fontSize: '12px' }} />
											)}
										</TableCell>
										<TableCell
											sx={{
												textAlign: 'left',
												minWidth: '100%',
												padding: '15px 10px !important',
												whiteSpace: 'normal'
											}}
										>
											<span> {row.message}</span>
										</TableCell>
										<TableCell
											sx={{
												textAlign: 'right',
												width: '230px',
												padding: '6px ,0px  !important'
											}}
										>
											{onEditAction &&
												(row.notificationStatus === NotificationStatuses.READ ||
													row.notificationStatus === NotificationStatuses.UN_READ) && (
													<IconButton
														onClick={(event:any) => {
															event.stopPropagation();
															onEditAction(row, NotificationStatuses.RESOLVED);
														}}
														sx={{
															backgroundColor: '#352b61',
															borderRadius: '3px'
														}}
													>
														<DoneIcon htmlColor="white" sx={{ fontSize: '14px' }} />
														<span
															style={{
																color: 'white',
																fontSize: '14px',
																fontWeight: '700',
																margin: '0px 10px 0px 12px'
															}}
														>
															{t('Resolve')}
														</span>
													</IconButton>
												)}
											{onDeleteAction && (
												<Tooltip title={t('delete')} placement="bottom-start">
													<IconButton
														onClick={() => onDeleteAction(row)}
														sx={{
															backgroundColor: 'rgba(255, 31, 31, 0.11)',
															borderRadius: '3px',
															margin: '0px 10px 0px 35px'
														}}
													>
														<DeleteOutlineIcon htmlColor="red" sx={{ fontSize: '20px' }} />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
							  ))
							: !loading && (
									<TableRow>
										<TableCell colSpan={3} sx={{ padding: '6px 16px !important' }}>
											<Typography align="center">{t('no_records_found')}</Typography>
										</TableCell>
									</TableRow>
							  )}
					</TableBody>
				</Table>
			</TableContainer>
			{!loading && rows && rows.length > 0 && (
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
}