import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid, Typography } from '@mui/material';
import CPDatePicker from 'components/atoms/CPDatePicker';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { getEndpointPromise, insertEndpointPromise } from 'services/apiServices';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { useSnackbar } from 'notistack';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import { ILabels } from 'types/label.type';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import { getDownloadFileName, isAuthOperation } from 'utils/commonUtil';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CPMultiSelectDropDown from 'components/atoms/CPMultiSelectDropDown';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import ReportTable from 'components/molecules/ReportTable';
import ComparisonTable from 'components/molecules/ComparisonTable';
import get from 'lodash/get';

import ACCESS_TAGS from 'common/constants/accessTags';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import DeltaLabelReportListContainer from './DeltaLabelReportListContainer';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ScrollContainer from 'react-indiana-drag-scroll';
import moment from 'moment';
import SummaryReportContainer from './SummaryReportContainer';
import { MOMENT_DATE_FORMAT } from 'common/constants/applicationConstants';
import { getColumnSettings, getReportParameters, setColumnSettings, setReportParameters } from 'utils/localStorageUtil';
import CPCheckBox from 'components/atoms/CPCheckbox';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

const DeltaAnalysis = () => {
	const { t } = useTranslation();
	const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const [funnelList, setFunnelList] = useState([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [reportList, setReportList] = useState<any>([]);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [isLoadingLabelList, setIsLoadingLabelList] = useState<boolean>(false);
	const [labels, setLabels] = useState([]);
	const [clients, setClients] = useState<IOptionItem[] | []>([]);
	const contentLeftRef = useRef<HTMLDivElement>(null);
	const contentRightRef = useRef<HTMLDivElement>(null);
	const [searchFunnelSummary, setSearchFunnelSummary] = useState<boolean>(false);
	const [grandTotalRow, setGrandTotalRow] = useState<any>([]);
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const [labelList, setLabelList] = useState<number[]>([]);
	const [searchLabelReportLoading, setSearchLabelReportLoading] = useState<boolean>(false);
	const [labelReportList, setLabelReportList] = useState<any>([]);
	const componentRef = useRef<any>({ current: '' });
	const settings = useSelector((state: RootState) => state.settings);
	const [funnelSummaryList, setFunnelSummaryList] = useState<any>(null);
	const [reportColumns1, setReportColumns1] = useState<any>([]);
	const [reportColumns2, setReportColumns2] = useState<any>([]);
	const [isSpAttributedHidden, setIsSpAttributedHidden] = useState<boolean>(false);
	const [isGaLastClickHidden, setIsGaLastClickHidden] = useState<boolean>(false);
	const [isPlatformStatisticHidden, setIsPlatformStatisticHidden] = useState<boolean>(false);
	const [isExportingExelReport, setIsExportingExelReport] = useState<boolean>(false);
	const [isShowHideColumnLocked, setIsShowHideColumnLocked] = useState<boolean>(true);
	const { prmClientId, prmStartDate, prmEndDate, prmStartDate2, prmEndDate2 } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		componentRef.current = document.getElementById('FunnelListContainer');
	});

	// const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleExportDropdownClose = () => {
	// 	setAnchorEl(null);
	// };

	const validationSchema = yup
		.object({
			clientId: yup.string().required(t('value_required')).trim().nullable()
		})
		.shape({
			startDate1: yup
				.date()
				.typeError('Date needs to be mm/dd/yyyy format')
				.when('endDate1', (endDate1, schema) => endDate1 && schema.max(endDate1, t('cannot_exeed_base_end')))
				.required(t('value_required')),
			startDate2: yup
				.date()
				.typeError('Date needs to be mm/dd/yyyy format')
				.when('endDate2', (endDate2, schema) => endDate2 && schema.max(endDate2, t('cannot_exeed_compare_end')))
				.required(t('value_required')),
			endDate1: yup
				.date()
				.typeError('Date needs to be mm/dd/yyyy format')
				.max(
					moment().subtract(1, 'd').toDate(),
					t('should_be_earlier') + new Date(new Date().setDate(new Date().getDate())).toLocaleDateString()
				)
				.required(t('value_required')),
			endDate2: yup
				.date()
				.typeError('Date needs to be mm/dd/yyyy format')
				.max(
					moment().subtract(1, 'd').toDate(),
					t('should_be_earlier') + new Date(new Date().setDate(new Date().getDate())).toLocaleDateString()
				)
				.required(t('value_required'))
		});
	const searchForm = useFormik({
		initialValues: {
			startDate1: moment().subtract(7, 'd').toDate(),
			startDate2: moment().subtract(7, 'd').toDate(),
			endDate1: moment().subtract(1, 'd').toDate(),
			endDate2: moment().subtract(1, 'd').toDate(),
			clientId: null
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			handleSearch();
		}
	});

	useEffect(() => {
		getClients();
		getAllLabels();
		getAllFunnels();
	}, []);

	useEffect(() => {
		if (searchForm.values.clientId && searchForm.values.startDate1 && searchForm.values.endDate1) {
			const reportParams = {
				clientId: searchForm.values.clientId,
				startDate: moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT),
				endDate: moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT)
			};
			setReportParameters(JSON.stringify(reportParams));
		}
	}, [searchForm.values.clientId, searchForm.values.startDate1, searchForm.values.endDate1]);

	useEffect(() => {
		if (!isShowHideColumnLocked) {
			const columnSettings = {
				isSpAttributedHidden: isSpAttributedHidden,
				isGaLastClickHidden: isGaLastClickHidden,
				isPlatformStatisticHidden: isPlatformStatisticHidden
			};
			setColumnSettings(JSON.stringify(columnSettings));
		}
	}, [isSpAttributedHidden, isGaLastClickHidden, isPlatformStatisticHidden]);

	useEffect(() => {
		if (prmClientId && prmStartDate && prmEndDate && prmStartDate2 && prmEndDate2) {
			searchForm.setFieldValue('clientId', parseInt(prmClientId));
			searchForm.setFieldValue('startDate1', moment(prmStartDate).toDate());
			searchForm.setFieldValue('endDate1', moment(prmEndDate).toDate());
			searchForm.setFieldValue('startDate2', moment(prmStartDate2).toDate());
			searchForm.setFieldValue('endDate2', moment(prmEndDate2).toDate());
		} else {
			let reportParams = getReportParameters();
			if (reportParams) {
				let reportParamsObj = JSON.parse(reportParams);
				let startDate2 = moment(reportParamsObj.startDate).toDate();
				let endDate2 = moment(reportParamsObj.endDate).toDate();

				startDate2.setFullYear(startDate2.getFullYear() - 1);
				endDate2.setFullYear(endDate2.getFullYear() - 1);

				searchForm.setFieldValue('clientId', parseInt(reportParamsObj.clientId));
				searchForm.setFieldValue('startDate1', moment(reportParamsObj.startDate).toDate());
				searchForm.setFieldValue('endDate1', moment(reportParamsObj.endDate).toDate());
				searchForm.setFieldValue('startDate2', startDate2);
				searchForm.setFieldValue('endDate2', endDate2);
				searchForm.validateForm();
			}
		}
		// if (reportColumns1 && reportColumns2) {
		// 	setReportColumns1(tableColumns1);
		// 	setReportColumns2(tableColumns2);
		// }
		handleShowHideColumns(null);

		if (!isLoadingClientList && !isLoadingLabelList && searchForm.isValid && searchForm.dirty) {
			searchForm.submitForm();
		}
	}, [isLoadingClientList, isLoadingLabelList, funnelList]);

	useEffect(() => {
		if (reportList.length > 0 && funnelSummaryList) {
			getGrandTotal(reportList);
		}
	}, [funnelSummaryList, reportList]);

	const handleSearch = () => {
		if (funnelList.length > 0) {
			let clientId = searchForm.values.clientId;
			let startDate = moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT);
			let endDate = moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT);
			let startDate2 = moment(searchForm.values.startDate2).format(MOMENT_DATE_FORMAT);
			let endDate2 = moment(searchForm.values.endDate2).format(MOMENT_DATE_FORMAT);
			navigate(
				'/reports/delta-analysis/' + clientId + '/' + startDate + '/' + endDate + '/' + startDate2 + '/' + endDate2
			);

			let reportList: any[] = [];
			let labelReportApiList: any[] = [];
			funnelList.forEach((funnel: any) => {
				let getFunnel = insertEndpointPromise(`entitymanager/analysis-report/delta-analysis`, {
					clientId: searchForm.values.clientId,
					tableOneStartDate: moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT),
					tableOneEndDate: moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT),
					tableTwoStartDate: moment(searchForm.values.startDate2).format(MOMENT_DATE_FORMAT),
					tableTwoEndDate: moment(searchForm.values.endDate2).format(MOMENT_DATE_FORMAT),
					funnelId: funnel.id
				});
				reportList.push(getFunnel);
			});
			getAllSingleAnalysisData(reportList);
			getFunnelSummaryReport();
			if (labelList.length > 0) {
				labelList.forEach((label: any) => {
					let getLabel = insertEndpointPromise(`entitymanager/analysis-report/delta-analysis-label`, {
						clientId: searchForm.values.clientId,
						tableOneStartDate: moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT),
						tableOneEndDate: moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT),
						tableTwoStartDate: moment(searchForm.values.startDate2).format(MOMENT_DATE_FORMAT),
						tableTwoEndDate: moment(searchForm.values.endDate2).format(MOMENT_DATE_FORMAT),
						labelId: label
					});
					labelReportApiList.push(getLabel);
				});

				getAllSingleAnalysisLabelData(labelReportApiList);
			}
		}
	};

	const getAllSingleAnalysisData = async (reportList: any[]) => {
		try {
			setSearchLoading(true);
			const response = await Promise.all(reportList);
			setReportList(response);
		} catch (error: any) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchLoading(false);
		}
	};
	const getAllSingleAnalysisLabelData = async (labelDataList: any[]) => {
		try {
			let resultArray: any = [];
			setSearchLabelReportLoading(true);
			const response = await Promise.allSettled(labelDataList);
			response.forEach((result) => (result.status === 'fulfilled' ? resultArray.push(result.value) : null));
			setLabelReportList(resultArray);
		} catch (error: any) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchLabelReportLoading(false);
		}
	};
	const getAllFunnels = async () => {
		try {
			const response = await getEndpointPromise('/entitymanager/funnel/all');
			setFunnelList(response.data.funnels);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		}
	};
	const getClients = async () => {
		if (!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA)) {
			let clients = get(userAccess, 'userProfile.clients');

			let clientList: IOptionItem[] = [];
			clients?.forEach((value: any) => {
				clientList.push({ value: value.id, label: value.name });
			});
			let sortedClients = clientList.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);

			return;
		}
		try {
			setIsLoadingClientList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/client-account/list`);
			var clients: IOptionItem[] = [];
			response.data.userAccountList?.forEach((value: any) => {
				clients.push({ value: value.id, label: value.clientName });
			});
			let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingClientList(false);
		}
	};
	const getAllLabels = async () => {
		try {
			setIsLoadingLabelList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/label/all`);
			let newArray = [];
			newArray = response.data.labels.map((item: any) => ({
				value: item.labelName,
				key: item.id
			}));
			setLabels(newArray);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingLabelList(false);
		}
	};

	const tableColumns1: IColumn[] = [
		{ id: 'prospecting_channels', label: t('prospecting_channels'), minWidth: '300px', align: 'left' },
		{ id: 'cost', label: t('cost'), minWidth: '144px', align: 'right' },
		{ id: 'sessions', label: t('noOfSessions'), minWidth: '144px', align: 'right' },
		{ id: 'newUsers', label: t('new_users'), minWidth: '155px', align: 'right' },
		{ id: 'transactions', label: t('transactions'), minWidth: '155px', align: 'right' },
		{ id: 'revenue-1', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-1', label: t('ROAS'), minWidth: '138px', align: 'right' },
		{ id: 'revenue-2', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-2', label: t('ROAS'), minWidth: '138px', align: 'right' },
		{ id: 'revenue-3', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-3', label: t('ROAS'), minWidth: '138px', align: 'right' }
	];

	const tableColumns2: IColumn[] = [
		{ id: 'prospecting_channels', label: t('prospecting_channels'), minWidth: '300px', align: 'left', isHidden: true },
		{ id: 'cost', label: t('cost'), minWidth: '144px', align: 'right' },
		{ id: 'sessions', label: t('noOfSessions'), minWidth: '144px', align: 'right' },
		{ id: 'newUsers', label: t('new_users'), minWidth: '155px', align: 'right' },
		{ id: 'transactions', label: t('transactions'), minWidth: '155px', align: 'right' },
		{ id: 'revenue-1', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-1', label: t('ROAS'), minWidth: '144px', align: 'right' },
		{ id: 'revenue-2', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-2', label: t('ROAS'), minWidth: '144px', align: 'right' },
		{ id: 'revenue-3', label: t('revenue'), minWidth: '144px', align: 'right' },
		{ id: 'ROAS-3', label: t('ROAS'), minWidth: '144px', align: 'right' }
	];

	const getTableData = (tableDataList: any) => {
		let table1: any = [];
		let table2: any = [];
		let comparision: any = [];
		tableDataList.forEach((value: any) => {
			table1.push({
				channelName: value.channelName,
				cost: value.tableOneData.cost,
				newUsers: value.tableOneData.newUsers,
				sessions: value.tableOneData.sessions,
				transactions: value.tableOneData.transactions,
				gaRevenue: value.tableOneData.gaRevenue,
				gaRoas: value.tableOneData.gaRoas,
				platformRevenue: value.tableOneData.platformRevenue,
				platformRoas: value.tableOneData.platformRoas,
				spAttributeRevenue: value.tableOneData.spAttributeRevenue,
				spAttributeRoas: value.tableOneData.spAttributeRoas
			});
			table2.push({
				channelName: value.channelName,
				cost: value.tableTwoData.cost,
				newUsers: value.tableTwoData.newUsers,
				sessions: value.tableTwoData.sessions,
				transactions: value.tableTwoData.transactions,
				gaRevenue: value.tableTwoData.gaRevenue,
				gaRoas: value.tableTwoData.gaRoas,
				platformRevenue: value.tableTwoData.platformRevenue,
				platformRoas: value.tableTwoData.platformRoas,
				spAttributeRevenue: value.tableTwoData.spAttributeRevenue,
				spAttributeRoas: value.tableTwoData.spAttributeRoas
			});
			comparision.push({
				spendChange: value.comparisonData.spendChange,
				revenueChange: value.comparisonData.revenueChange,
				roasChange: value.comparisonData.roasChange,
				spendChangePercentage: value.comparisonData.spendChangePercentage,
				revChangePercentage: value.comparisonData.revChangePercentage,
				changePercentage: value.comparisonData.changePercentage
			});
		});
		return { table1: table1, table2: table2, comparision: comparision };
	};

	const getGrandTotal = (tableDataList: any) => {
		let table2conversionRate: number = 0;
		let table1conversionRate: number = 0;
		let table1Transactions = 0;
		let table2Transactions = 0;
		let table1Sessions = 0;
		let table2Sessions = 0;

		tableDataList.forEach((value: any) => {
			table1conversionRate = table1conversionRate + value.data.tableOneTotal.conversionRate;
			table1Transactions = table1Transactions + value.data.tableOneTotal.transactions;
			table1Sessions = table1Sessions + value.data.tableOneTotal.sessions;

			table2conversionRate = table2conversionRate + value.data.tableTwoTotal.conversionRate;
			table2Transactions = table2Transactions + value.data.tableTwoTotal.transactions;
			table2Sessions = table2Sessions + value.data.tableTwoTotal.sessions;
		});
		const table1 = {
			cost: funnelSummaryList.tableOneTotal.cost,
			newUsers: funnelSummaryList.tableOneTotal.newUsers,
			sessions: table1Sessions,
			transactions: funnelSummaryList.tableOneTotal.transactions,
			gaRevenue: funnelSummaryList.tableOneTotal.gaRevenue,
			gaRoas: funnelSummaryList.tableOneTotal.gaRoas,
			platformRevenue: funnelSummaryList.tableOneTotal.platformRevenue,
			platformRoas: funnelSummaryList.tableOneTotal.platformRoas,
			spAttributeRevenue: funnelSummaryList.tableOneTotal.spAttributeRevenue,
			spAttributeRoas: funnelSummaryList.tableOneTotal.spAttributeRoas
		};
		const table2 = {
			cost: funnelSummaryList.tableTwoTotal.cost,
			newUsers: funnelSummaryList.tableTwoTotal.newUsers,
			sessions: table2Sessions,
			transactions: funnelSummaryList.tableTwoTotal.transactions,
			gaRevenue: funnelSummaryList.tableTwoTotal.gaRevenue,
			gaRoas: funnelSummaryList.tableTwoTotal.gaRoas,
			platformRevenue: funnelSummaryList.tableTwoTotal.platformRevenue,
			platformRoas: funnelSummaryList.tableTwoTotal.platformRoas,
			spAttributeRevenue: funnelSummaryList.tableTwoTotal.spAttributeRevenue,
			spAttributeRoas: funnelSummaryList.tableTwoTotal.spAttributeRoas
		};
		const comparision = {
			spendChange: funnelSummaryList.comparisonTotal.spendChange,
			revenueChange: funnelSummaryList.comparisonTotal.revenueChange,
			roasChange: funnelSummaryList.comparisonTotal.roasChange,
			spendChangePercentage: funnelSummaryList.comparisonTotal.spendChangePercentage,
			revChangePercentage: funnelSummaryList.comparisonTotal.revChangePercentage,
			changePercentage: funnelSummaryList.comparisonTotal.changePercentage
		};
		setGrandTotalRow({ table1GrandTotal: table1, table2GrandTotal: table2, comparisionGrandTotal: comparision });
	};

	const handleMultiselect = (_: any, newValue: any) => {
		let newArray: number[] = [];
		newValue.forEach((item: any) => newArray.push(item.key));
		setLabelList(newArray);
	};

	// const reactToPrintContent = useCallback(() => {
	// 	return componentRef.current;
	// }, [componentRef.current]);

	// const reactToPrintTrigger = useCallback(() => {
	// 	return (
	// 		<MenuItem
	// 			disabled={
	// 				searchLoading ||
	// 				searchLabelReportLoading ||
	// 				!searchForm.dirty ||
	// 				!searchForm.isValid ||
	// 				(reportList.length === 0 && labelReportList.length === 0)
	// 			}
	// 		>
	// 			{t('pdf')}
	// 		</MenuItem>
	// 	);
	// }, [searchForm]);

	// const addHTMLNode = () => {
	// 	const nameElement = document.createElement('h5');
	// 	nameElement.id = 'client-name';
	// 	const dateElement1 = document.createElement('h5');
	// 	dateElement1.id = 'date-period-1';
	// 	const dateElement2 = document.createElement('h5');
	// 	dateElement2.id = 'date-period-2';
	// 	let clientName: any = clients.find((client) => searchForm.values.clientId === client.value);
	// 	nameElement.textContent = `Client Name: ${clientName.label}`;
	// 	dateElement1.textContent = `Date Period 1: ${moment(searchForm.values.startDate1).format('MM/DD/YYYY')} to ${moment(
	// 		searchForm.values.endDate1
	// 	).format('MM/DD/YYYY')}`;
	// 	dateElement2.textContent = `Date Period 2: ${moment(searchForm.values.startDate2).format('MM/DD/YYYY')} to ${moment(
	// 		searchForm.values.endDate2
	// 	).format('MM/DD/YYYY')}`;
	// 	document.getElementById('client-name-div')?.append(nameElement);
	// 	document.getElementById('date-period-div-1')?.append(dateElement1);
	// 	document.getElementById('date-period-div-2')?.append(dateElement2);
	// 	handleExportDropdownClose();
	// };

	// const removeHTMLNode = () => {
	// 	let clientName = document.getElementById('client-name');
	// 	let dateNode1 = document.getElementById('date-period-1');
	// 	let dateNode2 = document.getElementById('date-period-2');
	// 	clientName?.remove();
	// 	dateNode1?.remove();
	// 	dateNode2?.remove();
	// };
	const getPageCSS = () => {
		return `@page { margin: 15px  15px  15px  15px !important; background-color: #FFF;size: 30in 9in; }`;
	};

	const getFunnelSummaryReport = async () => {
		try {
			setSearchFunnelSummary(true);
			const response: any = await insertEndpointPromise<any>(`entitymanager/analysis-report/delta-analysis-summary`, {
				clientId: searchForm.values.clientId,
				tableOneStartDate: moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT),
				tableOneEndDate: moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT),
				tableTwoStartDate: moment(searchForm.values.startDate2).format(MOMENT_DATE_FORMAT),
				tableTwoEndDate: moment(searchForm.values.endDate2).format(MOMENT_DATE_FORMAT)
			});
			let summaryTableOne: any = [];
			let summaryTableTwo: any = [];
			let comparisonTable: any = [];
			response.data.tableDataList.map((row: any) => {
				summaryTableOne.push({ funnelName: row.funnelName, ...row.tableOneData });
				summaryTableTwo.push({ funnelName: row.funnelName, ...row.tableTwoData });
				comparisonTable.push({ ...row.comparisonData });
			});
			let summaryReport = {
				summaryTableOne: summaryTableOne,
				summaryTableTwo: summaryTableTwo,
				comparisonTable: comparisonTable,
				tableOneTotal: response.data.tableOneTotal,
				tableTwoTotal: response.data.tableTwoTotal,
				comparisonTotal: response.data.comparisonTotal
			};
			setFunnelSummaryList(summaryReport);
		} catch (error: any) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchFunnelSummary(false);
		}
	};

	const handleDataExport = async () => {
		setIsExportingExelReport(true);
		// handleExportDropdownClose();
		try {
			let response = await insertEndpointPromise(
				`/entitymanager/analysis-report/delta-analysis/download`,
				{
					clientId: searchForm.values.clientId,
					tableOneStartDate: moment(searchForm.values.startDate1).format(MOMENT_DATE_FORMAT),
					tableOneEndDate: moment(searchForm.values.endDate1).format(MOMENT_DATE_FORMAT),
					tableTwoStartDate: moment(searchForm.values.startDate2).format(MOMENT_DATE_FORMAT),
					tableTwoEndDate: moment(searchForm.values.endDate2).format(MOMENT_DATE_FORMAT),
					labelId: labelList
				},
				{ responseType: 'arraybuffer' }
			);
			let disposition = response.headers['content-disposition'];
			let filename = getDownloadFileName(disposition);
			let blob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsExportingExelReport(false);
		}
	};

	const handleShowHideColumns = (e: any) => {
		//table1

		let columnEditable1 = reportColumns1.length === 0 ? tableColumns1 : reportColumns1;
		let tbl1Revenue1 = _.find(columnEditable1, ['id', 'revenue-1']);
		let tbl1Roas1 = _.find(columnEditable1, ['id', 'ROAS-1']);
		let tbl1Revenue2 = _.find(columnEditable1, ['id', 'revenue-2']);
		let tbl1Roas2 = _.find(columnEditable1, ['id', 'ROAS-2']);
		let tbl1Revenue3 = _.find(columnEditable1, ['id', 'revenue-3']);
		let tbl1Roas3 = _.find(columnEditable1, ['id', 'ROAS-3']);
		//table2
		let columnEditable2 = reportColumns2.length === 0 ? tableColumns2 : reportColumns2;
		let tbl2Revenue1 = _.find(columnEditable2, ['id', 'revenue-1']);
		let tbl2Roas1 = _.find(columnEditable2, ['id', 'ROAS-1']);
		let tbl2Revenue2 = _.find(columnEditable2, ['id', 'revenue-2']);
		let tbl2Roas2 = _.find(columnEditable2, ['id', 'ROAS-2']);
		let tbl2Revenue3 = _.find(columnEditable2, ['id', 'revenue-3']);
		let tbl2Roas3 = _.find(columnEditable2, ['id', 'ROAS-3']);

		if (e != null) {
			setIsShowHideColumnLocked(false);
			//table1
			if (e.target.name === 'chkbxSpAttributed') {
				setIsSpAttributedHidden(!e.target.checked);
				if (tbl1Revenue1) {
					tbl1Revenue1.isHidden = !e.target.checked;
				}
				if (tbl1Roas1) {
					tbl1Roas1.isHidden = !e.target.checked;
				}
			}
			if (e.target.name === 'chkbxGaLastClick') {
				setIsGaLastClickHidden(!e.target.checked);
				if (tbl1Revenue2) {
					tbl1Revenue2.isHidden = !e.target.checked;
				}
				if (tbl1Roas2) {
					tbl1Roas2.isHidden = !e.target.checked;
				}
			}
			if (e.target.name === 'chkbxPlatformStatistics') {
				setIsPlatformStatisticHidden(!e.target.checked);
				if (tbl1Revenue3) {
					tbl1Revenue3.isHidden = !e.target.checked;
				}
				if (tbl1Roas3) {
					tbl1Roas3.isHidden = !e.target.checked;
				}
			}

			//table2

			if (e.target.name === 'chkbxSpAttributed') {
				setIsSpAttributedHidden(!e.target.checked);
				if (tbl2Revenue1) {
					tbl2Revenue1.isHidden = !e.target.checked;
				}
				if (tbl2Roas1) {
					tbl2Roas1.isHidden = !e.target.checked;
				}
			}
			if (e.target.name === 'chkbxGaLastClick') {
				setIsGaLastClickHidden(!e.target.checked);
				if (tbl2Revenue2) {
					tbl2Revenue2.isHidden = !e.target.checked;
				}
				if (tbl2Roas2) {
					tbl2Roas2.isHidden = !e.target.checked;
				}
			}
			if (e.target.name === 'chkbxPlatformStatistics') {
				setIsPlatformStatisticHidden(!e.target.checked);
				if (tbl2Revenue3) {
					tbl2Revenue3.isHidden = !e.target.checked;
				}
				if (tbl2Roas3) {
					tbl2Roas3.isHidden = !e.target.checked;
				}
			}
		} else {
			let columnSettings = getColumnSettings();

			if (columnSettings) {
				let columnSettingsObj = JSON.parse(columnSettings);

				let isSpAttributedHidden = columnSettingsObj.isSpAttributedHidden;
				let isGaLastClickHidden = columnSettingsObj.isGaLastClickHidden;
				let isPlatformStatisticHidden = columnSettingsObj.isPlatformStatisticHidden;
				setIsSpAttributedHidden(isSpAttributedHidden);
				setIsGaLastClickHidden(isGaLastClickHidden);
				setIsPlatformStatisticHidden(isPlatformStatisticHidden);

				if (tbl1Revenue1) {
					tbl1Revenue1.isHidden = isSpAttributedHidden;
				}
				if (tbl1Roas1) {
					tbl1Roas1.isHidden = isSpAttributedHidden;
				}
				if (tbl2Revenue1) {
					tbl2Revenue1.isHidden = isSpAttributedHidden;
				}
				if (tbl2Roas1) {
					tbl2Roas1.isHidden = isSpAttributedHidden;
				}

				if (tbl1Revenue2) {
					tbl1Revenue2.isHidden = isGaLastClickHidden;
				}
				if (tbl1Roas2) {
					tbl1Roas2.isHidden = isGaLastClickHidden;
				}
				if (tbl2Revenue2) {
					tbl2Revenue2.isHidden = isGaLastClickHidden;
				}
				if (tbl2Roas2) {
					tbl2Roas2.isHidden = isGaLastClickHidden;
				}

				if (tbl1Revenue3) {
					tbl1Revenue3.isHidden = isPlatformStatisticHidden;
				}
				if (tbl1Roas3) {
					tbl1Roas3.isHidden = isPlatformStatisticHidden;
				}
				if (tbl2Revenue3) {
					tbl2Revenue3.isHidden = isPlatformStatisticHidden;
				}

				if (tbl2Roas3) {
					tbl2Roas3.isHidden = isPlatformStatisticHidden;
				}
			}
		}
		setReportColumns1(columnEditable1);
		setReportColumns2(columnEditable2);
	};
	return (
		<div className={styles.container}>
			<Breadcrumb title={t('delta_analysis')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }}>
					<style>{getPageCSS()}</style>
					<Typography variant="h6" sx={{ marginTop: '15px' }}>
						{t('Search')}
					</Typography>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ width: '100%', height: 0 }}>
							<div style={{ width: '100px', height: '100px', position: 'absolute' }} ref={contentLeftRef} />
							<div
								style={{ width: '100px', height: '100px', position: 'absolute', right: '0px' }}
								ref={contentRightRef}
							/>
						</div>

						<div style={{ padding: '24px 28px' }}>
							<Grid container spacing={2} sx={{ padding: '0px 0px' }}>
								<Grid item xs={12}>
									<Typography
										variant="info1"
										sx={{ fontSize: '11px', fontWeight: 400, lineHeight: '16.5px', paddingTop: '0px' }}
									>
										{t('need_to_select_client_to_start_the_search')}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<CPSingleSelectAutoCompleteDropDown
										name="clientId"
										size="small"
										options={clients}
										label={t('client_name')}
										loading={isLoadingClientList}
										disableClearable
										onBlur={searchForm.handleBlur}
										setFieldValue={searchForm.setFieldValue}
										error={searchForm.touched.clientId && searchForm.errors.clientId ? true : false}
										helperText={searchForm.touched.clientId ? searchForm.errors.clientId : ''}
										// disabled={
										// 	!isAuthOperation(
										// 		get(userAccess, 'userProfile.featureList', []),
										// 		ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
										// 	)
										// }
										value={get(
											[...clients].filter((e) => e.value === searchForm.values.clientId),
											'0',
											null
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<CPMultiSelectDropDown
										id="labelList"
										options={labels}
										label={t('filterby_label')}
										size={'small'}
										name="label"
										handleChange={handleMultiselect}
										loading={isLoadingLabelList}
										disabled={isLoadingLabelList}
									/>
								</Grid>
								{/* <Grid item xs={12}>
									<Grid container>
										<Grid item xs={4.8}>
											<Typography variant="info1" sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}>
												{t('datatable1')}
											</Typography>
										</Grid>
										<Grid item xs={7.2}>
											<Typography
												variant="info1"
												sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '18px', marginLeft: '18px' }}
											>
												{t('datatable2')}
											</Typography>
										</Grid>
									</Grid>
								</Grid> */}
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={2.4}>
											<CPDatePicker
												name="startDate1"
												label={t('base_start')}
												size="small"
												fullWidth
												handleChange={(date) => {
													searchForm.setFieldValue('startDate1', date ? date : undefined);
												}}
												value={searchForm.values.startDate1}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.startDate1 && searchForm.errors.startDate1 ? true : false}
												helperText={searchForm.touched.startDate1 ? searchForm.errors.startDate1 : ''}
												maxDate={searchForm.values.endDate1}
												allowKeyInput={true}
											/>
										</Grid>

										<Grid item xs={2.4}>
											<CPDatePicker
												name="endDate1"
												label={t('base_end')}
												size="small"
												fullWidth
												minDate={searchForm.values.startDate1}
												handleChange={(date) => {
													searchForm.setFieldValue('endDate1', date ? date : undefined);
												}}
												value={searchForm.values.endDate1}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.endDate1 && searchForm.errors.endDate1 ? true : false}
												helperText={searchForm.touched.endDate1 ? searchForm.errors.endDate1 : ''}
												maxDate={moment().subtract(1, 'd').toDate()}
												allowKeyInput={true}
											/>
										</Grid>
										<Grid item xs={2.4} sx={{ marginLeft: '16px' }}>
											<CPDatePicker
												name="startDate2"
												label={t('compareto_start')}
												size="small"
												fullWidth
												handleChange={(date) => {
													searchForm.setFieldValue('startDate2', date ? date : undefined);
												}}
												value={searchForm.values.startDate2}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.startDate2 && searchForm.errors.startDate2 ? true : false}
												helperText={searchForm.touched.startDate2 ? searchForm.errors.startDate2 : ''}
												maxDate={searchForm.values.endDate2}
												allowKeyInput={true}
											/>
										</Grid>

										<Grid item xs={2.4}>
											<CPDatePicker
												name="endDate2"
												label={t('compareto_end')}
												size="small"
												fullWidth
												minDate={searchForm.values.startDate2}
												handleChange={(date) => {
													searchForm.setFieldValue('endDate2', date ? date : undefined);
												}}
												value={searchForm.values.endDate2}
												onBlur={searchForm.handleBlur}
												error={searchForm.touched.endDate2 && searchForm.errors.endDate2 ? true : false}
												helperText={searchForm.touched.endDate2 ? searchForm.errors.endDate2 : ''}
												maxDate={moment().subtract(1, 'd').toDate()}
												allowKeyInput={true}
											/>
										</Grid>
										<Grid item xs={2} sx={{ marginLeft: '16px' }}>
											<CPLoadingButton
												label={
													<>
														<ManageSearchIcon sx={{ marginRight: '12px' }} />
														{t('Search')}
													</>
												}
												variant={'contained'}
												style={{ width: '100%', borderRadius: '32px' }}
												onClick={handleSearch}
												loading={isLoadingClientList || searchLoading || searchLabelReportLoading}
												disabled={searchLoading || searchLabelReportLoading || !searchForm.dirty || !searchForm.isValid}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>

					<Grid container spacing={2} sx={{ marginBottom: '15px' }}>
						<Grid item xs={6}>
							<CPCheckBox
								label="SP Attributed"
								handleChange={handleShowHideColumns}
								name="chkbxSpAttributed"
								checked={!isSpAttributedHidden}
							/>
							<CPCheckBox
								label="GA Last Click"
								handleChange={handleShowHideColumns}
								name="chkbxGaLastClick"
								checked={!isGaLastClickHidden}
							/>
							<CPCheckBox
								label="Platform Statistics"
								handleChange={handleShowHideColumns}
								name="chkbxPlatformStatistics"
								checked={!isPlatformStatisticHidden}
							/>
						</Grid>
						<Grid item xs={6} justifyContent={'flex-end'} display="flex">
							<CPLoadingButton
								label={t('export')}
								startIcon={<FileUploadIcon />}
								onClick={handleDataExport}
								variant="contained"
								loading={isExportingExelReport || isLoadingClientList || searchLoading || searchLabelReportLoading}
								disabled={!(searchForm.isValid && searchForm.dirty && !searchLoading) || searchLabelReportLoading}
							/>
							{/* <CPButton
								label={'Export'}
								startIcon={<FileUploadIcon />}
								variant="contained"
								onClick={handleExportClick}
								disabled={
									searchLoading ||
									searchLabelReportLoading ||
									isExportingExelReport ||
									!searchForm.dirty ||
									!searchForm.isValid ||
									(reportList.length === 0 && labelReportList.length === 0)
								}
							/> */}
							{/* <Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleExportDropdownClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button'
								}}
							>
								<ReactToPrint
									content={reactToPrintContent}
									documentTitle="delta-analysis-report.pdf"
									onAfterPrint={() => removeHTMLNode()}
									onBeforeGetContent={() => addHTMLNode()}
									removeAfterPrint
									trigger={reactToPrintTrigger}
								/>
								<MenuItem onClick={handleDataExport}>Excel</MenuItem>
							</Menu> */}
						</Grid>
					</Grid>
					<ScrollContainer
						hideScrollbars={false}
						className="scroll-container"
						style={{ overflowX: 'scroll', display: 'flex', width: settings.isNavBarOpen ? '75vw' : '88vw' }}
					>
						<div id="FunnelListContainer" style={{ backgroundColor: '#FFF' }}>
							<div id="client-name-div"></div>
							<div id="date-period-div-1"></div>
							<div id="date-period-div-2"></div>
							<div>
								{funnelList.length > 0 &&
									funnelList.map((funnel: any, index: number) => {
										let tempObj = getTableData(
											reportList[index]?.data?.tableDataList?.length > 0 ? reportList[index].data.tableDataList : []
										);
										return (
											<div
												key={index}
												style={{
													display: 'flex',
													marginBottom: '20px'
												}}
											>
												<div>
													<ReportTable
														columns={reportColumns1}
														funnel={funnel}
														width={settings.isNavBarOpen ? '77vw' : '88vw'}
														totalRow={
															reportList[index]?.data?.tableOneTotal ? reportList[index].data.tableOneTotal : {}
														}
														rows={tempObj.table1.length > 0 ? tempObj.table1 : []}
														loading={searchLoading}
														grandTotalRow={
															index === funnelList.length - 1 && grandTotalRow?.table1GrandTotal
																? grandTotalRow.table1GrandTotal
																: null
														}
														tableCaption={t('base')}
														isSpAttributedHidden={isSpAttributedHidden}
														isGaLastClickHidden={isGaLastClickHidden}
														isPlatformStatisticHidden={isPlatformStatisticHidden}
													/>
												</div>
												<div style={{ marginLeft: '50px' }}>
													<ReportTable
														isShowChannels={false}
														columns={reportColumns2}
														funnel={funnel}
														width={settings.isNavBarOpen ? '77vw' : '88vw'}
														totalRow={
															reportList[index]?.data?.tableTwoTotal ? reportList[index].data.tableTwoTotal : {}
														}
														rows={tempObj.table2.length > 0 ? tempObj.table2 : []}
														loading={searchLoading}
														grandTotalRow={
															index === funnelList.length - 1 && grandTotalRow?.table2GrandTotal
																? grandTotalRow.table2GrandTotal
																: null
														}
														tableCaption={t('compareto')}
														isSpAttributedHidden={isSpAttributedHidden}
														isGaLastClickHidden={isGaLastClickHidden}
														isPlatformStatisticHidden={isPlatformStatisticHidden}
													/>
												</div>
												<div style={{ marginLeft: '50px' }}>
													<ComparisonTable
														columns={tableColumns1}
														funnel={funnel}
														totalRow={
															reportList[index]?.data?.comparisonTotal ? reportList[index].data.comparisonTotal : {}
														}
														rows={tempObj.comparision.length > 0 ? tempObj.comparision : []}
														loading={searchLoading}
														grandTotalRow={
															index === funnelList.length - 1 && grandTotalRow?.comparisionGrandTotal
																? grandTotalRow.comparisionGrandTotal
																: null
														}
														tableCaption={t('comparison_table')}
													/>
												</div>
											</div>
										);
									})}
							</div>
							{funnelSummaryList && (
								<SummaryReportContainer
									summaryReport={funnelSummaryList}
									searchFunnelSummary={searchFunnelSummary}
									isSpAttributedHidden={isSpAttributedHidden}
									isGaLastClickHidden={isGaLastClickHidden}
									isPlatformStatisticHidden={isPlatformStatisticHidden}
								/>
							)}
							{labelList.length > 0 && (
								<DeltaLabelReportListContainer
									labelReportList={labelReportList}
									labelList={labelList}
									searchLabelReportLoading={searchLabelReportLoading}
									isSpAttributedHidden={isSpAttributedHidden}
									isGaLastClickHidden={isGaLastClickHidden}
									isPlatformStatisticHidden={isPlatformStatisticHidden}
								/>
							)}
						</div>
					</ScrollContainer>
				</div>
			</main>
		</div>
	);
};

export default DeltaAnalysis;
