import { configureStore } from '@reduxjs/toolkit';
import settingsSlice from './settingsSlice';
import userSlice from './userSlice';
// ...
const store = configureStore({
	reducer: {
		userProfile: userSlice,
		settings: settingsSlice
	}
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
