import { useMsal } from '@azure/msal-react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import Popover from '@mui/material/Popover';
import { logoutRequest } from 'configs/azureConfig';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage } from 'utils/localStorageUtil';

interface IUserProfilePopOver {
	anchorEl: HTMLButtonElement | null;
	handleClose: () => void;
}
export default function UserProfilePopOver(props: IUserProfilePopOver) {
	const { anchorEl, handleClose } = props;
	const open = Boolean(anchorEl);
	const { t } = useTranslation();
	const id = open ? 'user-popover' : undefined;
	const navigate = useNavigate();
	const { instance, accounts } = useMsal();
	const currentAccount = accounts[0];

	const handleProfileClick = () => {
		navigate('/my-profile');
		handleClose();
	};

	const handleLogout = () => {
		clearLocalStorage();
		instance.logoutRedirect({ ...logoutRequest, account: currentAccount });
	};

	const handleChangePasswordClick = () => {
		navigate('/change-password');
		handleClose();
	};

	return (
		<div>
			<Popover
				id={id}
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<List sx={{ width: '200px', padding: 0 }}>
					<ListItemButton onClick={handleProfileClick}>
						<ListItemText primary={t('my_profile')} />
					</ListItemButton>
					<ListItemButton onClick={handleChangePasswordClick}>
						<ListItemText primary={t('change_password')} />
					</ListItemButton>
					<ListItemButton onClick={handleLogout}>
						<ListItemText primary={t('log_out')} />
					</ListItemButton>
				</List>
			</Popover>
		</div>
	);
}
