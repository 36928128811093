import axios from 'axios';
import { getAuthToken, getXUserId } from 'utils/localStorageUtil';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	headers: {
		'Content-type': 'application/json',
		'Access-Control-Allow-Origin': true,
		'Access-Control-Allow': '*'
	}
});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = getAuthToken();
		const xUserId = getXUserId();
		if (token) {
			return {
				...config,
				headers: {
					'Content-type': 'application/json',
					'Access-Control-Allow': '*',
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': true,
					...(xUserId && { 'X-User-ID': xUserId })
				}
			};
		}

		return config;
	},
	(error) => {
		// handle the error
		return Promise.reject(error);
	}
);

export default axiosInstance;
