import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IncrementReportTableCell } from 'components/atoms/CPReportTableCell';
import CPTextField from 'components/atoms/CPTextField';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';

interface ISummaryReportContainer {
	incrementalReport?: any;
	searchTestReults?: any;
	handleChange?: any;
	userInputParameters: any;
	setUserInputParameters: any;
}
const IncrementalTestResultsTableTwo = (props: ISummaryReportContainer) => {
	const { t } = useTranslation();
	const { incrementalReport, userInputParameters, setUserInputParameters } = props;

	// const theme = useTheme();
	const columns: IColumn[] = [
		{
			id: 'previous-results',
			label: '',
			align: 'left',
			style: { borderLeft: 0 }
		},
		{
			id: 'spend',
			label: t('spend'),
			align: 'right'
		},
		{
			id: 'cpc',
			label: t('cpc'),
			align: 'right'
		},
		{
			id: 'clicks',
			label: t('clicks'),
			align: 'right'
		},
		{
			id: 'conversionRate',
			label: t('conversionRate'),
			align: 'right'
		},
		{
			id: 'conv',
			label: t('conv'),
			align: 'right'
		},
		{
			id: 'aov_',
			label: t('aov_'),
			align: 'right',
			type: 'currency'
		},
		{
			id: 'totalSales',
			label: t('totalSales'),
			align: 'right',
			type: 'currency'
		},
		{
			id: 'channelRoas',
			label: t('channelRoas'),
			align: 'right',
			type: 'currency'
		}
	];

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', margin: '30px 0px' }} elevation={0}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							{columns.map((column: IColumn) => (
								<TableCell sx={{ padding: 0, fontSize: '13px' }} key={column.id} align={column.align}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{/* {incrementalReport && ( */}
					<TableBody>
						<TableRow>
							<IncrementReportTableCell sx={{ textAlign: 'left', paddingLeft: 2, whiteSpace: 'inherit' }}>
								{t('controlGeo')}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.controlGeo?.spend ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								<CPTextField
									type="number"
									value={userInputParameters.controlGeoCPC}
									name={''}
									handleChange={(e: any) => {
										setUserInputParameters({
											...userInputParameters,
											controlGeoCPC: e.target.value ? Number(e.target.value) : e.target.value
										});
									}}
									inputProps={{
										style: {
											textAlign: 'right',
											fontSize: '12px'
										}
									}}
									size="small"
								/>
							</IncrementReportTableCell>
							<IncrementReportTableCell>{incrementalReport?.geoData?.controlGeo?.clicks ?? 0}</IncrementReportTableCell>
							<IncrementReportTableCell>
								<CPTextField
									type="number"
									size="small"
									value={userInputParameters.conversionRate}
									handleChange={(e: any) => {
										setUserInputParameters({
											...userInputParameters,
											conversionRate: e.target.value ? Number(e.target.value) : e.target.value
										});
									}}
									inputProps={{
										style: {
											textAlign: 'right',
											fontSize: '12px'
										}
									}}
									name={''}
								/>
							</IncrementReportTableCell>
							<IncrementReportTableCell>{incrementalReport?.geoData?.controlGeo?.conv ?? 0}</IncrementReportTableCell>
							<IncrementReportTableCell>{incrementalReport?.geoData?.controlGeo?.aov ?? 0}</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.controlGeo?.totalSales ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.controlGeo?.channelRoas ?? 0}
							</IncrementReportTableCell>
						</TableRow>
						<TableRow>
							<IncrementReportTableCell sx={{ textAlign: 'left', paddingLeft: 2, whiteSpace: 'inherit' }}>
								{t('incrementalTestGeo')}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.incrementalTestGeo?.spend ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								<CPTextField
									type="number"
									size="small"
									value={userInputParameters.incrementalTestGeoCPC}
									handleChange={(e: any) => {
										setUserInputParameters({
											...userInputParameters,
											incrementalTestGeoCPC: e.target.value ? Number(e.target.value) : e.target.value
										});
									}}
									inputProps={{
										style: {
											textAlign: 'right',
											fontSize: '12px'
										}
									}}
									// InputProps={{
									// 	disableUnderline: true // <== added this
									// }}
									name="incrementalTestGeo"
								/>
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.incrementalTestGeo?.clicks ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell sx={{ paddingRight: '27px!important' }} align="right">
								{incrementalReport?.geoData?.incrementalTestGeo?.conversionRate ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.incrementalTestGeo?.conv ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.incrementalTestGeo?.aov ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.incrementalTestGeo?.totalSales ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.incrementalTestGeo?.channelRoas ?? 0}
							</IncrementReportTableCell>
						</TableRow>
						<TableRow>
							<IncrementReportTableCell sx={{ textAlign: 'left', paddingLeft: 2, whiteSpace: 'inherit' }}>
								{t('totalTestGeo')}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.totalTestGeo?.spend ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell sx={{ paddingRight: '27px!important' }} align="right">
								{incrementalReport?.geoData?.totalTestGeo?.cpc ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.totalTestGeo?.clicks ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell sx={{ paddingRight: '27px!important' }} align="right">
								{incrementalReport?.geoData?.totalTestGeo?.conversionRate ?? 0}
							</IncrementReportTableCell>
							<IncrementReportTableCell>{incrementalReport?.geoData?.totalTestGeo?.conv ?? 0}</IncrementReportTableCell>
							<IncrementReportTableCell>{incrementalReport?.geoData?.totalTestGeo?.aov ?? 0}</IncrementReportTableCell>
							<IncrementReportTableCell>
								{formatCurrency(incrementalReport?.geoData?.totalTestGeo?.totalSales ?? 0, true, false)}
							</IncrementReportTableCell>
							<IncrementReportTableCell>
								{incrementalReport?.geoData?.totalTestGeo?.channelRoas ?? 0}
							</IncrementReportTableCell>
						</TableRow>
					</TableBody>
					{/* )} */}
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default IncrementalTestResultsTableTwo;
