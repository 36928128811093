import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Collapse, ListItem, ListItemButton, ListSubheader, Tooltip } from '@mui/material';
import { Drawer, DrawerHeader } from './Drawer';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import Loader from 'components/atoms/Loader';
import {
	forgotPasswordRequest,
	loginRequest,
	logoutRequest,
	passwordResetPolicy,
	signInPolicy
} from 'configs/azureConfig';
import { clearLocalStorage, setAuthToken, setXUserId } from 'utils/localStorageUtil';
import { drawerWidth } from './AppBar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getEndpointPromise } from 'services/apiServices';
import { setUserProfile } from 'store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IUserInfo } from 'types/userProfile.type';
import miniLogo from 'assets/app-logo-mini.png';
import logo from 'assets/app-logo.png';
import { APP_NAVIGATION } from 'configs/navConfig';
import { theme } from 'configs/theme';
import { RootState } from 'store';
import { isAuthRoute } from 'utils/commonUtil';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import MsalErrorCode from 'common/enums/msalErrorCode';
import { setNavBarOpen } from 'store/settingsSlice';
import { NavLink } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserProfilePopOver from './UserProfilePopOver';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import ACCESS_TAGS from 'common/constants/accessTags';

interface ILayout {
	children: React.ReactNode;
}

export default function Layout(props: ILayout) {
	const { t } = useTranslation();
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const userAccess = useSelector((state: RootState) => state.userProfile);
	const { children } = props;
	const [sideNavBarOpen, setSideNavBarOpen] = React.useState(true);
	const [selectedNavItem, setSelectedNavItem] = React.useState('');
	const [authenticated, setAuthenticated] = React.useState<boolean>();
	const [profileAnchor, setProfileAnchor] = React.useState<HTMLButtonElement | null>(null);
	const navigate = useNavigate();

	const currentAccount = accounts[0];
	const requestSignInPolicy = {
		...loginRequest,
		account: currentAccount
	};
	const requestPasswordResetPolicy = {
		...forgotPasswordRequest,
		account: currentAccount
	};
	useEffect(() => {
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			let tokenReleasedPolicy: string = get(currentAccount, 'idTokenClaims.acr', signInPolicy).toUpperCase();
			let request = tokenReleasedPolicy === passwordResetPolicy ? requestPasswordResetPolicy : requestSignInPolicy;
			instance
				.acquireTokenSilent(request)
				.then((accessTokenResponse) => {
					// Acquire token silent success
					let accessToken = accessTokenResponse.accessToken;
					// Call your API with token
					setAuthToken(accessToken);
					getUserProfile();
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						if (error.errorMessage.includes(MsalErrorCode.SESSION_DOES_NOT_HAVE)) {
							return instance.logoutRedirect({
								...logoutRequest,
								account: currentAccount
							});
						} else {
							return instance.acquireTokenRedirect(request);
						}
					} else {
						return instance.logoutRedirect({
							...logoutRequest,
							account: currentAccount
						});
					}
				});
		}
	}, [isAuthenticated, inProgress]);

	const getUserProfile = async () => {
		try {
			const response = await getEndpointPromise<IUserInfo>('/entitymanager/user/profile', 'data');
			dispatch(setUserProfile(response));
			setAuthenticated(isAuthenticated);
			setXUserId(get(response, 'id'));
		} catch (error: any) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
			clearLocalStorage();
			instance.logoutRedirect({
				account: currentAccount
			});
		}
	};

	const handleSideNavOpen = () => {
		dispatch(setNavBarOpen(true));
		setSideNavBarOpen(true);
	};

	const handleSideNavClose = () => {
		dispatch(setNavBarOpen(false));
		setSideNavBarOpen(false);
	};

	const handleNavigation = (item: any) => {
		if (get(item, 'children')) {
			setSelectedNavItem(item.id === selectedNavItem ? '' : item.id);
		}
	};

	const handleClose = () => {
		setProfileAnchor(null);
	};

	const handleMoreInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
		setProfileAnchor(event.currentTarget);
	};
	const handleNotifications = () => {
		navigate('/notifications');
	};
	const handleOperations = () => {
		navigate('/operations');
	};

	if (!authenticated) {
		return <Loader />;
	} else {
		return (
			<>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<Drawer variant="permanent" open={sideNavBarOpen} color={'primary'}>
						<DrawerHeader sx={{ height: '140px', alignSelf: 'center', alignItems: 'self-start' }}>
							<Box sx={{ display: sideNavBarOpen ? '' : 'none', marginTop: '48px' }}>
								<NavLink to={'/'}>
									<img src={logo} alt="logo" style={{ height: '50px' }} />
								</NavLink>
							</Box>
							<Box sx={{ display: sideNavBarOpen ? 'none' : '', marginTop: '50px' }}>
								<NavLink to={'/'}>
									<img src={miniLogo} alt="logo" style={{ height: '48px' }} />
								</NavLink>
							</Box>
						</DrawerHeader>
						<Box
							sx={{
								height: '80%',
								overflowY: 'auto',
								overflowX: 'hidden',
								'&::-webkit-scrollbar': {
									width: '2px'
								},
								'&::-webkit-scrollbar-track': {
									boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
									webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: 'rgba(0,0,0,.1)',
									outline: '1px solid slategrey',
									borderRadius: '10px'
								}
							}}
						>
							<List
								sx={{
									width: '100%',
									maxWidth: drawerWidth,
									paddingInline: '12px'
								}}
								subheader={
									<>
										{sideNavBarOpen ? (
											<ListSubheader
												component="div"
												sx={{
													fontSize: '12px',
													color: '#CECECE',
													fontWeight: 500,
													textTransform: 'uppercase'
												}}
											>
												Main menu
											</ListSubheader>
										) : (
											<Divider />
										)}
									</>
								}
							>
								{APP_NAVIGATION.map(
									(item: any) =>
										isAuthRoute(get(userAccess, 'userProfile.featureList', []), item.auth) && (
											<div key={item.id}>
												{!item.children ? (
													<NavLink
														to={item.path ? item.path : '/'}
														className={({ isActive }) => (isActive ? 'link-active' : 'link')}
													>
														<ListItem
															sx={{
																paddingLeft: sideNavBarOpen ? '16px' : '8px'
															}}
														>
															<ListItemIcon sx={{ minWidth: '32px', color: theme.palette.primary.main }}>
																<item.icon />
															</ListItemIcon>
															{sideNavBarOpen && (
																<ListItemText
																	primary={t(item.label.toString())}
																	primaryTypographyProps={{ fontSize: '14px' }}
																/>
															)}
														</ListItem>
													</NavLink>
												) : (
													<ListItemButton
														onClick={() => handleNavigation(item)}
														sx={{
															paddingLeft: sideNavBarOpen ? '16px' : '8px'
														}}
													>
														<ListItemIcon sx={{ minWidth: '32px' }}>
															<item.icon />
														</ListItemIcon>
														{sideNavBarOpen && (
															<>
																<ListItemText
																	primary={t(item.label.toString())}
																	primaryTypographyProps={{
																		fontSize: '14px'
																	}}
																/>
																{item.children && (item.id === selectedNavItem ? <ExpandLess /> : <ExpandMore />)}
															</>
														)}
													</ListItemButton>
												)}
												{item.children && (
													<Collapse in={item.id === selectedNavItem} timeout="auto" unmountOnExit>
														<List component="div" disablePadding>
															{item.children.map(
																(child: any) =>
																	isAuthRoute(get(userAccess, 'userProfile.featureList', []), child.auth) && (
																		<NavLink
																			key={child.id}
																			to={child.path ? child.path : '/'}
																			className={({ isActive }) => (isActive ? 'link-active' : 'link')}
																		>
																			<Tooltip title={t(child.label.toString())} placement="right">
																				<ListItem
																					sx={{
																						pl: sideNavBarOpen ? 4 : 3
																					}}
																				>
																					<ListItemIcon sx={{ minWidth: '32px' }}>
																						<child.icon />
																					</ListItemIcon>
																					<ListItemText
																						primary={t(child.label.toString())}
																						primaryTypographyProps={{ fontSize: '14px' }}
																					/>
																				</ListItem>
																			</Tooltip>
																		</NavLink>
																	)
															)}
														</List>
													</Collapse>
												)}
											</div>
										)
								)}
							</List>
							<List
								sx={{ width: '100%', maxWidth: drawerWidth, paddingInline: '12px', height: '20%' }}
								subheader={
									<>
										{sideNavBarOpen ? (
											<ListSubheader
												component="div"
												sx={{
													fontSize: '12px',
													color: '#CECECE',
													fontWeight: 500,
													textTransform: 'uppercase'
												}}
											>
												support
											</ListSubheader>
										) : (
											<Divider />
										)}
									</>
								}
							>
								{isAuthRoute(get(userAccess, 'userProfile.featureList', []), [ACCESS_TAGS.GET_ALL_NOTIFICATIONS]) && (
									<ListItemButton
										onClick={() => handleNotifications()}
										sx={{ paddingLeft: sideNavBarOpen ? '16px' : '8px' }}
									>
										<ListItemIcon sx={{ minWidth: '32px' }}>
											<NotificationsIcon fontSize={'small'} sx={{ color: '#49454F' }} />
										</ListItemIcon>
										{sideNavBarOpen ? (
											<ListItemText
												primary={t('notifications')}
												primaryTypographyProps={{ fontSize: '14px', color: '#49454F' }}
											/>
										) : null}
									</ListItemButton>
								)}
								{isAuthRoute(get(userAccess, 'userProfile.featureList', []), [ACCESS_TAGS.TRIGGER_ETL]) && (
									<ListItemButton
										onClick={() => handleOperations()}
										sx={{ paddingLeft: sideNavBarOpen ? '16px' : '8px' }}
									>
										<ListItemIcon sx={{ minWidth: '32px' }}>
											<WorkIcon fontSize={'small'} sx={{ color: '#49454F' }} />
										</ListItemIcon>
										{sideNavBarOpen ? (
											<ListItemText
												primary={t('operations')}
												primaryTypographyProps={{ fontSize: '14px', color: '#49454F' }}
											/>
										) : null}
									</ListItemButton>
								)}
							</List>
						</Box>
						<div style={{ bottom: 5, width: '100%' }}>
							<span style={{ right: '5px', zIndex: 4 }}>
								<IconButton onClick={sideNavBarOpen ? handleSideNavClose : handleSideNavOpen}>
									{!sideNavBarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
								</IconButton>
							</span>
							<List>
								<ListItem
									sx={{
										minHeight: 48,
										justifyContent: sideNavBarOpen ? 'initial' : 'center',
										px: 2.5,
										bottom: 0
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: sideNavBarOpen ? 3 : 'auto',
											justifyContent: 'center'
										}}
									>
										<AccountCircleIcon fontSize="medium" />
									</ListItemIcon>
									<div style={{ display: 'flex', width: 'calc(100% - 80px)' }}>
										<ListItemText
											primary={get(currentAccount, 'idTokenClaims.name')}
											secondary={get(currentAccount, 'idTokenClaims.email')}
											sx={{ opacity: sideNavBarOpen ? 1 : 0 }}
											primaryTypographyProps={{
												noWrap: true
											}}
											secondaryTypographyProps={{ noWrap: true }}
										/>
										<ListItemText
											primary={
												<IconButton onClick={handleMoreInfo}>
													<MoreVertIcon />
												</IconButton>
											}
											sx={{ opacity: sideNavBarOpen ? 1 : 0, position: 'absolute', right: 5, top: '15%' }}
											primaryTypographyProps={{
												noWrap: true
											}}
										/>
										<UserProfilePopOver handleClose={handleClose} anchorEl={profileAnchor} />
									</div>
								</ListItem>
							</List>
						</div>
					</Drawer>
					<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
						{children}
					</Box>
				</Box>
			</>
		);
	}
}
