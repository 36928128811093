import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { get } from 'lodash';

interface IResponsiveDialog {
	open: boolean;
	title: string;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xs';
	fullWidth?: boolean;
	handleClose: () => void;
	content: React.ReactNode;
	actions: React.ReactNode;
	contentHeight?: number;
}

export default function ResponsiveDialog(props: IResponsiveDialog) {
	const dialogContentRef = useRef<HTMLDivElement>(null);
	const [scrollContentHeight, setScrollContentHeight] = useState<number>(0);
	const { title, content, handleClose, open, maxWidth = 'md', fullWidth = true, actions, contentHeight = 450 } = props;

	useEffect(() => {
		let firstChild = get(dialogContentRef, 'current.firstChild', null);
		if (firstChild !== null) {
			setScrollContentHeight((firstChild as HTMLDivElement).clientHeight + 10);
		} else {
			setScrollContentHeight(0);
		}
	});

	return (
		<React.Fragment>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: { borderRadius: '15px', width: '774px' }
				}}
			>
				<DialogTitle
					sx={{ fontSize: 24, fontWeight: 500, paddingInline: '36px', paddingTop: '36px', textTransform: 'capitalize' }}
				>
					{title}
					<IconButton onClick={handleClose} sx={{ float: 'right' }}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{
						height: contentHeight < scrollContentHeight ? scrollContentHeight : contentHeight,
						padding: '0px 36px 0px'
					}}
					ref={dialogContentRef}
				>
					{content}
				</DialogContent>
				<DialogActions sx={{ padding: '32px' }}>{actions}</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
