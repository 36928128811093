import { TableCell, TableHead, TableRow } from '@mui/material';
import { IColumn } from './DataTable.type';

interface ITableHeader {
	columns: IColumn[];
}
export default function DataTableHeader(props: ITableHeader) {
	const { columns } = props;

	return (
		<TableHead
			sx={{
				'& th': {
					backgroundColor: '#F9F9F9',
					color: '#949494',
					fontWeight: 400,
					fontSize: 14
				},
				'& th:first-of-type': {
					borderRadius: '6px 0 0 6px'
				},
				'& th:last-child': {
					borderRadius: '0 6px 6px 0'
				}
			}}
		>
			<TableRow>
				{columns.map((column) => (
					<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth }}
						sx={{ padding: '0px 10px', borderBottomWidth: 0 }}
					>
						{column.label}
					</TableCell>
				))}
				<TableCell align="center" sx={{ minWidth: '140px', padding: '12px 16px', borderBottomWidth: 0 }}>
					Actions
				</TableCell>
			</TableRow>
		</TableHead>
	);
}
