import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import { getEndpointPromise, insertEndpointPromise } from 'services/apiServices';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import CPDatePicker from 'components/atoms/CPDatePicker';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CPTextField from 'components/atoms/CPTextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CPButton from 'components/atoms/CPButton';
import CPConfirmationDialog from 'components/atoms/CPConfirmationDialog';
// import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
// import { IOptionItem } from 'interfaces/optionItem.interface';
import { get } from 'lodash';
import { ILabels } from 'types/label.type';
import axiosInstance from 'configs/axiosConfig';

const Operations = () => {
	const { t } = useTranslation();

	const [mappingSchedulerLoading, setMappingSchedulerLoading] = useState<boolean>(false);
	const [etlProcessLoading, setEtlProcessLoading] = useState<boolean>(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [etlProcessConfirmation, setEtlProcessConfirmation] = useState<boolean>(false);
	// const [runAccountProcessLoading, setRunAccountProcessLoading] = useState<boolean>(false);
	// const [clients, setClients] = useState<IOptionItem[]>([]);
	// const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const [isSavingNotificationEmails, setIsSavingNotificationEmails] = useState<boolean>(false);
	const [isLoadingNotificationEmailList, setIsLoadingNotificationEmailList] = useState<boolean>(false);

	const etlValidationSchema = yup.object({
		startDate: yup
			.date()
			.default(() => new Date())
			.required(t('value_required')),
		endDate: yup
			.date()
			.when('startDate', (startDate, schema) => startDate && schema.min(startDate, t('end_date_cannot_be')))
			.required(t('value_required'))
	});

	const notificationEmailvalidationSchema = yup.object({
		email: yup
			.string()
			.max(100, t('cannot_exceed_100'))
			.email(t('invalid_email'))
			.matches(/^[A-Za-z0-9._%+-]{0,64}@[A-Za-z0-9.-]+.[A-Za-z]+$/, t('invalid_email'))
			.trim(),
		recipientEmail: yup
			.string()
			.max(100, t('cannot_exceed_100'))
			.email(t('invalid_email'))
			.matches(/^[A-Za-z0-9._%+-]{0,64}@[A-Za-z0-9.-]+.[A-Za-z]+$/, t('invalid_email'))
			.trim()
	});

	// const runAccountMappingSchema = yup.object({});

	const etlForm = useFormik({
		initialValues: {
			startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
			endDate: new Date()
		},
		validationSchema: etlValidationSchema,
		onSubmit: () => {
			setEtlProcessConfirmation(true);
		}
	});

	const notificationEmaiForm = useFormik({
		initialValues: {
			email: '',
			recipientEmail: '',
			recipientEmailList: []
		},
		validationSchema: notificationEmailvalidationSchema,
		onSubmit: () => {
			handleAdd();
		}
	});
	// const AccountMappingProcessForm = useFormik({
	// 	initialValues: {
	// 		clientId: null
	// 	},
	// 	validationSchema: runAccountMappingSchema,
	// 	onSubmit: () => {
	// 		handleRunAccountMappingProcess();
	// 	}
	// });

	// useEffect(() => {
	// 	getClients();
	// }, []);

	useEffect(() => {
		getNotificationEmails();
	}, []);

	const getNotificationEmails = async () => {
		try {
			notificationEmaiForm.setFieldValue('recipientEmailList', []);
			setIsLoadingNotificationEmailList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/operations/email/all`);
			notificationEmaiForm.setFieldValue('recipientEmailList', get(response, 'data.emailList') ?? '');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingNotificationEmailList(false);
		}
	};

	// const getClients = async () => {
	// 	try {
	// 		setIsLoadingClientList(true);
	// 		const response = await getEndpointPromise<ILabels>(`/entitymanager/client-account/list`);
	// 		var clients: IOptionItem[] = [];
	// 		response.data.userAccountList?.forEach((value: any) => {
	// 			clients.push({ value: value.id, label: value.clientName });
	// 		});
	// 		let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
	// 		setClients(sortedClients);
	// 	} catch (error) {
	// 		let message = getReadableError(error);
	// 		const key = enqueueSnackbar(
	// 			<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
	// 		);
	// 	} finally {
	// 		setIsLoadingClientList(false);
	// 	}
	// };

	const handleRunMappingScheduler = async () => {
		try {
			setMappingSchedulerLoading(true);
			let scheduleData = await insertEndpointPromise(`/entitymanager/scheduler/runJob`, {
				jobName: 'QueryMappingJob',
				jobGroup: 'queryMapping'
			});

			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={scheduleData.data.schedulerStatus}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setMappingSchedulerLoading(false);
		}
	};

	const handleRunETLProcess = async () => {
		try {
			setEtlProcessConfirmation(false);
			setEtlProcessLoading(true);
			let scheduleData = await insertEndpointPromise(`/entitymanager/etl/trigger`, {
				startDate: etlForm.values.startDate,
				endDate: etlForm.values.endDate
			});
			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={scheduleData.data}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setEtlProcessLoading(false);
		}
	};

	// const handleRunAccountMappingProcess = async () => {
	// 	try {
	// 		setRunAccountProcessLoading(true);
	// 		let scheduleData = await insertEndpointPromise(`/entitymanager/base-transaction/map-account-ids`, {
	// 			accountId: AccountMappingProcessForm.values.clientId
	// 		});
	// 		const key = enqueueSnackbar(
	// 			<CPAlert
	// 				title={t('successful')}
	// 				message={scheduleData.data}
	// 				severity={'success'}
	// 				onClose={() => closeSnackbar(key)}
	// 			/>
	// 		);
	// 	} catch (error) {
	// 		let message = getReadableError(error);
	// 		const key = enqueueSnackbar(
	// 			<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
	// 		);
	// 	} finally {
	// 		setRunAccountProcessLoading(false);
	// 	}
	// };

	const saveNotificationEmails = async (email: string): Promise<boolean> => {
		try {
			setIsSavingNotificationEmails(true);
			await insertEndpointPromise(`/entitymanager/operations/email`, {
				email: [email]
			});
			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={t('successfully_added')}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			return true;
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
			return false;
		} finally {
			setIsSavingNotificationEmails(false);
		}
	};

	const removeNotificationEmail = async (email: string): Promise<boolean> => {
		setIsSavingNotificationEmails(true);
		let request = axiosInstance.delete(`${process.env.REACT_APP_BASE_API_URL}/entitymanager//operations/email/delete`, {
			data: { email: email }
		});
		return request
			.then(function () {
				const key = enqueueSnackbar(
					<CPAlert
						title={t('successful')}
						message={t('successfully_removed')}
						severity={'success'}
						onClose={() => closeSnackbar(key)}
					/>
				);
				return true;
			})
			.catch((error) => {
				let message = getReadableError(error);
				let key = enqueueSnackbar(
					<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
				);
				return false;
			})
			.finally(() => {
				setIsSavingNotificationEmails(false);
			});
	};

	const handleAdd = () => {
		let newEmailList: string[] = ([] = notificationEmaiForm.values.recipientEmailList);
		let newEmail = (notificationEmaiForm.values.recipientEmail ?? '').trim();
		if (newEmail !== undefined && newEmail !== '' && !newEmailList.includes(newEmail)) {
			let result = saveNotificationEmails(newEmail);
			result.then((isSuccess) => {
				if (isSuccess) {
					newEmailList.push(newEmail);
					notificationEmaiForm.setFieldValue('recipientEmail', '');
					notificationEmaiForm.setFieldValue('recipientEmailList', newEmailList);
				}
			});
		}
	};

	const handleRecipientEmailRemoveClick = (email: any) => {
		let newEmailList: any[] = notificationEmaiForm.values.recipientEmailList;
		const index = newEmailList.indexOf(email);
		if (index > -1) {
			let result = removeNotificationEmail(email);
			result.then((isSuccess) => {
				if (isSuccess) {
					newEmailList.splice(index, 1);
					notificationEmaiForm.setFieldValue('recipientEmailList', newEmailList);
				}
			});
		}
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('operations')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }} id="mapping-scheduler">
					<Grid container sx={{ padding: '24px 0px !important' }}>
						<Grid item md={6} xs={12} display="flex" alignItems={'center'}>
							<Typography
								variant="info1"
								sx={{ alignSelf: 'left', fontSize: '14px', lineHeight: '34px', fontWeight: '400' }}
							>
								{t('run_mapping_scheduler')}
							</Typography>
						</Grid>
						<Grid item md={6} xs={12} display="flex" justifyContent={'flex-end'} alignItems={'center'}>
							<CPLoadingButton
								label={t('run')}
								variant={'contained'}
								style={{ width: '280px', borderRadius: '32px' }}
								loading={mappingSchedulerLoading}
								onClick={handleRunMappingScheduler}
								disabled={mappingSchedulerLoading}
							/>
						</Grid>
					</Grid>
				</div>
			</main>
			<main className={styles.main} style={{ marginTop: '16px' }}>
				<Grid container spacing={1} sx={{ padding: '10px 0px 24px 0px' }}>
					<Grid item md={12} xs={12} sx={{ paddingTop: 0, marginBottom: '15px' }}>
						<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '34px' }}>
							{t('run_etl_process')}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<CPDatePicker
							name="startDate"
							label={t('start_date')}
							handleChange={(date) => {
								etlForm.setFieldValue('startDate', date);
							}}
							size="small"
							fullWidth
							value={etlForm.values.startDate}
							onBlur={etlForm.handleBlur}
							error={etlForm.touched.startDate && etlForm.errors.startDate ? true : false}
							helperText={etlForm.touched.startDate ? etlForm.errors.startDate : ''}
							maxDate={etlForm.values.endDate}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<CPDatePicker
							name="endDate"
							label={t('end_date')}
							handleChange={(date) => {
								etlForm.setFieldValue('endDate', date);
							}}
							size="small"
							fullWidth
							minDate={etlForm.values.startDate}
							value={etlForm.values.endDate}
							onBlur={etlForm.handleBlur}
							error={etlForm.touched.endDate && etlForm.errors.endDate ? true : false}
							helperText={etlForm.touched.endDate ? etlForm.errors.endDate : ''}
						/>
					</Grid>

					<Grid item md={6} xs={12} display="flex" justifyContent={'flex-end'}>
						<CPLoadingButton
							label={t('run')}
							variant={'contained'}
							style={{ width: '280px', borderRadius: '32px' }}
							loading={etlProcessLoading}
							onClick={etlForm.submitForm}
							disabled={etlProcessLoading}
						/>
					</Grid>
				</Grid>
				{/* Reolve confirmation dialog */}
				<CPConfirmationDialog
					open={etlProcessConfirmation}
					title={t('etl_process_confirmation_title')}
					content={<>{t('etl_process_confirmation')}</>}
					buttonOptions={
						<>
							<CPButton
								style={{ minWidth: '150px' }}
								label={t('cancel')}
								onClick={() => {
									setEtlProcessConfirmation(false);
								}}
								disabled={etlProcessLoading}
							/>
							<CPLoadingButton
								loading={etlProcessLoading}
								style={{ minWidth: '150px' }}
								label={t('run')}
								variant="contained"
								color="error"
								onClick={() => {
									handleRunETLProcess();
								}}
							/>
						</>
					}
				/>
			</main>
			{/* Run Account Mapping Process */}
			{/* <main className={styles.main} style={{ marginTop: '16px' }}>
				<Grid container spacing={1} sx={{ padding: '10px 0px 24px 0px' }}>
					<Grid item md={12} xs={12} sx={{ paddingTop: 0, marginBottom: '15px' }}>
						<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '34px' }}>
							{t('run_account_mapping_process')}
						</Typography>
					</Grid>

					<Grid item md={6} xs={12}>
						<CPSingleSelectAutoCompleteDropDown
							name="clientId"
							size="small"
							options={clients}
							label={t('client_name')}
							loading={isLoadingClientList}
							disableClearable
							onBlur={AccountMappingProcessForm.handleBlur}
							setFieldValue={AccountMappingProcessForm.setFieldValue}
							error={
								AccountMappingProcessForm.touched.clientId && AccountMappingProcessForm.errors.clientId ? true : false
							}
							helperText={AccountMappingProcessForm.touched.clientId ? AccountMappingProcessForm.errors.clientId : ''}
							value={get(
								[...clients].filter((e) => e.value === AccountMappingProcessForm.values.clientId),
								'0',
								null
							)}
						/>
					</Grid>

					<Grid item md={6} xs={12} display="flex" justifyContent={'flex-end'}>
						<CPLoadingButton
							label={t('run')}
							variant={'contained'}
							style={{ width: '280px', borderRadius: '32px' }}
							loading={runAccountProcessLoading}
							onClick={AccountMappingProcessForm.submitForm}
							disabled={
								runAccountProcessLoading ||
								AccountMappingProcessForm.values.clientId === null ||
								AccountMappingProcessForm.values.clientId === undefined ||
								AccountMappingProcessForm.values.clientId === ''
							}
						/>
					</Grid>
				</Grid>
			</main> */}
			<main className={styles.main} style={{ marginTop: '16px' }}>
				<Grid container spacing={3} sx={{ paddingTop: '8px' }}>
					<Grid item xs={12} sx={{ marginTop: '0px' }}>
						<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '34px' }}>
							{t('company_notification_emails')}
						</Typography>
					</Grid>

					{notificationEmaiForm.values.recipientEmailList.length > 0 ? (
						<Grid item xs={6}>
							<Stack spacing={3} direction="column">
								{notificationEmaiForm.values.recipientEmailList.map((email) => (
									<CPTextField
										label={t('email')}
										fullWidth
										size="small"
										name={email}
										value={email}
										key={email}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														disabled={isSavingNotificationEmails || isLoadingNotificationEmailList}
														edge="end"
														style={{ color: 'red' }}
														onClick={() => handleRecipientEmailRemoveClick(email)}
													>
														<RemoveCircleIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								))}
							</Stack>
						</Grid>
					) : null}
					{/* </Grid> */}
					<Grid item xs={12}>
						<Grid container spacing={3} sx={{ paddingTop: '0px' }}>
							<Grid item xs={6}>
								<CPTextField
									label={t('email')}
									name="recipientEmail"
									fullWidth
									size="small"
									onBlur={notificationEmaiForm.handleBlur}
									handleChange={notificationEmaiForm.handleChange}
									value={notificationEmaiForm.values.recipientEmail}
									error={notificationEmaiForm.errors.recipientEmail ? true : false}
									helperText={
										notificationEmaiForm.errors.recipientEmail ? notificationEmaiForm.errors.recipientEmail : ''
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<div style={{ display: 'flex' }}>
									<CPLoadingButton
										label={<AddIcon />}
										onClick={handleAdd}
										variant="contained"
										loading={isSavingNotificationEmails || isLoadingNotificationEmailList}
										style={{ minWidth: '40px', width: '40px', height: '40px', marginLeft: -10, flex: 'none' }}
										disabled={
											notificationEmaiForm.errors.recipientEmail ||
											(notificationEmaiForm.values.recipientEmail?.length ?? 0) === 0
												? true
												: false
										}
									/>
									<Typography variant="bodyDisable" style={{ marginLeft: 10, alignSelf: 'center' }}>
										{t('add_more')}
									</Typography>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ paddingBottom: '20px' }}>
						{/* <CPLoadingButton
							label={t('save')}
							variant={'contained'}
							style={{ width: '280px', borderRadius: '32px' }}
							onClick={saveNotificationEmails}
							loading={isSavingNotificationEmails || isLoadingNotificationEmailList}
							disabled={!notificationEmaiForm.dirty}
							// disabled={
							// 	!(
							// 		notificationEmaiForm.isValid &&
							// 		notificationEmaiForm.dirty &&
							// 		((notificationEmaiForm.errors.recipientEmail === undefined &&
							// 			(notificationEmaiForm.values.recipientEmail?.length ?? 0) > 0) ||
							// 			notificationEmaiForm.errors.recipientEmail === undefined)
							// 	)
							// }
						/> */}
					</Grid>
				</Grid>
			</main>
		</div>
	);
};

export default Operations;
