import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CPTextField from 'components/atoms/CPTextField';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { insertEndpointPromise } from 'services/apiServices';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
	const { t } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isSavingPassword, setIsSavingPassword] = useState<boolean>(false);
	const navigate = useNavigate();

	const validationSchema = yup.object({
		newPassword: yup
			.string()
			.required(t('value_required'))
			.max(100, t('cannot_exceed_100'))
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t('invalid_password'))
			.trim(),
		passwordConfirm: yup
			.string()
			.required(t('value_required'))
			.oneOf([yup.ref('newPassword'), null], t('passwords_must_match'))
	});

	const ChangePasswordForm = useFormik({
		initialValues: {
			newPassword: '',
			passwordConfirm: ''
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: () => {
			handleSave();
		}
	});

	const handleSave = async () => {
		try {
			setIsSavingPassword(true);
			await insertEndpointPromise(`/entitymanager/user/change-password`, {
				newPassword: ChangePasswordForm.values.newPassword
			});

			const key = enqueueSnackbar(
				<CPAlert
					title={t('successfullySaved')}
					message={t('password_changed_successfully')}
					severity={'info'}
					onClose={() => closeSnackbar(key)}
				/>
			);
			navigate('/');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsSavingPassword(false);
			ChangePasswordForm.resetForm();
		}
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('change_password')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }}>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ padding: '24px 28px' }}>
							<Grid container>
								<Grid item xs={12} sx={{ marginTop: '25px', marginBottom: '25px' }}>
									<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '16.5px' }}>
										{t('change_password')}
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ marginBottom: '25px' }}>
									<Grid container>
										<Grid item xs={12} md={3}>
											<CPTextField
												label={'Password'}
												type={'password'}
												name="newPassword"
												fullWidth
												onBlur={ChangePasswordForm.handleBlur}
												handleChange={ChangePasswordForm.handleChange}
												error={
													ChangePasswordForm.touched.newPassword && ChangePasswordForm.errors.newPassword ? true : false
												}
												helperText={ChangePasswordForm.touched.newPassword ? ChangePasswordForm.errors.newPassword : ''}
												size="small"
												value={ChangePasswordForm.values.newPassword}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sx={{ marginBottom: '25px' }}>
									<Grid container>
										<Grid item xs={12} md={3}>
											<CPTextField
												label={'Confirm'}
												type={'password'}
												fullWidth
												name="passwordConfirm"
												onBlur={ChangePasswordForm.handleBlur}
												handleChange={ChangePasswordForm.handleChange}
												error={
													ChangePasswordForm.touched.passwordConfirm && ChangePasswordForm.errors.passwordConfirm
														? true
														: false
												}
												helperText={
													ChangePasswordForm.touched.passwordConfirm ? ChangePasswordForm.errors.passwordConfirm : ''
												}
												size="small"
												value={ChangePasswordForm.values.passwordConfirm}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={6} sx={{ marginTop: '50px' }} display="flex" justifyContent={'flex-end'}>
									<CPLoadingButton
										onClick={ChangePasswordForm.submitForm}
										style={{ minWidth: '150px' }}
										label={t('save')}
										loading={isSavingPassword}
										variant="contained"
										disabled={!(ChangePasswordForm.isValid && ChangePasswordForm?.dirty) || !ChangePasswordForm?.dirty}
									/>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default ChangePassword;
