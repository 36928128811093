import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CPTextField from 'components/atoms/CPTextField';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatColumnValue } from 'utils/commonUtil';

interface ISummaryReportContainer {
	incrementalReport?: any;
	searchTestReults?: any;
	userInputParameters: any;
	setUserInputParameters: any;
}
const IncrementalTestResultsTableOne = (props: ISummaryReportContainer) => {
	const { t } = useTranslation();
	const { incrementalReport, userInputParameters, setUserInputParameters } = props;

	const columns: IColumn[] = [
		{
			id: 'previous-results',
			label: t('previous_test_results'),
			align: 'left',
			style: { borderLeft: 0 }
		},
		{
			id: 'value',
			label: ' ',
			align: 'right',
			type: 'currency'
		}
	];

	const rows = [
		{ key: 'baseSpendControlGeo', type: 'input', format: 'currency' },
		{ key: 'totalSpendTestGeo', type: 'input', format: 'currency' },
		{ key: 'incrementalSpendTestGeo', type: 'readOnly', format: 'currency' },
		{ key: 'totalSalesControlGeo', type: 'input', format: 'number' },
		{ key: 'totalSalesTestGeo', type: 'input', format: 'currency' },
		{ key: 'incrementalSalesObservedTestGeo', type: 'readOnly', format: 'currency' },
		{ key: 'incrementalRoasTestGeo', type: 'readOnly', format: 'number' },
		{ key: 'aov', type: 'input', format: 'number' },
		{ key: 'sitewideCr', type: 'input', format: 'percentage' }
	];

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '20px' }} elevation={0}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							{columns.map((column: IColumn) => (
								<TableCell sx={{ padding: 0, fontSize: '13px' }} key={column.id}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.key}>
								<TableCell sx={{ padding: 0, paddingLeft: 2, fontSize: '12px' }}>{t(row.key)}</TableCell>
								<TableCell sx={{ padding: 0, paddingRight: '300px' }} align="center">
									{row.type === 'input' ? (
										<CPTextField
											key={row.key}
											inputProps={{
												style: {
													textAlign: 'center',
													fontSize: '12px'
												}
											}}
											size="small"
											type="number"
											value={userInputParameters[row.key]}
											name={''}
											handleChange={(e: any) => {
												let value = e.target.value ? Number(e.target.value) : e.target.value;
												let userInputParametersTemp = userInputParameters;
												userInputParametersTemp[row.key] = value;
												setUserInputParameters({ ...userInputParametersTemp });
											}}
										/>
									) : (
										<Typography sx={{ paddingRight: '15px', fontSize: '12px' }}>
											{incrementalReport && !_.isNil(incrementalReport[row.key]) && incrementalReport[row.key] != 0
												? formatColumnValue(row.format, incrementalReport[row.key] ?? 0, true, false)
												: 0}
										</Typography>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default IncrementalTestResultsTableOne;
