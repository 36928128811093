import type { Transition } from "history";
import { useCallback, useContext } from "react";
import { useBlocker } from "./useBocker";
import { Context } from "./context";

export function usePrompt(when = true) {
  const showPrompt = useContext(Context);

  const blocker = useCallback(
    async (tx: Transition) => {
      try {
        await showPrompt();
        tx.retry();
      } catch (e) {
        // Do nothing
      }
    },
    [showPrompt]
  );

  return useBlocker(blocker, when);
}
