import { AuthError, EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { Slide, ThemeProvider } from '@mui/material';
import MsalErrorCode from 'common/enums/msalErrorCode';
import Layout from 'components/templates/Layout';
import { forgotPasswordRequest, msalConfig } from 'configs/azureConfig';
import { theme } from 'configs/theme';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoutes from './routes';
import 'styles/globals.css';
import CPSnackbar from 'components/atoms/CPSnackbar';
import { PromtContext } from 'hooks/Prompt';
import RequestInterceptor from 'components/templates/Layout/RequestInterceptor';

function App() {
	const msalInstance = new PublicClientApplication(msalConfig);
	useEffect(() => {
		const callbackId = msalInstance.addEventCallback((event) => {
			if (event.eventType === EventType.LOGIN_FAILURE) {
				if (event.error && (event.error as AuthError).errorMessage.indexOf(MsalErrorCode.FORGOTTEN_PASSWORD) > -1) {
					msalInstance.loginRedirect(forgotPasswordRequest);
				} else if (
					event.error &&
					(event.error as AuthError).errorMessage.indexOf(MsalErrorCode.CODE_VERIFIER_NOT_MATCH) > -1
				) {
					window.location.reload();
				}
			}
		});

		return () => {
			if (callbackId) {
				msalInstance.removeEventCallback(callbackId);
			}
		};
	}, []);

	return (
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<ThemeProvider theme={theme}>
					<SnackbarProvider
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						classes={{ containerRoot: 'SnackbarProviderRoot' }}
						TransitionComponent={Slide}
						maxSnack={4}
						content={(key, message) => <CPSnackbar key={key} content={message} />}
					>
						<BrowserRouter>
							<PromtContext>
								<Layout>
									<RequestInterceptor>
										<AppRoutes />
									</RequestInterceptor>
								</Layout>
							</PromtContext>
						</BrowserRouter>
					</SnackbarProvider>
				</ThemeProvider>
			</MsalAuthenticationTemplate>
		</MsalProvider>
	);
}

export default App;
