import { DEFAULT_DATE_FORMAT } from 'common/constants/applicationConstants';
import { KeyBoardKeys } from 'common/enums/keybordKeys.enum';
import { format } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import { IOptionItem } from 'interfaces/optionItem.interface';
import _ from 'lodash';
import { APP_ROUTES } from 'routes';

export const isAuthRoute = (accessList: string[], authList: string[]) => {
	return authList.some((value) => accessList.includes(value));
};

export const isAuthOperation = (accessList: string[], operationTag: string) => {
	return accessList.includes(operationTag);
};

export const getDownloadFileName = (headerValue: string) => {
	let filename = '';
	if (headerValue && headerValue.indexOf('attachment') !== -1) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		var matches = filenameRegex.exec(headerValue);
		if (matches !== null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
};

export const formatCurrency = (
	amount: number,
	trimDecimal: boolean = false,
	showCurrency: boolean = true,
	languageCode: string = 'en',
	countryCode: string = 'us',
	currencyCode: string = 'USD'
) => {
	return Intl.NumberFormat(`${languageCode}-${countryCode}`, {
		currency: currencyCode,
		...(showCurrency && { style: 'currency' }),
		...(trimDecimal && { maximumSignificantDigits: amount.toString().length })
	}).format(amount);
};

export const preventKeyboardEventPressE = (evt: any) => evt.key === 'e' && evt.preventDefault();

export const AllowOnlyNumberKeys = (e: any) => {
	const re = /[0-9]+/;
	if (
		re.test(e.key) ||
		e.key == KeyBoardKeys.Backspace ||
		e.key == KeyBoardKeys.Dot ||
		e.key == KeyBoardKeys.ArrowLeft ||
		e.key == KeyBoardKeys.ArrowRight ||
		e.key == KeyBoardKeys.Tab
	) {
		return true;
	}
	e.preventDefault();
};

export const getRanks = () => {
	var ranks: IOptionItem[] = [];

	for (let i = 1; i <= 100; i++) {
		ranks.push({ label: i.toString(), value: i });
	}
	return ranks;
};

export const formatDate = (date: any) => {
	// Get the timezone from browser using native methods
	alert('ss');
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const dateTmp = Date.parse(date.toLocaleString());

	const localDate = convertToLocalTime(dateTmp, {
		timeZone: timezone
	});

	return format(localDate, DEFAULT_DATE_FORMAT);
};

export const getNotificationStatuses = () => {
	var statuses: IOptionItem[] = [];
	statuses.push({ label: 'All', value: 'ALL' });
	statuses.push({ label: 'Read', value: 'READ' });
	statuses.push({ label: 'Unread', value: 'UN_READ' });
	statuses.push({ label: 'Resolved', value: 'RESOLVED' });
	return statuses;
};

export const formatColumnValue = (
	type: string | undefined,
	value: any,
	trimDecimal: boolean = false,
	showCurrency: boolean = true
) => {
	if (type === 'currency' && value) return formatCurrency(value, trimDecimal, showCurrency);
	else if (type === 'percentage' && !_.isNil(value)) return `${value}%`;
	else if (value) return value.toString();
	else return '-';
};

export const limitDecimalValueInput = (input: string, maxDecimalValues: number) => {
	const decimalIndex = input.indexOf('.');
	const limitInput =
		input.indexOf('.') >= 0
			? input.substring(0, decimalIndex) + input.substring(decimalIndex, decimalIndex + maxDecimalValues)
			: input;
	return limitInput;
};

export const isValidRoute = (url: string) => {
	return APP_ROUTES.find((route) => route.path === url);
};
