import ACCESS_TAGS from 'common/constants/accessTags';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import TaskIcon from '@mui/icons-material/Task';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RemoveIcon from '@mui/icons-material/Remove';

export const APP_NAVIGATION = [
	{
		id: 'user-management',
		label: 'user_management',
		icon: PeopleOutlineIcon,
		auth: [ACCESS_TAGS.GET_ALL_USERS, ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT],
		children: [
			{
				id: 'user-management-accounts',
				label: 'accounts',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_ALL_USERS],
				path: '/user-management/accounts'
			},
			{
				id: 'user-management-clients',
				label: 'clients',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT],
				path: '/user-management/clients'
			}
		]
	},
	{
		id: 'configurations',
		label: 'configurations',
		icon: TaskIcon,
		auth: [
			ACCESS_TAGS.GET_ALL_CHANNELS,
			ACCESS_TAGS.GET_GLOBAL_QUERY,
			ACCESS_TAGS.GET_CUSTOM_QUERY,
			ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
		],
		children: [
			{
				id: 'channels',
				label: 'channels',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_ALL_CHANNELS],
				path: '/configurations/channels'
			},
			{
				id: 'global-query',
				label: 'global_query',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_GLOBAL_QUERY],
				path: '/configurations/global-queries'
			},
			{
				id: 'customized-queries',
				label: 'customized_queries',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_CUSTOM_QUERY],
				path: '/configurations/custom-queries'
			},
			{
				id: 'sp-attribution-entry',
				label: 'sp_attribution_entry',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA],
				path: '/configurations/sp-attribution-entry'
			},
			{
				id: 'incremental-schedule',
				label: 'incremental_schedule',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA],
				path: '/configurations/incremental-schedule'
			}
		]
	},
	{
		id: 'reports',
		label: 'reports',
		icon: QueryStatsIcon,
		auth: [
			ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT,
			ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT,
			ACCESS_TAGS.DAILY_FUNNEL_REPORT,
			ACCESS_TAGS.DAILY_CHANNEL_REPORT
		],
		children: [
			{
				id: 'single-analysis',
				label: 'single_analysis',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT],
				path: '/reports/attributed-sales'
			},
			{
				id: 'delta-analysis',
				label: 'delta_analysis',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT],
				path: '/reports/delta-analysis'
			},
			{
				id: 'funnel-report',
				label: 'funnel_report',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.DAILY_FUNNEL_REPORT],
				path: '/reports/daily-funnel-stats'
			},
			{
				id: 'channel-report',
				label: 'channel_report',
				icon: RemoveIcon,
				auth: [ACCESS_TAGS.DAILY_CHANNEL_REPORT],
				path: '/reports/daily-channel-stats'
			}
		]
	}
];
