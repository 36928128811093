import * as React from 'react';
import { Alert, AlertColor, AlertTitle, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
interface ICPAlert {
	message: string | React.ReactNode;
	severity: AlertColor;
	title: string | React.ReactNode;
	onClose: () => void | undefined;
	width?: string | number;
}

export default function CPAlert(props: ICPAlert) {
	const { message, severity, title, onClose, width = '534px' } = props;
	const theme = useTheme();
	return (
		<Alert
			severity={severity}
			sx={{ width: width }}
			onClose={onClose}
			iconMapping={{
				success: <CheckIcon sx={{ color: theme.palette.success.dark }} />,
				info: <InfoOutlinedIcon sx={{ color: theme.palette.info.dark }} />,
				error: <ErrorOutlineIcon sx={{ color: theme.palette.error.dark }} />
			}}
		>
			<AlertTitle>{title}</AlertTitle>
			{message}
		</Alert>
	);
}
