import { Button, TableSortLabel, Typography } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Loader from 'components/atoms/Loader';
import { theme } from 'configs/theme';
import { isNull } from 'lodash';
import get from 'lodash/get';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';
import _ from 'lodash';
import { SortOrder } from 'common/enums/sortOrder.enum';

type Order = SortOrder.ASC | SortOrder.DESC;

interface ICampaignData {
	tableHeader: ICampaignTableHeader;
	loading: boolean;
	rows: any[];
	onCreateAction?: (data: any) => void;
	onCreateActionDisableCondition?: (data: any) => boolean;
	defaultSortColumn: string;
	defaultSortOrder: Order;
}
export interface ICampaignTableHeader {
	groups?: IGroup[];
	columns: IColumn[];
}
export interface IGroup {
	id: string;
	label: string;
	colSpan: number;
}
export interface IColumn {
	id: string;
	label: string;
	minWidth?: number | string;
	align?: 'right' | 'left';
	leftConditionTrueIcon?: React.ReactNode;
	leftConditionFalseIcon?: React.ReactNode;
	leftIconCondition?: string;
	type?: 'currency';
}

export default function CampaignTable(props: ICampaignData) {
	const [separatorIndexes, setSeparatorIndexes] = useState<number[]>([]);
	const {
		tableHeader,
		loading = false,
		rows,
		onCreateAction,
		onCreateActionDisableCondition,
		defaultSortColumn,
		defaultSortOrder
	} = props;
	const { t } = useTranslation();
	const [order, setOrder] = useState<Order>(defaultSortOrder);
	const [orderBy, setOrderBy] = useState<string>(defaultSortColumn);

	// useEffects
	useEffect(() => {
		let groups = get(tableHeader, 'groups', []);
		let indexes: number[] = [];
		if (groups.length > 0) {
			let total = 0;
			groups.forEach((value) => {
				total = value.colSpan + total;
				indexes.push(total);
			});
		}
		setSeparatorIndexes(indexes);
	}, [tableHeader]);

	const createSortHandler = (property: string) => () => {
		const isAsc = orderBy === property && order === SortOrder.ASC;
		setOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);
		setOrderBy(property);
	};

	function sortRows<T>(array: readonly T[]) {
		return _.orderBy(array, [orderBy], [order]);
	}
	return (
		<TableContainer>
			<Table stickyHeader aria-label="a dense table" sx={{ tableLayout: 'unset' }}>
				<TableHead>
					{tableHeader.groups ? (
						<TableRow
							sx={
								tableHeader.groups.length > 1
									? {
											'& th:first-of-type': {
												borderRadius: '6px 0 0 0'
											},
											'& th:last-child': {
												borderRadius: '0 6px 0 0'
											}
									  }
									: {
											'& th:first-of-type': {
												borderRadius: '6px 6px 0 0'
											}
									  }
							}
						>
							{tableHeader.groups.map((row, index) => {
								return (
									<TableCell
										key={index}
										sx={{
											backgroundColor: '#F9F9F9',
											borderRight: 0,
											padding: '7px 10px',
											borderBottomWidth: 0,
											borderLeft: index > 0 ? '1px solid #F0F0F0' : 'none',
											fontWeight: 500,
											fontSize: '16px',
											color: '#949494'
										}}
										align="left"
										colSpan={row.colSpan}
									>
										{row.label}
									</TableCell>
								);
							})}
						</TableRow>
					) : null}
					<TableRow>
						{tableHeader.columns.map((row, index) => {
							return (
								<TableCell
									sortDirection={orderBy === row.id ? order : false}
									key={index}
									align={row.align}
									sx={{
										padding: '12px 10px',
										background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;',
										fontWeight: 600,
										fontSize: '12px',
										color: 'rgba(0, 0, 0, 0.5)',
										minWidth: row.minWidth,
										borderLeft: separatorIndexes.includes(index) ? '1px solid #F0F0F0' : 'none'
									}}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={orderBy === row.id ? order : SortOrder.ASC}
										onClick={createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</TableCell>
							);
						})}
						{/* Action */}

						<TableCell
							width={'122px'}
							align={'center'}
							sx={{
								width: '122px',
								padding: '12px 10px',
								background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;',
								fontWeight: 600,
								fontSize: '12px',
								color: 'rgba(0, 0, 0, 0.5)'
							}}
						>
							{t('action')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody sx={{ marginBottom: 10 }}>
					{loading && (
						<TableRow>
							<TableCell colSpan={tableHeader.columns.length + 1}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{!loading && rows && rows.length > 0 ? (
						<React.Fragment>
							{sortRows(rows).map((row: Object, index: number) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={index.toString()}>
										{tableHeader.columns.map((column, columnIndex) => {
											const value = get(row, column.id); //row[column.id as keyof typeof row];
											const leftConditionTrueIcon = get(column, 'leftConditionTrueIcon', null);
											const leftConditionFalseIcon = get(column, 'leftConditionFalseIcon', null);
											const leftIconCondition = get(row, column.leftIconCondition ?? '', false);
											let cellValue = '-';
											if (!isNull(value) && value) {
												cellValue = value.toString();
												if (column.type === 'currency') {
													let numberValue = Number(cellValue);
													if (!isNaN(numberValue)) {
														cellValue = formatCurrency(numberValue);
													}
												}
											}
											return (
												<TableCell
													key={columnIndex}
													align={column.align}
													sx={{
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														maxWidth: column.minWidth ? column.minWidth : 170,
														padding: '10px',
														borderLeft: separatorIndexes.includes(columnIndex) ? '1px solid #F0F0F0' : 'none'
													}}
												>
													<div style={{ display: 'flex' }}>
														{leftConditionTrueIcon ? (leftIconCondition ? leftConditionTrueIcon : null) : null}
														{leftConditionFalseIcon ? (!leftIconCondition ? leftConditionFalseIcon : null) : null}
														<Tooltip
															title={value !== undefined && value !== null ? cellValue : ''}
															placement="bottom-start"
														>
															<span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{cellValue}</span>
														</Tooltip>
													</div>
												</TableCell>
											);
										})}
										<TableCell align={'center'} sx={{ padding: '8px', width: '122px' }} width={'122px'}>
											{onCreateAction && (
												<Tooltip title={t('create')} placement="bottom-start">
													<span>
														<Button
															onClick={() => onCreateAction(row)}
															sx={{
																background: '#F4F2FF',
																boxShadow: '0px 2px 5px #ECECED',
																borderRadius: '4px',
																border: '1px solid #D6CFFD',
																color: theme.palette.primary.main,
																height: '24px',
																minWidth: '91px',
																fontSize: '12px',
																fontWeight: 600
															}}
															disabled={onCreateActionDisableCondition ? onCreateActionDisableCondition(row) : false}
														>
															{t('create')}
														</Button>
													</span>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</React.Fragment>
					) : (
						!loading && (
							<TableRow>
								<TableCell component="th" scope="row" colSpan={tableHeader.columns.length + 1}>
									<Typography
										sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', color: '#363636' }}
										align="center"
									>
										{t('no_records')}
									</Typography>
								</TableCell>
							</TableRow>
						)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
