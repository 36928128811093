import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserInfo } from 'types/userProfile.type';

interface IUserProfile {
	userProfile: IUserInfo;
}

const initialState: IUserProfile = { userProfile: {} };

export const userSlice = createSlice({
	name: 'userProfile',
	initialState: initialState,
	reducers: {
		setUserProfile: (state, action: PayloadAction<IUserInfo>) => {
			state.userProfile = action.payload;
		},
		setUserFullName(state, action: PayloadAction<string>) {
			state.userProfile.userFullName = action.payload;
		}
	}
});
export const { setUserProfile, setUserFullName } = userSlice.actions;

export default userSlice.reducer;
