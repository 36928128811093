import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CPTextField from 'components/atoms/CPTextField';
import CPButton from 'components/atoms/CPButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { get } from 'lodash';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { updateEndpointPromise } from 'services/apiServices';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { setUserFullName } from 'store/userSlice';

const MyProfile = () => {
	const { t } = useTranslation();
	const userProfile = useSelector((state: RootState) => state.userProfile);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false);
	const dispatch = useDispatch();
	// const [file, setFile] = useState(null);
	// const [fileDataURL, setFileDataURL] = useState<string>();
	// const imageMimeType = /image\/(png|jpg|jpeg)/i;
	const validationSchema = yup.object({
		userName: yup.string().required(t('value_required')).trim().nullable()
	});

	const ProfileForm = useFormik({
		initialValues: {
			userName: get(userProfile, 'userProfile.userFullName'),
			email: get(userProfile, 'userProfile.email')
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: () => {
			handleSave();
		}
	});

	// useEffect(() => {
	// 	let fileReader: any,
	// 		isCancel = false;
	// 	if (file) {
	// 		fileReader = new FileReader();
	// 		fileReader.onload = (e: any) => {
	// 			const { result } = e.target;
	// 			if (result && !isCancel) {
	// 				setFileDataURL(result);
	// 			}
	// 		};
	// 		fileReader.readAsDataURL(file);
	// 	}
	// 	return () => {
	// 		isCancel = true;
	// 		if (fileReader && fileReader.readyState === 1) {
	// 			fileReader.abort();
	// 		}
	// 	};
	// }, [file]);

	// useEffect(() => {
	// 	let email = get(userAccess, 'userProfile.email');
	// 	let userName = get(userAccess, 'userProfile.userFullName');
	// 	setProfileEmail(email);
	// 	ProfileForm.setFieldValue('userName', userName);
	// }, []);

	// const changeHandler = (e :any) => {
	// 	const file = e.target.files[0];
	// 	if (!file.type.match(imageMimeType)) {
	// 		return;
	// 	}
	// 	setFile(file);
	// };

	const handleSave = async () => {
		try {
			setIsSavingProfile(true);
			const response = await updateEndpointPromise(`/entitymanager/user/update`, {
				fullName: ProfileForm.values.userName,
				userObjectId: get(userProfile, 'userProfile.userObjectId'),
				profileImage: ''
			});
			dispatch(setUserFullName(get(response, 'data.userFullName')));

			const key = enqueueSnackbar(
				<CPAlert
					title={t('successful')}
					message={t('successfullySaved')}
					severity={'success'}
					onClose={() => closeSnackbar(key)}
				/>
			);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsSavingProfile(false);
			ProfileForm.resetForm();
		}
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={'My Profile'} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }}>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ padding: '24px 28px' }}>
							<Grid container>
								{/* <Grid item xs={12} sx={{ marginBottom: '18px' }}>
									<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '16.5px' }}>
										{t('Profile image')}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Avatar sx={{ width: 135, height: 135 }} src={fileDataURL} alt="preview" />
								</Grid>
								<Grid item xs={10} alignItems="center" display="flex" justifyContent={'left'}>
									<CPButton
										label={'Upload'}
										startIcon={<FileUploadIcon />}
										// onClick={handleDataExport}
										variant="contained"
										component="label"
										// loading={isExportingChannel}
									>
										<input hidden accept="image/*" multiple type="file" onChange={changeHandler} />
									</CPButton>
								</Grid> */}
								<Grid item xs={12} sx={{ marginTop: '25px', marginBottom: '25px' }}>
									<Typography variant="info1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '16.5px' }}>
										{'Info'}
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ marginBottom: '25px' }} display="flex" justifyContent={'left'}>
									<CPTextField
										label={'Name'}
										name="userName"
										onBlur={ProfileForm.handleBlur}
										handleChange={ProfileForm.handleChange}
										error={ProfileForm.touched.userName && ProfileForm.errors.userName ? true : false}
										helperText={ProfileForm.touched.userName ? ProfileForm.errors.userName : ''}
										size="small"
										value={ProfileForm.values.userName}
									/>
								</Grid>
								<Grid item xs={12} sx={{ marginBottom: '25px' }}>
									<CPTextField label={''} name="email" size="small" disabled={true} value={ProfileForm.values.email} />
								</Grid>
								<Grid item xs={6} md={6} sx={{ marginTop: '50px' }} display="flex" justifyContent={'flex-end'}>
									<CPButton
										onClick={ProfileForm.submitForm}
										style={{ minWidth: '150px' }}
										label={t('save')}
										variant="contained"
										disabled={!ProfileForm.isValid || !ProfileForm?.dirty || isSavingProfile}
									/>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default MyProfile;
