import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ChangeEvent, Fragment } from 'react';
import { Chip, CircularProgress, Tooltip } from '@mui/material';

interface ICPMultiSelectDropDown {
	onBlur?: (e: ChangeEvent) => void;
	id?: string;
	options: OptionItem[];
	label?: string;
	handleChange?: (event: any, newValue: any) => void;
	placeHolder?: string;
	name: string;
	error?: boolean | undefined;
	helperText?: string | any;
	size?: 'small' | 'medium';
	required?: boolean;
	value?: any;
	disabled?: boolean;
	loading?: boolean;
	showTooltip?: boolean | undefined;
	showTooltipMinimumLength?: number;
	readOnly?: boolean;
	limitTags?: number;
}

interface OptionItem {
	key: string;
	value: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CPMultiSelectDropDown(props: ICPMultiSelectDropDown) {
	const {
		label,
		options,
		placeHolder,
		id,
		handleChange,
		name,
		error,
		onBlur,
		helperText,
		size,
		required,
		value,
		loading,
		disabled,
		showTooltipMinimumLength = 15,
		showTooltip = true,
		readOnly,
		limitTags = 1
	} = props;
	const showToolTipForOption = (label: string) => {
		var toolTipValue = '';

		if (showTooltip && label !== undefined) {
			const tooltip = label.toString();
			if (tooltip.length > showTooltipMinimumLength) {
				toolTipValue = tooltip;
			}
		}
		return toolTipValue;
	};

	return (
		<Autocomplete
			limitTags={limitTags}
			readOnly={readOnly}
			disabled={disabled}
			multiple
			onChange={handleChange}
			id={id}
			onBlur={onBlur}
			size={size}
			options={options.sort((a, b) => Number(a.value.toLowerCase() > b.value.toLowerCase()))}
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) => option.key === value.key}
			getOptionLabel={(option) => option.value}
			value={value}
			renderTags={(value: readonly OptionItem[], getTagProps) =>
				value.map((option: OptionItem, index: number) => (
					<Tooltip key={option.key} title={showToolTipForOption(option.value)} placement="bottom-start">
						<Chip size={size} label={option.value} {...getTagProps({ index })} />
					</Tooltip>
				))
			}
			renderOption={(props, option, { selected }) => (
				<Tooltip key={option.key} title={showToolTipForOption(option.value)} placement="bottom-start">
					<li {...props}>
						<Checkbox
							id="{option.key}"
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							checked={selected}
							key={option.key}
						/>
						{option.value}
					</li>
				</Tooltip>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					size={size}
					name={name}
					label={label}
					error={error}
					helperText={helperText}
					placeholder={placeHolder}
					required={required}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? (
									<CircularProgress color="inherit" size={20} sx={{ position: 'absolute', right: '32px' }} />
								) : null}
								{params.InputProps.endAdornment}
							</Fragment>
						)
					}}
				/>
			)}
		/>
	);
}
