import { IconButton, InputAdornment } from '@mui/material';
import CPTextField from 'components/atoms/CPTextField';
import { theme } from 'configs/theme';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
interface IRemovableTextField {
	label: string;
	value?: string;
	name: string;
	size?: 'small' | 'medium';
	showRemoveIcon?: boolean;
	handleRemoveClick?: (value?: string) => void;
	disabled?: boolean;
	inputProps?: any;
}
const RemovableTextField = (props: IRemovableTextField) => {
	const { label, value, name, showRemoveIcon, handleRemoveClick, disabled, inputProps } = props;
	return (
		<CPTextField
			InputProps={
				!showRemoveIcon
					? {
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										edge="end"
										style={{ color: theme.palette.primary.delete }}
										onClick={() => handleRemoveClick?.(value)}
									>
										<RemoveCircleIcon />
									</IconButton>
								</InputAdornment>
							)
					  }
					: undefined
			}
			label={label}
			value={value}
			name={name}
			size={'small'}
			fullWidth
			disabled={disabled}
			inputProps={inputProps}
		/>
	);
};

export default RemovableTextField;
