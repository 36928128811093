import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DataTableHeader from './DataTableHeader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import Loader from 'components/atoms/Loader';
import { IDataTable } from './DataTable.type';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { theme } from 'configs/theme';

export default function DataTable(props: IDataTable) {
	const {
		onEditAction,
		onDeleteAction,
		onViewAction,
		rows,
		rowsPerPage,
		page,
		setPage,
		setRowsPerPage,
		columns,
		rowCount,
		loading = false
	} = props;
	const { t } = useTranslation();

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
					<DataTableHeader columns={columns} />
					<TableBody>
						{loading && (
							<TableRow>
								<TableCell colSpan={columns.length + 1}>
									<Loader />
								</TableCell>
							</TableRow>
						)}
						{!loading && rows && rows.length > 0 ? (
							<React.Fragment>
								{rows.map((row: Object, index: Number) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={index.toString()}>
											{columns.map((column) => {
												const value = row[column.id as keyof typeof row];
												return (
													<TableCell
														key={column.id}
														align={column.align}
														sx={{
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															maxWidth: column.minWidth ? column.minWidth : 170,
															padding: '10px'
														}}
													>
														<Tooltip
															title={value !== undefined && value !== null ? value.toString() : ''}
															placement="bottom-start"
														>
															<span>{value ? value.toString() : '-'}</span>
														</Tooltip>
													</TableCell>
												);
											})}
											<TableCell align={'center'} sx={{ padding: '10px' }}>
												{onViewAction && (
													<Tooltip title={t('view')} placement="bottom-start">
														<IconButton
															onClick={() => onViewAction(row)}
															sx={{
																backgroundColor: 'rgba(31, 132, 255, 0.11)',
																borderRadius: '3px',
																margin: '0px 10px',
																width: '36px',
																height: '36px'
															}}
														>
															<span
																className="material-symbols-rounded"
																style={{ color: theme.palette.primary.main, fontSize: '20px' }}
															>
																visibility
															</span>
															{/* <EditIcon htmlColor="blue" /> */}
														</IconButton>
													</Tooltip>
												)}
												{onEditAction && (
													<Tooltip title={t('edit')} placement="bottom-start">
														<IconButton
															onClick={() => onEditAction(row)}
															sx={{
																backgroundColor: 'rgba(31, 132, 255, 0.11)',
																borderRadius: '3px',
																margin: '0px 10px',
																width: '36px',
																height: '36px'
															}}
														>
															<span
																className="material-symbols-rounded"
																style={{ color: theme.palette.primary.main, fontSize: '20px' }}
															>
																edit_note
															</span>
														</IconButton>
													</Tooltip>
												)}
												{onDeleteAction && (
													<Tooltip title={t('delete')} placement="bottom-start">
														<IconButton
															onClick={() => onDeleteAction(row)}
															sx={{
																backgroundColor: 'rgba(255, 31, 31, 0.11)',
																borderRadius: '3px',
																margin: '0px 10px',
																width: '36px',
																height: '36px'
															}}
														>
															<DeleteOutlineIcon htmlColor="red" sx={{ fontSize: '20px' }} />
														</IconButton>
													</Tooltip>
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</React.Fragment>
						) : (
							!loading && (
								<TableRow>
									<TableCell colSpan={columns.length + 1}>
										<Typography>{t('no_records_found')}</Typography>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{!loading && rows && rows.length > 0 && (
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
}
