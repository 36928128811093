import {
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import { IncrementReportTableCell } from 'components/atoms/CPReportTableCell';
import CPTextField from 'components/atoms/CPTextField';
import Loader from 'components/atoms/Loader';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

interface IIncrementalTestResultsTableThree {
	aov: number;
	handleTableThreeChange: (row: any, index: number, value: any, key: string) => void;
	tableThreeValueList: any[];
	setTableThreeValueList: (value: any) => void;
	tableThreeUpdated: boolean;
	setTableThreeUpdated: (value: boolean) => void;
	loadingTableThree: boolean;
	generateTableThree: () => void;
}

const IncrementalTestResultsTableThree = (props: IIncrementalTestResultsTableThree) => {
	const {
		aov,
		handleTableThreeChange,
		tableThreeValueList,
		setTableThreeValueList,
		tableThreeUpdated,
		setTableThreeUpdated,
		loadingTableThree,
		generateTableThree
	} = props;
	const { t } = useTranslation();

	const columns: IColumn[] = [
		{
			id: 'spendLevelMin',
			label: t('spend_level_min'),
			align: 'left',
			style: { borderLeft: 0 },
			type: 'input'
		},
		{
			id: 'spendLevelMax',
			label: t('spend_level_max'),
			align: 'right',
			type: 'input'
		},
		{
			id: 'cpc',
			label: t('cpc'),
			align: 'right',
			type: 'input'
		},
		{
			id: 'clicks',
			label: t('clicks'),
			align: 'right',
			type: 'number'
		},
		{
			id: 'conversionRate',
			label: t('conversionRate'),
			align: 'right',
			type: 'input'
		},
		{
			id: 'conv',
			label: t('conv'),
			align: 'right',
			type: 'number'
		},
		{
			id: 'aov',
			label: t('aov_'),
			align: 'right',
			type: 'number'
		},
		{
			id: 'channelSales',
			label: t('channelSales'),
			align: 'right',
			type: 'currency'
		},
		{
			id: 'channelRoas',
			label: t('channelRoas'),
			align: 'right',
			type: 'number'
		}
	];

	const addNewRow = () => {
		let valueListTemp: any[] = [];
		valueListTemp.push({
			aov: aov,
			conversionRate: 0,
			cpc: 0,
			rowId: tableThreeValueList.length,
			spendLevelMax: 0,
			spendLevelMin: 0,
			clicks: 0,
			conv: 0,
			channelSales:0,
			channelRoas:0
		});
		setTableThreeValueList(tableThreeValueList.concat(valueListTemp));
		setTableThreeUpdated(true);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', margin: '30px 0px' }} elevation={0}>
			<Grid container spacing={2} sx={{ marginBottom: '15px' }}>
				<Grid item xs={6}></Grid>
				<Grid item xs={6} justifyContent={'flex-end'} display="flex">
					<CPLoadingButton
						label={'Generate'}
						startIcon={<RefreshIcon />}
						onClick={generateTableThree}
						variant="contained"
						loading={loadingTableThree}
						disabled={loadingTableThree || !tableThreeUpdated}
						style={{ marginRight: '10px' }}
					/>
				</Grid>
			</Grid>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							{columns.map((column: IColumn) => (
								<TableCell sx={{ padding: 0, fontSize: '13px' }} key={column.id} align={column.align}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{loadingTableThree && (
							<TableRow>
								<TableCell colSpan={columns.length}>
									<Loader />
								</TableCell>
							</TableRow>
						)}
						{tableThreeValueList.length > 0 &&
							tableThreeValueList.map((row: any, index: number) => (
								<TableRow key={row.rowId}>
									{columns.map((column) => {
										// const value = column.id === 'aov' ? aov : row[column.id as keyof typeof row];
										const value = row[column.id as keyof typeof row];
										return (
											<IncrementReportTableCell
												key={column.id}
												align={column.align}
												sx={{
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													maxWidth: column.minWidth ? column.minWidth : 170,
													padding: '10px'
												}}
											>
												{column.type === 'input' ? (
													<CPTextField
														name={column.id}
														type="number"
														value={value || 0}
														handleChange={(e: any) => handleTableThreeChange(row, index, e.target.value, column.id)}
														inputProps={{
															style: {
																textAlign: 'right',
																fontSize: '12px'
															}
														}}
														size="small"
													/>
												) : (
													<span>{!_.isNil(value) ? value.toString() : '-'}</span>
												)}
											</IncrementReportTableCell>
										);
									})}
								</TableRow>
							))}
						<TableRow sx={{ border: '1px' }}>
							<IncrementReportTableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
								<IconButton onClick={addNewRow}>
									<ControlPointIcon />
								</IconButton>
								{t('addRow')}
							</IncrementReportTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default IncrementalTestResultsTableThree;
